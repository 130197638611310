import {
  Button,
  Stack,
  Combobox,
  Listbox,
  Tooltip,
  Spinner,
  Icon,
} from "@shopify/polaris";
import { useSdk } from "~/hooks";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { CancelSmallMinor, QuestionMarkMajor } from "@shopify/polaris-icons";

export const ProductSearch = ({ index, productIds, isExcluded, onSave }) => {
  const sdk = useSdk();

  const [queryValue, setQueryValue] = useState("");
  const [prevousQueryValue, setPrevousQueryValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    productIds || []
  );
  const [
    shouldRefetchProductDetails,
    setShouldRefetchProductDetails,
  ] = useState(false);
  const PAGE_SIZE = 25;

  //Products for the dropdown
  const { isFetching, data, refetch } = useQuery(
    [`productsSearch_${index}_${isExcluded ? 1 : 0}`],
    async () => {
      const input = {
        first: PAGE_SIZE,
        query: `state:ACTIVE${queryValue ? ` AND ${queryValue}` : ""}`,
      };

      return sdk
        .shopifyProducts({
          input,
        })
        .then((res) => res.shopifyProducts);
    },
    { enabled: false, refetchOnWindowFocus: false }
  );
  
  //Product Details for display
  const productDetails = useQuery(
    [`productsDetailsSearch_${index}_${isExcluded ? 1 : 0}`],
    async () => {
      const productDetails = await sdk
        .shopifyProductsByIds({
          input: { ids: selectedOptions, locale: "en" },
        })
        .then((res) => res.shopifyProductsByIds);

      const productMap = {};
      productDetails.forEach((product) => {
        productMap[product.id] = product.title;
      });
      return productMap;
    },
    { enabled: true, refetchOnWindowFocus: false }
  );

  //Auto save the answers
  useEffect(() => {
    if (selectedOptions !== productIds) {
      setShouldRefetchProductDetails(true);
      onSave(selectedOptions);
    }
  }, [selectedOptions]);

  //Auto save while unmount for sure
  useEffect(() => {
    onSave(selectedOptions);
  }, []);

  //Refetch the query
  useEffect(() => {
    //Only refetch if the query value is different or the data is being fetched for the first time (!data)
    if (!isFetching && (prevousQueryValue !== queryValue || !data)) {
      refetch();
      setPrevousQueryValue(queryValue);
    }
    if (!productDetails.isFetching && shouldRefetchProductDetails) {
      productDetails.refetch();
      setShouldRefetchProductDetails(false);
    }
  }, [
    isFetching,
    queryValue,
    productDetails.isFetching,
    shouldRefetchProductDetails,
  ]);

  //Options should not include selectedOptions
  const options = data?.edges.map((item) => {
    return {
      value: item.node.id,
      label: item.node.title,
    };
  });

  const updateSelection = (selected: string) => {
    let newSelection = [...selectedOptions, selected];
    setSelectedOptions(newSelection);
  };

  const removeOption = (tag) => {
    setSelectedOptions(selectedOptions.filter((opt) => opt !== tag));
  };

  const optionDisplay = (tag: string) => {
    let title = productDetails.data && productDetails.data[tag];

    if (title) {
      return (
        <p
          style={{
            maxWidth: "6.25rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </p>
      );
    } else {
      return (
        <Spinner accessibilityLabel="Small spinner example" size="small" />
      );
    }
  };

  const optionsMarkup =
    options && options?.length > 0
      ? options?.map((option) => {
          const { label, value } = option;
          if (!selectedOptions.includes(value)) {
            return (
              <Listbox.Option
                key={`${value}`}
                value={value}
                accessibilityLabel={label}
              >
                {label}
              </Listbox.Option>
            );
          } else {
            return null;
          }
        })
      : null;

  const loadingMarkup = isFetching ? (
    <Listbox.Loading accessibilityLabel={"Loading products based on search"} />
  ) : null;

  const listboxMarkup =
    optionsMarkup || loadingMarkup ? (
      <Listbox onSelect={updateSelection}>
        {optionsMarkup && !isFetching ? optionsMarkup : null}
        {loadingMarkup}
      </Listbox>
    ) : null;

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <Stack spacing="extraTight" alignment="center">
        {selectedOptions.map((tag) => (
          <div
            key={productDetails.data && productDetails.data[tag]}
            style={{
              backgroundColor: isExcluded ? "#FED3D1" : "#AEE9D1",
              padding: "0.25rem 0.5rem",
              width: "8.4rem",
              fontSize: 14,
              borderRadius: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip content={productDetails.data && productDetails.data[tag]}>
              {optionDisplay(tag)}
            </Tooltip>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <Button
                plain
                key={`option-${tag}`}
                icon={CancelSmallMinor}
                onClick={() => removeOption(tag)}
              ></Button>
            </div>
          </div>
        ))}
      </Stack>
    ) : null;

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        <p>{isExcluded ? "Excluded Products" : "Matched Results"}</p>

        <span
          style={{
            display: "inline-block",
            marginLeft: "0.5rem",
            maxWidth: "1.125rem",
          }}
        >
          <Tooltip
            content={
              isExcluded
                ? "Add products to exclude them from being shown in results for this answer."
                : "Add products to match them to answers."
            }
          >
            <Icon source={QuestionMarkMajor} />
          </Tooltip>
        </span>
      </div>
      <Combobox
        allowMultiple={true}
        preferredPosition={"below"}
        height={"300"}
        activator={
          <Combobox.TextField
            autoComplete="on"
            label="Search Products"
            labelHidden
            value={queryValue}
            suggestion={""}
            placeholder="Search for products"
            verticalContent={verticalContentMarkup}
            onChange={setQueryValue}
          />
        }
      >
        {listboxMarkup}
      </Combobox>
    </div>
  );
};
