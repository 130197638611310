import { Checkbox, Select } from '@shopify/polaris';
import { range } from 'lodash';
import { Controller } from 'react-hook-form';

import { Section } from '~/components/Section';
import { useOptionsForm } from '~/hooks';

const resultsOptions = range(3).map((idx) => ({
  label: `${idx + 1} result${idx > 0 ? 's' : ''}`,
  value: `${idx + 1}`,
}));

const getDefaultValues = (values) => ({
  enableRestart:
    typeof values?.enableRestart !== 'undefined'
      ? !!values.enableRestart
      : true,
  enableReviews:
    typeof values?.enableReviews !== 'undefined'
      ? !!values.enableReviews
      : true,
  enableMoreResults:
    typeof values?.enableMoreResults !== 'undefined'
      ? !!values.enableMoreResults
      : true,
  enableAddToCart:
    typeof values?.enableAddToCart !== 'undefined'
      ? !!values.enableAddToCart
      : true,
  resultsAmount: `${values?.resultsAmount || 3}`,
});

export const ResultsOptions = () => {
  const { control } = useOptionsForm({
    getDefaultValues,
    formatValues: (values) => ({
      ...values,
      resultsAmount: parseInt(values.resultsAmount, 10),
    }),
    type: 'flow',
  });

  return (
    <>
      <Section title="Results options">
        <Controller
          control={control}
          name="enableRestart"
          render={({ field: { value, ...field } }) => (
            <Checkbox label="Enable start over" checked={value} {...field} />
          )}
        />

        <Controller
          control={control}
          name="enableMoreResults"
          render={({ field: { value, ...field } }) => (
            <Checkbox label="Enable more results" checked={value} {...field} />
          )}
        />

        <Controller
          control={control}
          name="enableAddToCart"
          render={({ field: { value, ...field } }) => (
            <Checkbox label="Enable add to cart" checked={value} {...field} />
          )}
        />

        <Controller
          control={control}
          name="enableReviews"
          render={({ field: { value, ...field } }) => (
            <Checkbox
              label="Enable product ratings"
              checked={value}
              {...field}
            />
          )}
        />
      </Section>

      <Section>
        <Controller
          control={control}
          name="resultsAmount"
          render={({ field: { ref, ...field } }) => (
            <Select label="Show results" options={resultsOptions} {...field} />
          )}
        />
      </Section>
    </>
  );
};
