import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** URL scalar type */
  URL: any;
  /** UUID v4 scalar type */
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivateFlowInput = {
  flowId: Scalars["UUID"];
  validateOnly: Scalars["Boolean"];
};

export type ActiveFlow = {
  __typename?: "ActiveFlow";
  accentColor?: Maybe<Scalars["String"]>;
  colorScheme: FlowColorScheme;
  cssEditor?: Maybe<Scalars["String"]>;
  emailTemplate?: Maybe<Scalars["String"]>;
  enableAddToCart: Scalars["Boolean"];
  enableMoreResults: Scalars["Boolean"];
  enableRestart: Scalars["Boolean"];
  enableReviews: Scalars["Boolean"];
  fontFamily?: Maybe<Scalars["String"]>;
  hasProgressBar: Scalars["Boolean"];
  hasStepIndicator: Scalars["Boolean"];
  id: Scalars["String"];
  locale: FlowLocale;
  logo?: Maybe<File>;
  logoWidth?: Maybe<Scalars["Float"]>;
  nodes: Array<ActiveFlowNode>;
  notificationLogo?: Maybe<File>;
  notificationsEnabled?: Maybe<Scalars["Boolean"]>;
  primaryColor?: Maybe<Scalars["String"]>;
  questionEmail?: Maybe<Scalars["String"]>;
  resultsAmount: Scalars["Float"];
  resultsDescription?: Maybe<Scalars["String"]>;
  resultsTitle?: Maybe<Scalars["String"]>;
  status: FlowStatus;
  subject?: Maybe<Scalars["String"]>;
  translation?: Maybe<ActiveTranslation>;
};

export type ActiveFlowContainer = {
  __typename?: "ActiveFlowContainer";
  flow: ActiveFlow;
  id: Scalars["String"];
  name: Scalars["String"];
  ogImage?: Maybe<File>;
  seoDescription?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
};

export type ActiveFlowNode = {
  __typename?: "ActiveFlowNode";
  description?: Maybe<Scalars["String"]>;
  distanceToEnd?: Maybe<GraphMinMaxDistance>;
  id: Scalars["String"];
  image?: Maybe<FileUnion>;
  isRequired: Scalars["Boolean"];
  layout: FlowNodeLayout;
  nextQuestionOnSelection: Scalars["Boolean"];
  options: Array<ActiveFlowNodeOption>;
  title?: Maybe<Scalars["String"]>;
  type: FlowNodeType;
  video?: Maybe<UrlFile>;
};

export type ActiveFlowNodeOption = {
  __typename?: "ActiveFlowNodeOption";
  description?: Maybe<Scalars["String"]>;
  emoji?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  image?: Maybe<FileUnion>;
  label: Scalars["String"];
  nextAction: FlowNodeOptionNextAction;
  nextNode?: Maybe<Scalars["ID"]>;
};

export type ActiveSubscription = {
  __typename?: "ActiveSubscription";
  trialDays?: Maybe<Scalars["Int"]>;
};

export type ActiveTranslation = {
  __typename?: "ActiveTranslation";
  id: Scalars["String"];
  isRtl: Scalars["Boolean"];
  locale: Scalars["String"];
  messages?: Maybe<Array<KeyStringValuePair>>;
};

export type AddSelectionInput = {
  nodeId: Scalars["UUID"];
  /** Not sending an option ID means the question has been skipped. */
  optionIds?: Maybe<Array<Scalars["UUID"]>>;
  sessionId: Scalars["UUID"];
};

export type AdminSession = {
  __typename?: "AdminSession";
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type AnalyticsConversionRate = {
  __typename?: "AnalyticsConversionRate";
  converted: AnalyticsConversionRateItem;
  engagements: AnalyticsConversionRateItem;
  reachedResults: AnalyticsConversionRateItem;
  sessions: AnalyticsConversionRateItem;
};

export type AnalyticsConversionRateInput = {
  end: Scalars["DateTime"];
  flowContainer?: Maybe<Scalars["UUID"]>;
  start: Scalars["DateTime"];
  withArchived?: Maybe<Scalars["Boolean"]>;
};

export type AnalyticsConversionRateItem = {
  __typename?: "AnalyticsConversionRateItem";
  changePercentage?: Maybe<Scalars["Float"]>;
  count: Scalars["Float"];
  percentage: Scalars["Float"];
};

export type AnalyticsFilterInput = {
  compareTo?: Maybe<CompareTo>;
  end: Scalars["DateTime"];
  flowContainer?: Maybe<Scalars["UUID"]>;
  start: Scalars["DateTime"];
  withArchived?: Maybe<Scalars["Boolean"]>;
};

export type AnalyticsFlowContainerFilterInput = {
  end: Scalars["DateTime"];
  flowContainer: Scalars["UUID"];
  start: Scalars["DateTime"];
};

export type AnalyticsResult = {
  __typename?: "AnalyticsResult";
  date: Scalars["String"];
  items: Array<AnalyticsResultItem>;
};

export type AnalyticsResultItem = {
  __typename?: "AnalyticsResultItem";
  key: Scalars["String"];
  value: Scalars["Float"];
};

export type AnalyticsTopProduct = {
  __typename?: "AnalyticsTopProduct";
  changePercentage?: Maybe<Scalars["Float"]>;
  count: Scalars["Float"];
  productId: Scalars["String"];
  productName: Scalars["String"];
};

export type AnalyticsTopRecommendedProduct = {
  __typename?: "AnalyticsTopRecommendedProduct";
  changePercentage?: Maybe<Scalars["Float"]>;
  productId: Scalars["String"];
  productName: Scalars["String"];
  recommendationCount: Scalars["Float"];
  recommendationPercentage: Scalars["Float"];
};

export type AnalyticsUsage = {
  __typename?: "AnalyticsUsage";
  engagements: Scalars["Float"];
};

export type AnalyticsUsageFilterInput = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
};

export type BooleanFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
};

export enum CollectionSortKeys {
  Id = "Id",
  Relevance = "Relevance",
  Title = "Title",
  UpdatedAt = "UpdatedAt",
}

export enum CompareTo {
  PreviousPeriod = "PREVIOUS_PERIOD",
}

export type CountSessionInput = {
  endDate: Scalars["DateTime"];
  flowContainerId?: Maybe<Scalars["UUID"]>;
  isCompleted?: Maybe<Scalars["Boolean"]>;
  startDate: Scalars["DateTime"];
};

export type CreateFlowContainerInput = {
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type CreateFlowInput = {
  containerId: Scalars["UUID"];
};

export type CreateFlowNodeInput = {
  description?: Maybe<Scalars["String"]>;
  flowId: Scalars["String"];
  isRequired: Scalars["Boolean"];
  layout: FlowNodeLayout;
  nextQuestionOnSelection: Scalars["Boolean"];
  options?: Maybe<Array<FlowNodeOptionInput>>;
  title?: Maybe<Scalars["String"]>;
  type: FlowNodeType;
};

export type CreateIntegrationInput = {
  privateKey?: Maybe<Scalars["String"]>;
  publicKey?: Maybe<Scalars["String"]>;
  type: IntegrationType;
};

export type CreateOneFlowContainerInput = {
  /** The record to create */
  flowContainer: CreateFlowContainerInput;
};

export type CreateOneFlowInput = {
  /** The record to create */
  flow: CreateFlowInput;
};

export type CreateOneFlowNodeInput = {
  /** The record to create */
  flowNode: CreateFlowNodeInput;
};

export type CreateOneIntegrationInput = {
  /** The record to create */
  integration: CreateIntegrationInput;
};

export type CreateOneTierInput = {
  /** The record to create */
  tier: CreateTier;
};

export type CreateOneTranslationInput = {
  /** The record to create */
  translation: CreateTranslation;
};

export type CreateTier = {
  billingVersion?: Maybe<Scalars["Float"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  maxEngagements?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  /** Price in cents. */
  price?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CreateTranslation = {
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  isRtl?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<Scalars["String"]>;
  messages?: Maybe<Array<KeyStringValuePairInput>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum CurrencyCode {
  Aed = "Aed",
  Afn = "Afn",
  All = "All",
  Amd = "Amd",
  Ang = "Ang",
  Aoa = "Aoa",
  Ars = "Ars",
  Aud = "Aud",
  Awg = "Awg",
  Azn = "Azn",
  Bam = "Bam",
  Bbd = "Bbd",
  Bdt = "Bdt",
  Bgn = "Bgn",
  Bhd = "Bhd",
  Bif = "Bif",
  Bmd = "Bmd",
  Bnd = "Bnd",
  Bob = "Bob",
  Brl = "Brl",
  Bsd = "Bsd",
  Btn = "Btn",
  Bwp = "Bwp",
  Byn = "Byn",
  Byr = "Byr",
  Bzd = "Bzd",
  Cad = "Cad",
  Cdf = "Cdf",
  Chf = "Chf",
  Clp = "Clp",
  Cny = "Cny",
  Cop = "Cop",
  Crc = "Crc",
  Cve = "Cve",
  Czk = "Czk",
  Djf = "Djf",
  Dkk = "Dkk",
  Dop = "Dop",
  Dzd = "Dzd",
  Egp = "Egp",
  Ern = "Ern",
  Etb = "Etb",
  Eur = "Eur",
  Fjd = "Fjd",
  Fkp = "Fkp",
  Gbp = "Gbp",
  Gel = "Gel",
  Ghs = "Ghs",
  Gip = "Gip",
  Gmd = "Gmd",
  Gnf = "Gnf",
  Gtq = "Gtq",
  Gyd = "Gyd",
  Hkd = "Hkd",
  Hnl = "Hnl",
  Hrk = "Hrk",
  Htg = "Htg",
  Huf = "Huf",
  Idr = "Idr",
  Ils = "Ils",
  Inr = "Inr",
  Iqd = "Iqd",
  Irr = "Irr",
  Isk = "Isk",
  Jep = "Jep",
  Jmd = "Jmd",
  Jod = "Jod",
  Jpy = "Jpy",
  Kes = "Kes",
  Kgs = "Kgs",
  Khr = "Khr",
  Kid = "Kid",
  Kmf = "Kmf",
  Krw = "Krw",
  Kwd = "Kwd",
  Kyd = "Kyd",
  Kzt = "Kzt",
  Lak = "Lak",
  Lbp = "Lbp",
  Lkr = "Lkr",
  Lrd = "Lrd",
  Lsl = "Lsl",
  Ltl = "Ltl",
  Lvl = "Lvl",
  Lyd = "Lyd",
  Mad = "Mad",
  Mdl = "Mdl",
  Mga = "Mga",
  Mkd = "Mkd",
  Mmk = "Mmk",
  Mnt = "Mnt",
  Mop = "Mop",
  Mru = "Mru",
  Mur = "Mur",
  Mvr = "Mvr",
  Mwk = "Mwk",
  Mxn = "Mxn",
  Myr = "Myr",
  Mzn = "Mzn",
  Nad = "Nad",
  Ngn = "Ngn",
  Nio = "Nio",
  Nok = "Nok",
  Npr = "Npr",
  Nzd = "Nzd",
  Omr = "Omr",
  Pab = "Pab",
  Pen = "Pen",
  Pgk = "Pgk",
  Php = "Php",
  Pkr = "Pkr",
  Pln = "Pln",
  Pyg = "Pyg",
  Qar = "Qar",
  Ron = "Ron",
  Rsd = "Rsd",
  Rub = "Rub",
  Rwf = "Rwf",
  Sar = "Sar",
  Sbd = "Sbd",
  Scr = "Scr",
  Sdg = "Sdg",
  Sek = "Sek",
  Sgd = "Sgd",
  Shp = "Shp",
  Sll = "Sll",
  Sos = "Sos",
  Srd = "Srd",
  Ssp = "Ssp",
  Std = "Std",
  Syp = "Syp",
  Szl = "Szl",
  Thb = "Thb",
  Tjs = "Tjs",
  Tmt = "Tmt",
  Tnd = "Tnd",
  Top = "Top",
  Try = "Try",
  Ttd = "Ttd",
  Twd = "Twd",
  Tzs = "Tzs",
  Uah = "Uah",
  Ugx = "Ugx",
  Usd = "Usd",
  Uyu = "Uyu",
  Uzs = "Uzs",
  Vef = "Vef",
  Ves = "Ves",
  Vnd = "Vnd",
  Vuv = "Vuv",
  Wst = "Wst",
  Xaf = "Xaf",
  Xcd = "Xcd",
  Xof = "Xof",
  Xpf = "Xpf",
  Yer = "Yer",
  Zar = "Zar",
  Zmw = "Zmw",
}

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars["ConnectionCursor"]>;
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars["ConnectionCursor"]>;
  /** Paginate first */
  first?: Maybe<Scalars["Int"]>;
  /** Paginate last */
  last?: Maybe<Scalars["Int"]>;
};

export type DateFieldComparison = {
  between?: Maybe<DateFieldComparisonBetween>;
  eq?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  neq?: Maybe<Scalars["DateTime"]>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars["DateTime"];
  upper: Scalars["DateTime"];
};

export type DeleteFlowNodeInput = {
  nodeId: Scalars["ID"];
};

export type DeleteManyFlowContainersInput = {
  /** Filter to find records to delete */
  filter: FlowContainerDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: "DeleteManyResponse";
  /** The number of records deleted. */
  deletedCount: Scalars["Int"];
};

export type DeleteOneInput = {
  /** The id of the record to delete. */
  id: Scalars["ID"];
};

export type DuplicateFlowInput = {
  containerId: Scalars["UUID"];
};

export type EndSessionInput = {
  email?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  locale: Scalars["String"];
};

export type File = {
  __typename?: "File";
  directory: Scalars["String"];
  fileName: Scalars["String"];
  storage: StorageType;
};

export type FileUnion = File | UrlFile;

export type Flow = {
  __typename?: "Flow";
  accentColor?: Maybe<Scalars["String"]>;
  amountOfSteps: Scalars["Float"];
  colorScheme: FlowColorScheme;
  container: FlowContainer;
  createdAt: Scalars["DateTime"];
  cssEditor?: Maybe<Scalars["String"]>;
  emailTemplate?: Maybe<Scalars["String"]>;
  enableAddToCart: Scalars["Boolean"];
  enableMoreResults: Scalars["Boolean"];
  enableRestart: Scalars["Boolean"];
  enableReviews: Scalars["Boolean"];
  fontFamily?: Maybe<Scalars["String"]>;
  hasProgressBar: Scalars["Boolean"];
  hasStepIndicator: Scalars["Boolean"];
  id: Scalars["String"];
  locale: FlowLocale;
  logo?: Maybe<File>;
  logoWidth?: Maybe<Scalars["Float"]>;
  nodes: Array<FlowNode>;
  notificationLogo?: Maybe<File>;
  notificationsEnabled?: Maybe<Scalars["Boolean"]>;
  previousVersion?: Maybe<Flow>;
  primaryColor?: Maybe<Scalars["String"]>;
  productIds: Array<Scalars["String"]>;
  questionEmail?: Maybe<Scalars["String"]>;
  resultsAmount: Scalars["Float"];
  resultsDescription?: Maybe<Scalars["String"]>;
  resultsTitle?: Maybe<Scalars["String"]>;
  status: FlowStatus;
  subject?: Maybe<Scalars["String"]>;
  translationId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type FlowNodesArgs = {
  filter?: Maybe<FlowNodeFilter>;
  sorting?: Maybe<Array<FlowNodeSort>>;
};

export type FlowAggregateGroupBy = {
  __typename?: "FlowAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<FlowStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

/** Flow color scheme. */
export enum FlowColorScheme {
  Cool = "COOL",
  Normal = "NORMAL",
  Warm = "WARM",
}

export type FlowContainer = {
  __typename?: "FlowContainer";
  createdAt: Scalars["DateTime"];
  flows: Array<Flow>;
  id: Scalars["String"];
  integrationMetadata?: Maybe<FlowIntegrationMetadata>;
  name: Scalars["String"];
  ogImage?: Maybe<File>;
  seoDescription?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  status: FlowContainerStatus;
  updatedAt: Scalars["DateTime"];
};

export type FlowContainerFlowsArgs = {
  filter?: Maybe<FlowFilter>;
  sorting?: Maybe<Array<FlowSort>>;
};

export type FlowContainerAggregateGroupBy = {
  __typename?: "FlowContainerAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<FlowContainerStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowContainerConnection = {
  __typename?: "FlowContainerConnection";
  /** Array of edges. */
  edges: Array<FlowContainerEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type FlowContainerCountAggregate = {
  __typename?: "FlowContainerCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type FlowContainerDeleteFilter = {
  and?: Maybe<Array<FlowContainerDeleteFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowContainerDeleteFilter>>;
  status?: Maybe<FlowContainerStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type FlowContainerDeleteResponse = {
  __typename?: "FlowContainerDeleteResponse";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  integrationMetadata?: Maybe<FlowIntegrationMetadata>;
  name?: Maybe<Scalars["String"]>;
  ogImage?: Maybe<File>;
  seoDescription?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  status?: Maybe<FlowContainerStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowContainerEdge = {
  __typename?: "FlowContainerEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the FlowContainer */
  node: FlowContainer;
};

export type FlowContainerFilter = {
  and?: Maybe<Array<FlowContainerFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowContainerFilter>>;
  status?: Maybe<FlowContainerStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type FlowContainerMaxAggregate = {
  __typename?: "FlowContainerMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<FlowContainerStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowContainerMinAggregate = {
  __typename?: "FlowContainerMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<FlowContainerStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowContainerSort = {
  direction: SortDirection;
  field: FlowContainerSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum FlowContainerSortFields {
  CreatedAt = "createdAt",
  Id = "id",
  Name = "name",
  Status = "status",
  UpdatedAt = "updatedAt",
}

/** Flow container status. */
export enum FlowContainerStatus {
  Archived = "ARCHIVED",
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
}

export type FlowContainerStatusFilterComparison = {
  eq?: Maybe<FlowContainerStatus>;
  gt?: Maybe<FlowContainerStatus>;
  gte?: Maybe<FlowContainerStatus>;
  iLike?: Maybe<FlowContainerStatus>;
  in?: Maybe<Array<FlowContainerStatus>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  like?: Maybe<FlowContainerStatus>;
  lt?: Maybe<FlowContainerStatus>;
  lte?: Maybe<FlowContainerStatus>;
  neq?: Maybe<FlowContainerStatus>;
  notILike?: Maybe<FlowContainerStatus>;
  notIn?: Maybe<Array<FlowContainerStatus>>;
  notLike?: Maybe<FlowContainerStatus>;
};

export type FlowContainerUpdateFilter = {
  and?: Maybe<Array<FlowContainerUpdateFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowContainerUpdateFilter>>;
  status?: Maybe<FlowContainerStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type FlowCountAggregate = {
  __typename?: "FlowCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type FlowEdge = {
  __typename?: "FlowEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Flow */
  node: Flow;
};

export type FlowFilter = {
  and?: Maybe<Array<FlowFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowFilter>>;
  status?: Maybe<FlowStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type FlowIntegrationMetadata = {
  __typename?: "FlowIntegrationMetadata";
  klaviyo?: Maybe<FlowIntegrationMetadataKlaviyo>;
  mailchimp?: Maybe<FlowIntegrationMetadataMailchimp>;
};

export type FlowIntegrationMetadataInput = {
  klaviyo?: Maybe<FlowIntegrationMetadataKlaviyoInput>;
  mailchimp?: Maybe<FlowIntegrationMetadataMailchimpInput>;
};

export type FlowIntegrationMetadataKlaviyo = {
  __typename?: "FlowIntegrationMetadataKlaviyo";
  listId: Scalars["String"];
};

export type FlowIntegrationMetadataKlaviyoInput = {
  listId: Scalars["String"];
};

export type FlowIntegrationMetadataMailchimp = {
  __typename?: "FlowIntegrationMetadataMailchimp";
  listId: Scalars["String"];
};

export type FlowIntegrationMetadataMailchimpInput = {
  listId: Scalars["String"];
};

/** Flow locale. */
export enum FlowLocale {
  Custom = "CUSTOM",
  En = "EN",
  Nl = "NL",
}

export type FlowMaxAggregate = {
  __typename?: "FlowMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<FlowStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowMinAggregate = {
  __typename?: "FlowMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<FlowStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowNode = {
  __typename?: "FlowNode";
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  flowId: Scalars["String"];
  id: Scalars["String"];
  image?: Maybe<FileUnion>;
  isRequired: Scalars["Boolean"];
  layout: FlowNodeLayout;
  nextQuestionOnSelection: Scalars["Boolean"];
  options?: Maybe<Array<FlowNodeOption>>;
  order: Scalars["Float"];
  title?: Maybe<Scalars["String"]>;
  type: FlowNodeType;
  updatedAt: Scalars["DateTime"];
  video?: Maybe<UrlFile>;
};

export type FlowNodeAggregateGroupBy = {
  __typename?: "FlowNodeAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowNodeAvgAggregate = {
  __typename?: "FlowNodeAvgAggregate";
  order?: Maybe<Scalars["Float"]>;
};

export type FlowNodeCountAggregate = {
  __typename?: "FlowNodeCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type FlowNodeDeleteResponse = {
  __typename?: "FlowNodeDeleteResponse";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  flowId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  image?: Maybe<FileUnion>;
  isRequired?: Maybe<Scalars["Boolean"]>;
  layout?: Maybe<FlowNodeLayout>;
  nextQuestionOnSelection?: Maybe<Scalars["Boolean"]>;
  options?: Maybe<Array<FlowNodeOption>>;
  order?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<FlowNodeType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  video?: Maybe<UrlFile>;
};

export type FlowNodeEdge = {
  __typename?: "FlowNodeEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the FlowNode */
  node: FlowNode;
};

export type FlowNodeFilter = {
  and?: Maybe<Array<FlowNodeFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowNodeFilter>>;
  order?: Maybe<NumberFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export enum FlowNodeLayout {
  MediaCoverLeft = "MEDIA_COVER_LEFT",
  MediaCoverRight = "MEDIA_COVER_RIGHT",
  MediaFloatLeft = "MEDIA_FLOAT_LEFT",
  MediaFloatRight = "MEDIA_FLOAT_RIGHT",
  MediaFloatTop = "MEDIA_FLOAT_TOP",
  Simple = "SIMPLE",
}

export type FlowNodeMaxAggregate = {
  __typename?: "FlowNodeMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowNodeMinAggregate = {
  __typename?: "FlowNodeMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FlowNodeOption = {
  __typename?: "FlowNodeOption";
  description?: Maybe<Scalars["String"]>;
  emoji?: Maybe<Scalars["String"]>;
  excludedProductIds: Array<Scalars["String"]>;
  id: Scalars["ID"];
  image?: Maybe<FileUnion>;
  label: Scalars["String"];
  nextAction: FlowNodeOptionNextAction;
  nextNode?: Maybe<Scalars["ID"]>;
  productIds: Array<Scalars["String"]>;
};

export type FlowNodeOptionInput = {
  description?: Maybe<Scalars["String"]>;
  emoji?: Maybe<Scalars["String"]>;
  excludedProductIds?: Maybe<Array<Scalars["String"]>>;
  id?: Maybe<Scalars["ID"]>;
  image?: Maybe<UrlFileInput>;
  label: Scalars["String"];
  productIds?: Maybe<Array<Scalars["String"]>>;
};

export type FlowNodeOptionLogicInput = {
  id: Scalars["ID"];
  nextAction?: Maybe<FlowNodeOptionNextAction>;
  nextNode?: Maybe<Scalars["ID"]>;
};

/** Option next action. */
export enum FlowNodeOptionNextAction {
  Auto = "AUTO",
  EndSession = "END_SESSION",
  SpecificNode = "SPECIFIC_NODE",
}

export type FlowNodeOrderInput = {
  flowId: Scalars["UUID"];
  order: Array<Scalars["UUID"]>;
};

export type FlowNodeSort = {
  direction: SortDirection;
  field: FlowNodeSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum FlowNodeSortFields {
  CreatedAt = "createdAt",
  Id = "id",
  Order = "order",
  UpdatedAt = "updatedAt",
}

export type FlowNodeSumAggregate = {
  __typename?: "FlowNodeSumAggregate";
  order?: Maybe<Scalars["Float"]>;
};

export enum FlowNodeType {
  Email = "EMAIL",
  Emoji = "EMOJI",
  EmojiMulti = "EMOJI_MULTI",
  Image = "IMAGE",
  ImageMulti = "IMAGE_MULTI",
  Simple = "SIMPLE",
  SimpleMulti = "SIMPLE_MULTI",
  Welcome = "WELCOME",
}

export type FlowNodeUploadInput = {
  image: Scalars["Upload"];
  nodeId: Scalars["UUID"];
};

export type FlowSort = {
  direction: SortDirection;
  field: FlowSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum FlowSortFields {
  CreatedAt = "createdAt",
  Id = "id",
  Status = "status",
  UpdatedAt = "updatedAt",
}

/** Flow status. */
export enum FlowStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
  Inactive = "INACTIVE",
}

export type FlowStatusFilterComparison = {
  eq?: Maybe<FlowStatus>;
  gt?: Maybe<FlowStatus>;
  gte?: Maybe<FlowStatus>;
  iLike?: Maybe<FlowStatus>;
  in?: Maybe<Array<FlowStatus>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  like?: Maybe<FlowStatus>;
  lt?: Maybe<FlowStatus>;
  lte?: Maybe<FlowStatus>;
  neq?: Maybe<FlowStatus>;
  notILike?: Maybe<FlowStatus>;
  notIn?: Maybe<Array<FlowStatus>>;
  notLike?: Maybe<FlowStatus>;
};

export type FlowUploadInput = {
  flowId: Scalars["UUID"];
  logo: Scalars["Upload"];
};

export type GraphMinMaxDistance = {
  __typename?: "GraphMinMaxDistance";
  max: Scalars["Float"];
  min: Scalars["Float"];
};

export type Integration = {
  __typename?: "Integration";
  createdAt: Scalars["DateTime"];
  hasPrivateKey: Scalars["Boolean"];
  id: Scalars["String"];
  publicKey?: Maybe<Scalars["String"]>;
  status: IntegrationStatus;
  type: IntegrationType;
  updatedAt: Scalars["DateTime"];
};

export type IntegrationAggregateGroupBy = {
  __typename?: "IntegrationAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type IntegrationConnection = {
  __typename?: "IntegrationConnection";
  /** Array of edges. */
  edges: Array<IntegrationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type IntegrationCountAggregate = {
  __typename?: "IntegrationCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type IntegrationDeleteResponse = {
  __typename?: "IntegrationDeleteResponse";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  publicKey?: Maybe<Scalars["String"]>;
  status?: Maybe<IntegrationStatus>;
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type IntegrationEdge = {
  __typename?: "IntegrationEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Integration */
  node: Integration;
};

export type IntegrationFilter = {
  and?: Maybe<Array<IntegrationFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<IntegrationFilter>>;
  type?: Maybe<IntegrationTypeFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type IntegrationMaxAggregate = {
  __typename?: "IntegrationMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type IntegrationMinAggregate = {
  __typename?: "IntegrationMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type IntegrationSort = {
  direction: SortDirection;
  field: IntegrationSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum IntegrationSortFields {
  CreatedAt = "createdAt",
  Id = "id",
  Type = "type",
  UpdatedAt = "updatedAt",
}

/** Integration status. */
export enum IntegrationStatus {
  Connected = "CONNECTED",
  Incomplete = "INCOMPLETE",
  Invalid = "INVALID",
}

export enum IntegrationType {
  Judgeme = "JUDGEME",
  Klaviyo = "KLAVIYO",
  Mailchimp = "MAILCHIMP",
  Yotpo = "YOTPO",
}

export type IntegrationTypeFilterComparison = {
  eq?: Maybe<IntegrationType>;
  gt?: Maybe<IntegrationType>;
  gte?: Maybe<IntegrationType>;
  iLike?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  like?: Maybe<IntegrationType>;
  lt?: Maybe<IntegrationType>;
  lte?: Maybe<IntegrationType>;
  neq?: Maybe<IntegrationType>;
  notILike?: Maybe<IntegrationType>;
  notIn?: Maybe<Array<IntegrationType>>;
  notLike?: Maybe<IntegrationType>;
};

export type KeyKvpArrayValuePair = {
  __typename?: "KeyKVPArrayValuePair";
  key: Scalars["String"];
  value: Array<KeyNumberValuePair>;
};

export type KeyKvpArrayValuePairInput = {
  key: Scalars["String"];
  value: Array<KeyNumberValuePairInput>;
};

export type KeyNumberValuePair = {
  __typename?: "KeyNumberValuePair";
  key: Scalars["String"];
  value: Scalars["Float"];
};

export type KeyNumberValuePairInput = {
  key: Scalars["String"];
  value: Scalars["Float"];
};

export type KeyStringValuePair = {
  __typename?: "KeyStringValuePair";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type KeyStringValuePairInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  activateFlow: Flow;
  /** Submits an answer to a question in the flow. */
  addSelection: Session;
  authenticateAdmin: AdminSession;
  createOneFlow: Flow;
  createOneFlowContainer: FlowContainer;
  createOneFlowNode: FlowNode;
  createOneIntegration: Integration;
  createOneTier: Tier;
  createOneTranslation: Translation;
  deleteFlowNode: FlowNode;
  deleteManyFlowContainers: DeleteManyResponse;
  deleteOneFlowContainer: FlowContainerDeleteResponse;
  deleteOneFlowNode: FlowNodeDeleteResponse;
  deleteOneIntegration: IntegrationDeleteResponse;
  deleteOneStore: StoreDeleteResponse;
  deleteOneTranslation: TranslationDeleteResponse;
  duplicateFlow: Flow;
  /** Ends a session if all questions have been answered. */
  endSession: Session;
  logoutAdmin: Scalars["Boolean"];
  publishMessage: Scalars["String"];
  /** Removes an answer from session (when skip is used). */
  removeSelection: Session;
  /** Restarts an ended session and returns the newly created session. */
  restartSession: Session;
  setDefaultTier: Scalars["Boolean"];
  shopifyCreateSubscription: Scalars["String"];
  /** Starts a new session. */
  startSession: Session;
  updateFlowContainerOgImage: FlowContainer;
  updateFlowLogic: Flow;
  updateFlowNodesOrder: Array<FlowNode>;
  updateManyFlowContainers: UpdateManyResponse;
  updateOneFlow: Flow;
  updateOneFlowContainer: FlowContainer;
  updateOneFlowNode: FlowNode;
  updateOneIntegration: Integration;
  updateOneStore: Store;
  updateOneTier: Tier;
  updateOneTranslation: Translation;
  updateUrlFileForOption: FlowNodeOption;
  uploadFileForOption: FlowNodeOption;
  uploadImageForFlowNode: FlowNode;
  uploadLogoForFlow: Flow;
  uploadNotificationLogoForFlow: Flow;
  validateFlow: Flow;
};

export type MutationActivateFlowArgs = {
  input: ActivateFlowInput;
};

export type MutationAddSelectionArgs = {
  input: AddSelectionInput;
};

export type MutationAuthenticateAdminArgs = {
  token: Scalars["String"];
};

export type MutationCreateOneFlowArgs = {
  input: CreateOneFlowInput;
};

export type MutationCreateOneFlowContainerArgs = {
  input: CreateOneFlowContainerInput;
};

export type MutationCreateOneFlowNodeArgs = {
  input: CreateOneFlowNodeInput;
};

export type MutationCreateOneIntegrationArgs = {
  input: CreateOneIntegrationInput;
};

export type MutationCreateOneTierArgs = {
  input: CreateOneTierInput;
};

export type MutationCreateOneTranslationArgs = {
  input: CreateOneTranslationInput;
};

export type MutationDeleteFlowNodeArgs = {
  input: DeleteFlowNodeInput;
};

export type MutationDeleteManyFlowContainersArgs = {
  input: DeleteManyFlowContainersInput;
};

export type MutationDeleteOneFlowContainerArgs = {
  input: DeleteOneInput;
};

export type MutationDeleteOneFlowNodeArgs = {
  input: DeleteOneInput;
};

export type MutationDeleteOneIntegrationArgs = {
  input: DeleteOneInput;
};

export type MutationDeleteOneStoreArgs = {
  input: DeleteOneInput;
};

export type MutationDeleteOneTranslationArgs = {
  input: DeleteOneInput;
};

export type MutationDuplicateFlowArgs = {
  input: DuplicateFlowInput;
};

export type MutationEndSessionArgs = {
  input: EndSessionInput;
};

export type MutationPublishMessageArgs = {
  attributes?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  topic: Scalars["String"];
};

export type MutationRemoveSelectionArgs = {
  input: RemoveSelectionInput;
};

export type MutationRestartSessionArgs = {
  id: Scalars["String"];
};

export type MutationSetDefaultTierArgs = {
  id: Scalars["String"];
};

export type MutationShopifyCreateSubscriptionArgs = {
  input: ShopifyCreateSubscriptionInput;
};

export type MutationStartSessionArgs = {
  input: StartSessionInput;
};

export type MutationUpdateFlowContainerOgImageArgs = {
  input: UploadFlowContainerOgImageInput;
};

export type MutationUpdateFlowLogicArgs = {
  input: UpdateFlowLogicInput;
};

export type MutationUpdateFlowNodesOrderArgs = {
  input: FlowNodeOrderInput;
};

export type MutationUpdateManyFlowContainersArgs = {
  input: UpdateManyFlowContainersInput;
};

export type MutationUpdateOneFlowArgs = {
  input: UpdateOneFlowInput;
};

export type MutationUpdateOneFlowContainerArgs = {
  input: UpdateOneFlowContainerInput;
};

export type MutationUpdateOneFlowNodeArgs = {
  input: UpdateOneFlowNodeInput;
};

export type MutationUpdateOneIntegrationArgs = {
  input: UpdateOneIntegrationInput;
};

export type MutationUpdateOneStoreArgs = {
  input: UpdateOneStoreInput;
};

export type MutationUpdateOneTierArgs = {
  input: UpdateOneTierInput;
};

export type MutationUpdateOneTranslationArgs = {
  input: UpdateOneTranslationInput;
};

export type MutationUpdateUrlFileForOptionArgs = {
  input: UpdateFlowNodeOptionImageInput;
};

export type MutationUploadFileForOptionArgs = {
  input: UploadFlowNodeOptionImageInput;
};

export type MutationUploadImageForFlowNodeArgs = {
  input: FlowNodeUploadInput;
};

export type MutationUploadLogoForFlowArgs = {
  input: FlowUploadInput;
};

export type MutationUploadNotificationLogoForFlowArgs = {
  input: FlowUploadInput;
};

export type MutationValidateFlowArgs = {
  input: ActivateFlowInput;
};

export type NumberFieldComparison = {
  between?: Maybe<NumberFieldComparisonBetween>;
  eq?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  gte?: Maybe<Scalars["Float"]>;
  in?: Maybe<Array<Scalars["Float"]>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  lt?: Maybe<Scalars["Float"]>;
  lte?: Maybe<Scalars["Float"]>;
  neq?: Maybe<Scalars["Float"]>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
  notIn?: Maybe<Array<Scalars["Float"]>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars["Float"];
  upper: Scalars["Float"];
};

export enum OttAction {
  ExportEmails = "EXPORT_EMAILS",
  GenerateJwt = "GENERATE_JWT",
}

export type PageInfo = {
  __typename?: "PageInfo";
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars["ConnectionCursor"]>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars["ConnectionCursor"]>;
};

export enum ProductCollectionSortKeys {
  BestSelling = "BestSelling",
  CollectionDefault = "CollectionDefault",
  Created = "Created",
  Id = "Id",
  Manual = "Manual",
  Price = "Price",
  Relevance = "Relevance",
  Title = "Title",
}

export type ProductReviews = {
  __typename?: "ProductReviews";
  scores: Array<ReviewScore>;
  service: ReviewsService;
};

export type ProductReviewsInput = {
  productIds: Array<Scalars["String"]>;
};

export enum ProductSortKeys {
  CreatedAt = "CreatedAt",
  Id = "Id",
  InventoryTotal = "InventoryTotal",
  ProductType = "ProductType",
  PublishedAt = "PublishedAt",
  Relevance = "Relevance",
  Title = "Title",
  UpdatedAt = "UpdatedAt",
  Vendor = "Vendor",
}

export type Query = {
  __typename?: "Query";
  /** Get one time token to use in `authenticateShopify` Query. */
  accessToken: Scalars["String"];
  activeFlowContainer?: Maybe<ActiveFlowContainer>;
  activeSubscription: ActiveSubscription;
  /** Returns `null` if not authenticated. */
  adminSession?: Maybe<AdminSession>;
  analyticsCollectedEmails: Array<AnalyticsResult>;
  analyticsRevenueAverageOrderValue: Array<AnalyticsResult>;
  analyticsRevenueOrders: Array<AnalyticsResult>;
  analyticsRevenueSales: Array<AnalyticsResult>;
  analyticsSessions: Array<AnalyticsResult>;
  analyticsSessionsPerCountry: Array<KeyKvpArrayValuePair>;
  analyticsSessionsPerDevice: Array<KeyKvpArrayValuePair>;
  analyticsTopProducts: Array<AnalyticsTopProduct>;
  analyticsTopRecommended: Array<AnalyticsTopRecommendedProduct>;
  analyticsUsage: AnalyticsUsage;
  /** Set Shopify JWT cookie to authenticate outside of Shopify. */
  authenticateShopify: Scalars["String"];
  conversionRates: AnalyticsConversionRate;
  /** Fetches the current store. */
  currentStore?: Maybe<Store>;
  flow?: Maybe<Flow>;
  flowContainer?: Maybe<FlowContainer>;
  flowContainers: FlowContainerConnection;
  flowNode?: Maybe<FlowNode>;
  /** Fetches store details relating to client */
  getStoreDetails?: Maybe<Store>;
  integration?: Maybe<Integration>;
  integrations: IntegrationConnection;
  previewFlowContainer?: Maybe<ActiveFlowContainer>;
  productReviews: ProductReviews;
  session?: Maybe<Session>;
  sessionCount: SessionCount;
  sessions: SessionConnection;
  shopifyCollections: ShopifyCollectionConnection;
  shopifyProductTypes: ShopifyStringConnection;
  shopifyProducts: ShopifyProductConnection;
  shopifyProductsByCollection: ShopifyProductConnection;
  shopifyProductsByIds: Array<ShopifyProduct>;
  store?: Maybe<Store>;
  storeEngagements: Array<StoreEngagementOutput>;
  stores: StoreConnection;
  tier?: Maybe<Tier>;
  tiers: TierConnection;
  translation?: Maybe<Translation>;
  translations: TranslationConnection;
};

export type QueryAccessTokenArgs = {
  action?: Maybe<OttAction>;
};

export type QueryActiveFlowContainerArgs = {
  slug: Scalars["String"];
};

export type QueryAnalyticsCollectedEmailsArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsRevenueAverageOrderValueArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsRevenueOrdersArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsRevenueSalesArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsSessionsArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsSessionsPerCountryArgs = {
  input: AnalyticsFlowContainerFilterInput;
};

export type QueryAnalyticsSessionsPerDeviceArgs = {
  input: AnalyticsFlowContainerFilterInput;
};

export type QueryAnalyticsTopProductsArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsTopRecommendedArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsUsageArgs = {
  input: AnalyticsUsageFilterInput;
};

export type QueryAuthenticateShopifyArgs = {
  token: Scalars["String"];
};

export type QueryConversionRatesArgs = {
  input: AnalyticsConversionRateInput;
};

export type QueryFlowArgs = {
  id: Scalars["ID"];
};

export type QueryFlowContainerArgs = {
  id: Scalars["ID"];
};

export type QueryFlowContainersArgs = {
  filter?: Maybe<FlowContainerFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<FlowContainerSort>>;
};

export type QueryFlowNodeArgs = {
  id: Scalars["ID"];
};

export type QueryIntegrationArgs = {
  id: Scalars["ID"];
};

export type QueryIntegrationsArgs = {
  filter?: Maybe<IntegrationFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<IntegrationSort>>;
};

export type QueryPreviewFlowContainerArgs = {
  flowId: Scalars["String"];
  slug: Scalars["String"];
};

export type QueryProductReviewsArgs = {
  input: ProductReviewsInput;
};

export type QuerySessionArgs = {
  id: Scalars["ID"];
};

export type QuerySessionCountArgs = {
  input: CountSessionInput;
};

export type QuerySessionsArgs = {
  filter?: Maybe<SessionFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<SessionSort>>;
};

export type QueryShopifyCollectionsArgs = {
  input: ShopifyCollectionInput;
};

export type QueryShopifyProductTypesArgs = {
  input: ShopifyProductTypesInput;
};

export type QueryShopifyProductsArgs = {
  input: ShopifyProductsInput;
};

export type QueryShopifyProductsByCollectionArgs = {
  input: ShopifyCollectionByHandleInput;
};

export type QueryShopifyProductsByIdsArgs = {
  input: ShopifyProductsByIdsInput;
};

export type QueryStoreArgs = {
  id: Scalars["ID"];
};

export type QueryStoreEngagementsArgs = {
  input: StoreEngagementFilterInput;
};

export type QueryStoresArgs = {
  filter?: Maybe<StoreFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<StoreSort>>;
};

export type QueryTierArgs = {
  id: Scalars["ID"];
};

export type QueryTiersArgs = {
  filter?: Maybe<TierFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<TierSort>>;
};

export type QueryTranslationArgs = {
  id: Scalars["ID"];
};

export type QueryTranslationsArgs = {
  filter?: Maybe<TranslationFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<TranslationSort>>;
};

export type RemoveSelectionInput = {
  nodeId: Scalars["UUID"];
  sessionId: Scalars["UUID"];
};

export type ReviewScore = {
  __typename?: "ReviewScore";
  average?: Maybe<Scalars["Float"]>;
  count: Scalars["Float"];
  productId: Scalars["String"];
};

export enum ReviewsService {
  Judgeme = "JUDGEME",
  Shopify = "SHOPIFY",
  Yotpo = "YOTPO",
}

export type Session = {
  __typename?: "Session";
  city?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  containerName: Scalars["String"];
  convertedAt?: Maybe<Scalars["DateTime"]>;
  convertedValue?: Maybe<Scalars["Float"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  flowId: Scalars["String"];
  id: Scalars["String"];
  isTest: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  orderCurrency?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["String"]>;
  orderName?: Maybe<Scalars["String"]>;
  originalSessionId?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  /** Get the sorted results of an ended session. */
  results?: Maybe<Array<SessionResult>>;
  selections: Array<SessionSelection>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  status: SessionStatus;
  totalOrderValue?: Maybe<Scalars["Float"]>;
  updatedAt: Scalars["DateTime"];
};

export type SessionResultsArgs = {
  locale?: Maybe<Scalars["String"]>;
};

export type SessionAggregateGroupBy = {
  __typename?: "SessionAggregateGroupBy";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  flowId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isTest?: Maybe<Scalars["Boolean"]>;
  orderName?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SessionCompletedAtFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
};

export type SessionConnection = {
  __typename?: "SessionConnection";
  /** Array of edges. */
  edges: Array<SessionEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SessionCount = {
  __typename?: "SessionCount";
  sessionAmount: Scalars["Float"];
};

export type SessionCountAggregate = {
  __typename?: "SessionCountAggregate";
  completedAt?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  flowId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isTest?: Maybe<Scalars["Int"]>;
  orderName?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type SessionEdge = {
  __typename?: "SessionEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Session */
  node: Session;
};

export type SessionFilter = {
  and?: Maybe<Array<SessionFilter>>;
  completedAt?: Maybe<SessionCompletedAtFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  flowId?: Maybe<SessionFlowIdFilterComparison>;
  id?: Maybe<StringFieldComparison>;
  isTest?: Maybe<BooleanFieldComparison>;
  or?: Maybe<Array<SessionFilter>>;
  orderName?: Maybe<StringFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type SessionFlowIdFilterComparison = {
  eq?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
};

export type SessionMaxAggregate = {
  __typename?: "SessionMaxAggregate";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  flowId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  orderName?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SessionMinAggregate = {
  __typename?: "SessionMinAggregate";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  flowId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  orderName?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SessionResult = {
  __typename?: "SessionResult";
  excludedMatchedOptionIds: Array<Scalars["String"]>;
  matchedOptionIds: Array<Scalars["String"]>;
  product: ShopifyProduct;
  productId: Scalars["String"];
};

export type SessionSelection = {
  __typename?: "SessionSelection";
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  node: FlowNode;
  optionId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
};

export type SessionSort = {
  direction: SortDirection;
  field: SessionSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum SessionSortFields {
  CompletedAt = "completedAt",
  CreatedAt = "createdAt",
  FlowId = "flowId",
  Id = "id",
  IsTest = "isTest",
  OrderName = "orderName",
  UpdatedAt = "updatedAt",
}

export enum SessionStatus {
  Completed = "COMPLETED",
  Converted = "CONVERTED",
  Open = "OPEN",
  Started = "STARTED",
}

export type ShopifyCollection = {
  __typename?: "ShopifyCollection";
  handle: Scalars["String"];
  id: Scalars["ID"];
  title: Scalars["String"];
};

export type ShopifyCollectionByHandleInput = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  handle: Scalars["String"];
  last?: Maybe<Scalars["Float"]>;
  query?: Maybe<Scalars["String"]>;
  reverse?: Maybe<Scalars["Boolean"]>;
  sortKey?: Maybe<ProductCollectionSortKeys>;
};

export type ShopifyCollectionConnection = {
  __typename?: "ShopifyCollectionConnection";
  edges: Array<ShopifyCollectionEdge>;
  pageInfo: ShopifyPageInfo;
};

export type ShopifyCollectionEdge = {
  __typename?: "ShopifyCollectionEdge";
  cursor: Scalars["String"];
  node: ShopifyCollection;
};

export type ShopifyCollectionInput = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  last?: Maybe<Scalars["Float"]>;
  query?: Maybe<Scalars["String"]>;
  reverse?: Maybe<Scalars["Boolean"]>;
  sortKey?: Maybe<CollectionSortKeys>;
};

export type ShopifyCreateSubscriptionInput = {
  tierId?: Maybe<Scalars["UUID"]>;
};

export type ShopifyFeaturedImage = {
  __typename?: "ShopifyFeaturedImage";
  transformedSrc: Scalars["String"];
};

export type ShopifyListingProduct = {
  __typename?: "ShopifyListingProduct";
  featuredImage?: Maybe<ShopifyFeaturedImage>;
  handle: Scalars["String"];
  id: Scalars["ID"];
  title: Scalars["String"];
};

export type ShopifyMetafield = {
  __typename?: "ShopifyMetafield";
  value: Scalars["String"];
};

export type ShopifyMoneyV2 = {
  __typename?: "ShopifyMoneyV2";
  /** Decimal money amount. */
  amount: Scalars["String"];
  /** Currency of the money. */
  currencyCode: CurrencyCode;
};

export type ShopifyPageInfo = {
  __typename?: "ShopifyPageInfo";
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
};

export type ShopifyProduct = {
  __typename?: "ShopifyProduct";
  featuredImage?: Maybe<ShopifyFeaturedImage>;
  handle: Scalars["String"];
  id: Scalars["ID"];
  onlineStoreUrl?: Maybe<Scalars["String"]>;
  priceRangeV2: ShopifyProductPriceRangeV2;
  rating?: Maybe<ShopifyMetafield>;
  ratingCount?: Maybe<ShopifyMetafield>;
  title: Scalars["String"];
  variants: ShopifyVariantConnection;
};

export type ShopifyProductConnection = {
  __typename?: "ShopifyProductConnection";
  edges: Array<ShopifyProductEdge>;
  pageInfo: ShopifyPageInfo;
};

export type ShopifyProductEdge = {
  __typename?: "ShopifyProductEdge";
  cursor: Scalars["String"];
  node: ShopifyListingProduct;
};

export type ShopifyProductPriceRangeV2 = {
  __typename?: "ShopifyProductPriceRangeV2";
  maxVariantPrice: ShopifyMoneyV2;
  minVariantPrice: ShopifyMoneyV2;
};

export type ShopifyProductTypesInput = {
  first: Scalars["Float"];
};

export type ShopifyProductsByIdsInput = {
  ids: Array<Scalars["ID"]>;
  locale: Scalars["String"];
};

export type ShopifyProductsInput = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  last?: Maybe<Scalars["Float"]>;
  query?: Maybe<Scalars["String"]>;
  reverse?: Maybe<Scalars["Boolean"]>;
  sortKey?: Maybe<ProductSortKeys>;
};

export type ShopifyStringConnection = {
  __typename?: "ShopifyStringConnection";
  edges: Array<ShopifyStringEdge>;
  pageInfo: ShopifyPageInfo;
};

export type ShopifyStringEdge = {
  __typename?: "ShopifyStringEdge";
  cursor: Scalars["String"];
  node: Scalars["String"];
};

export type ShopifyVariant = {
  __typename?: "ShopifyVariant";
  id: Scalars["ID"];
};

export type ShopifyVariantConnection = {
  __typename?: "ShopifyVariantConnection";
  /** Only returns the first 2 variants. */
  edges: Array<ShopifyVariantEdge>;
};

export type ShopifyVariantEdge = {
  __typename?: "ShopifyVariantEdge";
  node: ShopifyVariant;
};

/** Sort Directions */
export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = "NULLS_FIRST",
  NullsLast = "NULLS_LAST",
}

export type StartSessionInput = {
  flowId: Scalars["UUID"];
  referrer?: Maybe<Scalars["String"]>;
  screenWidth?: Maybe<Scalars["Float"]>;
};

/** File storage type. */
export enum StorageType {
  Google = "Google",
  Local = "Local",
}

export type Store = {
  __typename?: "Store";
  branding: Scalars["Boolean"];
  /** The two-letter code for the country of the store. */
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  currencyCode?: Maybe<Scalars["String"]>;
  /** The e-mail address of the store owner. */
  email?: Maybe<Scalars["String"]>;
  flowContainers: StoreFlowContainersConnection;
  id: Scalars["String"];
  /** Whether the store is created for demo or review purposes. */
  isTest: Scalars["Boolean"];
  multiQuestionAccess: Scalars["Boolean"];
  /** The name of the store. */
  name?: Maybe<Scalars["String"]>;
  /** Connected Shopify store ID. */
  shopifyId: Scalars["String"];
  shopifyPlan?: Maybe<Scalars["String"]>;
  subscriptionStatus: SubscriptionStatus;
  tier: Tier;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type StoreFlowContainersArgs = {
  filter?: Maybe<FlowContainerFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<FlowContainerSort>>;
};

export type StoreAggregateGroupBy = {
  __typename?: "StoreAggregateGroupBy";
  country?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isTest?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  shopifyId?: Maybe<Scalars["String"]>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type StoreConnection = {
  __typename?: "StoreConnection";
  /** Array of edges. */
  edges: Array<StoreEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type StoreCountAggregate = {
  __typename?: "StoreCountAggregate";
  country?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  email?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isTest?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["Int"]>;
  shopifyId?: Maybe<Scalars["Int"]>;
  subscriptionStatus?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type StoreCountryFilterComparison = {
  eq?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
};

export type StoreDeleteResponse = {
  __typename?: "StoreDeleteResponse";
  branding?: Maybe<Scalars["Boolean"]>;
  /** The two-letter code for the country of the store. */
  country?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  currencyCode?: Maybe<Scalars["String"]>;
  /** The e-mail address of the store owner. */
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  /** Whether the store is created for demo or review purposes. */
  isTest?: Maybe<Scalars["Boolean"]>;
  multiQuestionAccess?: Maybe<Scalars["Boolean"]>;
  /** The name of the store. */
  name?: Maybe<Scalars["String"]>;
  /** Connected Shopify store ID. */
  shopifyId?: Maybe<Scalars["String"]>;
  shopifyPlan?: Maybe<Scalars["String"]>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  /** The tier the store is on. */
  tier?: Maybe<Tier>;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type StoreEdge = {
  __typename?: "StoreEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Store */
  node: Store;
};

export type StoreEngagementFilterInput = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
};

export type StoreEngagementOutput = {
  __typename?: "StoreEngagementOutput";
  engagements: Scalars["Float"];
  store: Store;
};

export type StoreFilter = {
  and?: Maybe<Array<StoreFilter>>;
  country?: Maybe<StoreCountryFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  flowContainers?: Maybe<StoreFilterFlowContainerFilter>;
  id?: Maybe<StringFieldComparison>;
  isTest?: Maybe<StoreIsTestFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<StoreFilter>>;
  shopifyId?: Maybe<StringFieldComparison>;
  subscriptionStatus?: Maybe<SubscriptionStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type StoreFilterFlowContainerFilter = {
  and?: Maybe<Array<StoreFilterFlowContainerFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<StoreFilterFlowContainerFilter>>;
  status?: Maybe<FlowContainerStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type StoreFlowContainersConnection = {
  __typename?: "StoreFlowContainersConnection";
  /** Array of edges. */
  edges: Array<FlowContainerEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type StoreIsTestFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
};

export type StoreMaxAggregate = {
  __typename?: "StoreMaxAggregate";
  country?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shopifyId?: Maybe<Scalars["String"]>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type StoreMinAggregate = {
  __typename?: "StoreMinAggregate";
  country?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shopifyId?: Maybe<Scalars["String"]>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type StoreSort = {
  direction: SortDirection;
  field: StoreSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum StoreSortFields {
  Country = "country",
  CreatedAt = "createdAt",
  Email = "email",
  Id = "id",
  IsTest = "isTest",
  Name = "name",
  ShopifyId = "shopifyId",
  SubscriptionStatus = "subscriptionStatus",
  UpdatedAt = "updatedAt",
}

export type StringFieldComparison = {
  eq?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  iLike?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  like?: Maybe<Scalars["String"]>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  neq?: Maybe<Scalars["String"]>;
  notILike?: Maybe<Scalars["String"]>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  notLike?: Maybe<Scalars["String"]>;
};

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Pending = "PENDING",
}

export type SubscriptionStatusFilterComparison = {
  eq?: Maybe<SubscriptionStatus>;
  gt?: Maybe<SubscriptionStatus>;
  gte?: Maybe<SubscriptionStatus>;
  iLike?: Maybe<SubscriptionStatus>;
  in?: Maybe<Array<SubscriptionStatus>>;
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  like?: Maybe<SubscriptionStatus>;
  lt?: Maybe<SubscriptionStatus>;
  lte?: Maybe<SubscriptionStatus>;
  neq?: Maybe<SubscriptionStatus>;
  notILike?: Maybe<SubscriptionStatus>;
  notIn?: Maybe<Array<SubscriptionStatus>>;
  notLike?: Maybe<SubscriptionStatus>;
};

export type Tier = {
  __typename?: "Tier";
  billingVersion: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  isDefault: Scalars["Boolean"];
  isPublic: Scalars["Boolean"];
  maxEngagements: Scalars["Float"];
  name: Scalars["String"];
  /** Price in cents. */
  price: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
};

export type TierAggregateGroupBy = {
  __typename?: "TierAggregateGroupBy";
  billingVersion?: Maybe<Scalars["Float"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TierAvgAggregate = {
  __typename?: "TierAvgAggregate";
  billingVersion?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type TierConnection = {
  __typename?: "TierConnection";
  /** Array of edges. */
  edges: Array<TierEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type TierCountAggregate = {
  __typename?: "TierCountAggregate";
  billingVersion?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isPublic?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type TierEdge = {
  __typename?: "TierEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Tier */
  node: Tier;
};

export type TierFilter = {
  and?: Maybe<Array<TierFilter>>;
  billingVersion?: Maybe<NumberFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  isPublic?: Maybe<BooleanFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<TierFilter>>;
  price?: Maybe<NumberFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type TierInput = {
  billingVersion: Scalars["Float"];
  isDefault: Scalars["Boolean"];
  isPublic: Scalars["Boolean"];
  maxEngagements: Scalars["Float"];
  name: Scalars["String"];
  /** Price in cents. */
  price: Scalars["Float"];
};

export type TierMaxAggregate = {
  __typename?: "TierMaxAggregate";
  billingVersion?: Maybe<Scalars["Float"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TierMinAggregate = {
  __typename?: "TierMinAggregate";
  billingVersion?: Maybe<Scalars["Float"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TierSort = {
  direction: SortDirection;
  field: TierSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum TierSortFields {
  BillingVersion = "billingVersion",
  CreatedAt = "createdAt",
  Id = "id",
  IsPublic = "isPublic",
  Name = "name",
  Price = "price",
  UpdatedAt = "updatedAt",
}

export type TierSumAggregate = {
  __typename?: "TierSumAggregate";
  billingVersion?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type Translation = {
  __typename?: "Translation";
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  isRtl: Scalars["Boolean"];
  locale: Scalars["String"];
  messages?: Maybe<Array<KeyStringValuePair>>;
  updatedAt: Scalars["DateTime"];
};

export type TranslationAggregateGroupBy = {
  __typename?: "TranslationAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TranslationConnection = {
  __typename?: "TranslationConnection";
  /** Array of edges. */
  edges: Array<TranslationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type TranslationCountAggregate = {
  __typename?: "TranslationCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type TranslationDeleteResponse = {
  __typename?: "TranslationDeleteResponse";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  isRtl?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<Scalars["String"]>;
  messages?: Maybe<Array<KeyStringValuePair>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TranslationEdge = {
  __typename?: "TranslationEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Translation */
  node: Translation;
};

export type TranslationFilter = {
  and?: Maybe<Array<TranslationFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<TranslationFilter>>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type TranslationMaxAggregate = {
  __typename?: "TranslationMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TranslationMinAggregate = {
  __typename?: "TranslationMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TranslationSort = {
  direction: SortDirection;
  field: TranslationSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum TranslationSortFields {
  CreatedAt = "createdAt",
  Id = "id",
  UpdatedAt = "updatedAt",
}

export type UrlFile = {
  __typename?: "URLFile";
  /** BlurHash */
  blurhash?: Maybe<Scalars["String"]>;
  /** Height */
  height?: Maybe<Scalars["Float"]>;
  /** URL */
  url: Scalars["URL"];
  /** Width */
  width?: Maybe<Scalars["Float"]>;
};

export type UrlFileInput = {
  /** BlurHash */
  blurhash?: Maybe<Scalars["String"]>;
  /** Height */
  height?: Maybe<Scalars["Float"]>;
  /** URL */
  url: Scalars["URL"];
  /** Width */
  width?: Maybe<Scalars["Float"]>;
};

export type UpdateFlowContainerInput = {
  integrationMetadata?: Maybe<FlowIntegrationMetadataInput>;
  name?: Maybe<Scalars["String"]>;
  seoDescription?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  status?: Maybe<FlowContainerStatus>;
};

export type UpdateFlowInput = {
  accentColor?: Maybe<Scalars["String"]>;
  colorScheme?: Maybe<FlowColorScheme>;
  cssEditor?: Maybe<Scalars["String"]>;
  emailTemplate?: Maybe<Scalars["String"]>;
  enableAddToCart?: Maybe<Scalars["Boolean"]>;
  enableMoreResults?: Maybe<Scalars["Boolean"]>;
  enableRestart?: Maybe<Scalars["Boolean"]>;
  enableReviews?: Maybe<Scalars["Boolean"]>;
  fontFamily?: Maybe<Scalars["String"]>;
  hasProgressBar?: Maybe<Scalars["Boolean"]>;
  hasStepIndicator?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<FlowLocale>;
  logoWidth?: Maybe<Scalars["Float"]>;
  notificationsEnabled?: Maybe<Scalars["Boolean"]>;
  primaryColor?: Maybe<Scalars["String"]>;
  productIds?: Maybe<Array<Scalars["String"]>>;
  questionEmail?: Maybe<Scalars["String"]>;
  resultsAmount?: Maybe<Scalars["Float"]>;
  resultsDescription?: Maybe<Scalars["String"]>;
  resultsTitle?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  translationId?: Maybe<Scalars["String"]>;
};

export type UpdateFlowLogicInput = {
  flowId: Scalars["UUID"];
  logic: Array<FlowNodeOptionLogicInput>;
};

export type UpdateFlowNodeInput = {
  description?: Maybe<Scalars["String"]>;
  flowId?: Maybe<Scalars["String"]>;
  image?: Maybe<UrlFileInput>;
  isRequired?: Maybe<Scalars["Boolean"]>;
  layout?: Maybe<FlowNodeLayout>;
  nextQuestionOnSelection?: Maybe<Scalars["Boolean"]>;
  options?: Maybe<Array<FlowNodeOptionInput>>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<FlowNodeType>;
  video?: Maybe<UrlFileInput>;
};

export type UpdateFlowNodeOptionImageInput = {
  image: UrlFileInput;
  nodeId: Scalars["UUID"];
  optionId: Scalars["UUID"];
};

export type UpdateIntegrationInput = {
  privateKey?: Maybe<Scalars["String"]>;
  publicKey?: Maybe<Scalars["String"]>;
};

export type UpdateManyFlowContainersInput = {
  /** Filter used to find fields to update */
  filter: FlowContainerUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateFlowContainerInput;
};

export type UpdateManyResponse = {
  __typename?: "UpdateManyResponse";
  /** The number of records updated. */
  updatedCount: Scalars["Int"];
};

export type UpdateOneFlowContainerInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateFlowContainerInput;
};

export type UpdateOneFlowInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateFlowInput;
};

export type UpdateOneFlowNodeInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateFlowNodeInput;
};

export type UpdateOneIntegrationInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateIntegrationInput;
};

export type UpdateOneStoreInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateStoreInput;
};

export type UpdateOneTierInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateTier;
};

export type UpdateOneTranslationInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateTranslation;
};

export type UpdateStoreInput = {
  tierId: Scalars["UUID"];
};

export type UpdateTier = {
  billingVersion?: Maybe<Scalars["Float"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  maxEngagements?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  /** Price in cents. */
  price?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UpdateTranslation = {
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  isRtl?: Maybe<Scalars["Boolean"]>;
  locale?: Maybe<Scalars["String"]>;
  messages?: Maybe<Array<KeyStringValuePairInput>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UploadFlowContainerOgImageInput = {
  id: Scalars["UUID"];
  ogImage: Scalars["Upload"];
};

export type UploadFlowNodeOptionImageInput = {
  image: Scalars["Upload"];
  nodeId: Scalars["UUID"];
  optionId: Scalars["UUID"];
};

export type FlowFragmentFragment = { __typename?: "Flow" } & Pick<
  Flow,
  | "id"
  | "status"
  | "colorScheme"
  | "fontFamily"
  | "hasProgressBar"
  | "hasStepIndicator"
  | "enableAddToCart"
  | "enableMoreResults"
  | "enableRestart"
  | "enableReviews"
  | "resultsAmount"
  | "resultsDescription"
  | "resultsTitle"
  | "primaryColor"
  | "createdAt"
  | "updatedAt"
  | "notificationsEnabled"
  | "logoWidth"
  | "accentColor"
  | "questionEmail"
  | "subject"
  | "emailTemplate"
  | "cssEditor"
  | "productIds"
  | "locale"
  | "translationId"
> & {
    nodes: Array<{ __typename?: "FlowNode" } & FlowNodeFragmentFragment>;
    notificationLogo?: Maybe<
      { __typename?: "File" } & FileFragment_File_Fragment
    >;
    logo?: Maybe<{ __typename?: "File" } & FileFragment_File_Fragment>;
  };

export type FlowNodeFragmentFragment = { __typename?: "FlowNode" } & Pick<
  FlowNode,
  | "id"
  | "title"
  | "description"
  | "type"
  | "nextQuestionOnSelection"
  | "createdAt"
  | "updatedAt"
  | "layout"
  | "isRequired"
> & {
    image?: Maybe<
      | ({ __typename?: "File" } & FileFragment_File_Fragment)
      | ({ __typename?: "URLFile" } & FileFragment_UrlFile_Fragment)
    >;
    video?: Maybe<{ __typename?: "URLFile" } & FileFragment_UrlFile_Fragment>;
    options?: Maybe<
      Array<{ __typename?: "FlowNodeOption" } & FlowNodeOptionFragmentFragment>
    >;
  };

export type FlowNodeOptionFragmentFragment = {
  __typename?: "FlowNodeOption";
} & Pick<
  FlowNodeOption,
  | "id"
  | "emoji"
  | "label"
  | "description"
  | "productIds"
  | "excludedProductIds"
  | "nextAction"
  | "nextNode"
> & {
    image?: Maybe<
      | ({ __typename?: "File" } & FileFragment_File_Fragment)
      | ({ __typename?: "URLFile" } & FileFragment_UrlFile_Fragment)
    >;
  };

type FileFragment_File_Fragment = { __typename: "File" } & Pick<
  File,
  "directory" | "fileName" | "storage"
>;

type FileFragment_UrlFile_Fragment = { __typename: "URLFile" } & Pick<
  UrlFile,
  "url"
>;

export type FileFragmentFragment =
  | FileFragment_File_Fragment
  | FileFragment_UrlFile_Fragment;

export type IntegrationFragmentFragment = { __typename?: "Integration" } & Pick<
  Integration,
  "id" | "type" | "status" | "createdAt" | "publicKey" | "hasPrivateKey"
>;

export type ShopifyProductFragment = { __typename?: "ShopifyProduct" } & Pick<
  ShopifyProduct,
  "id" | "title"
> & {
    featuredImage?: Maybe<
      { __typename?: "ShopifyFeaturedImage" } & Pick<
        ShopifyFeaturedImage,
        "transformedSrc"
      >
    >;
  };

export type ShopifyProductListingFragment = {
  __typename?: "ShopifyListingProduct";
} & Pick<ShopifyListingProduct, "id" | "title"> & {
    featuredImage?: Maybe<
      { __typename?: "ShopifyFeaturedImage" } & Pick<
        ShopifyFeaturedImage,
        "transformedSrc"
      >
    >;
  };

export type ActivateFlowMutationVariables = Exact<{
  input: ActivateFlowInput;
}>;

export type ActivateFlowMutation = { __typename?: "Mutation" } & {
  activateFlow: { __typename?: "Flow" } & FlowFragmentFragment;
};

export type CreateOneContainerMutationVariables = Exact<{
  input: CreateOneFlowContainerInput;
}>;

export type CreateOneContainerMutation = { __typename?: "Mutation" } & {
  createOneFlowContainer: { __typename?: "FlowContainer" } & Pick<
    FlowContainer,
    "id"
  >;
};

export type CreateOneFlowMutationVariables = Exact<{
  input: CreateOneFlowInput;
}>;

export type CreateOneFlowMutation = { __typename?: "Mutation" } & {
  createOneFlow: { __typename?: "Flow" } & Pick<Flow, "id">;
};

export type CreateOneFlowNodeMutationVariables = Exact<{
  input: CreateOneFlowNodeInput;
}>;

export type CreateOneFlowNodeMutation = { __typename?: "Mutation" } & {
  createOneFlowNode: { __typename?: "FlowNode" } & FlowNodeFragmentFragment;
};

export type CreateOneIntegrationMutationVariables = Exact<{
  input: CreateOneIntegrationInput;
}>;

export type CreateOneIntegrationMutation = { __typename?: "Mutation" } & {
  createOneIntegration: {
    __typename?: "Integration";
  } & IntegrationFragmentFragment;
};

export type DeleteFlowNodeMutationVariables = Exact<{
  input: DeleteFlowNodeInput;
}>;

export type DeleteFlowNodeMutation = { __typename?: "Mutation" } & {
  deleteFlowNode: { __typename?: "FlowNode" } & Pick<FlowNode, "id">;
};

export type DeleteOneIntegrationMutationVariables = Exact<{
  input: DeleteOneInput;
}>;

export type DeleteOneIntegrationMutation = { __typename?: "Mutation" } & {
  deleteOneIntegration: { __typename?: "IntegrationDeleteResponse" } & Pick<
    IntegrationDeleteResponse,
    "id"
  >;
};

export type UpdateFlowContainerOgImageMutationVariables = Exact<{
  input: UploadFlowContainerOgImageInput;
}>;

export type UpdateFlowContainerOgImageMutation = { __typename?: "Mutation" } & {
  updateFlowContainerOgImage: { __typename?: "FlowContainer" } & Pick<
    FlowContainer,
    "id"
  >;
};

export type UpdateFlowLogicMutationVariables = Exact<{
  input: UpdateFlowLogicInput;
}>;

export type UpdateFlowLogicMutation = { __typename?: "Mutation" } & {
  updateFlowLogic: { __typename?: "Flow" } & FlowFragmentFragment;
};

export type UpdateFlowNodesOrderMutationVariables = Exact<{
  input: FlowNodeOrderInput;
}>;

export type UpdateFlowNodesOrderMutation = { __typename?: "Mutation" } & {
  updateFlowNodesOrder: Array<
    { __typename?: "FlowNode" } & Pick<FlowNode, "id">
  >;
};

export type UpdateOneContainerMutationVariables = Exact<{
  input: UpdateOneFlowContainerInput;
}>;

export type UpdateOneContainerMutation = { __typename?: "Mutation" } & {
  updateOneFlowContainer: { __typename?: "FlowContainer" } & Pick<
    FlowContainer,
    "id"
  >;
};

export type UpdateOneFlowMutationVariables = Exact<{
  input: UpdateOneFlowInput;
}>;

export type UpdateOneFlowMutation = { __typename?: "Mutation" } & {
  updateOneFlow: { __typename?: "Flow" } & FlowFragmentFragment;
};

export type UpdateOneFlowNodeMutationVariables = Exact<{
  input: UpdateOneFlowNodeInput;
}>;

export type UpdateOneFlowNodeMutation = { __typename?: "Mutation" } & {
  updateOneFlowNode: { __typename?: "FlowNode" } & FlowNodeFragmentFragment;
};

export type UpdateOneIntegrationMutationVariables = Exact<{
  input: UpdateOneIntegrationInput;
}>;

export type UpdateOneIntegrationMutation = { __typename?: "Mutation" } & {
  updateOneIntegration: {
    __typename?: "Integration";
  } & IntegrationFragmentFragment;
};

export type UpdateUrlFileForOptionMutationVariables = Exact<{
  input: UpdateFlowNodeOptionImageInput;
}>;

export type UpdateUrlFileForOptionMutation = { __typename?: "Mutation" } & {
  updateUrlFileForOption: {
    __typename?: "FlowNodeOption";
  } & FlowNodeOptionFragmentFragment;
};

export type UploadFileForOptionMutationVariables = Exact<{
  input: UploadFlowNodeOptionImageInput;
}>;

export type UploadFileForOptionMutation = { __typename?: "Mutation" } & {
  uploadFileForOption: {
    __typename?: "FlowNodeOption";
  } & FlowNodeOptionFragmentFragment;
};

export type UploadImageForFlowNodeMutationVariables = Exact<{
  input: FlowNodeUploadInput;
}>;

export type UploadImageForFlowNodeMutation = { __typename?: "Mutation" } & {
  uploadImageForFlowNode: { __typename?: "FlowNode" } & Pick<FlowNode, "id">;
};

export type UploadLogoForFlowMutationVariables = Exact<{
  input: FlowUploadInput;
}>;

export type UploadLogoForFlowMutation = { __typename?: "Mutation" } & {
  uploadLogoForFlow: { __typename?: "Flow" } & Pick<Flow, "id">;
};

export type UploadNotificationLogoForFlowMutationVariables = Exact<{
  input: FlowUploadInput;
}>;

export type UploadNotificationLogoForFlowMutation = {
  __typename?: "Mutation";
} & {
  uploadNotificationLogoForFlow: { __typename?: "Flow" } & Pick<Flow, "id">;
};

export type AccessTokenQueryVariables = Exact<{ [key: string]: never }>;

export type AccessTokenQuery = { __typename?: "Query" } & Pick<
  Query,
  "accessToken"
>;

export type ContainerQueryVariables = Exact<{
  id: Scalars["ID"];
  flowFilter?: Maybe<FlowFilter>;
}>;

export type ContainerQuery = { __typename?: "Query" } & {
  flowContainer?: Maybe<
    { __typename?: "FlowContainer" } & Pick<
      FlowContainer,
      | "id"
      | "name"
      | "status"
      | "slug"
      | "createdAt"
      | "seoTitle"
      | "seoDescription"
    > & {
        ogImage?: Maybe<{ __typename?: "File" } & FileFragment_File_Fragment>;
        flows: Array<{ __typename?: "Flow" } & FlowFragmentFragment>;
        integrationMetadata?: Maybe<
          { __typename?: "FlowIntegrationMetadata" } & {
            klaviyo?: Maybe<
              { __typename?: "FlowIntegrationMetadataKlaviyo" } & Pick<
                FlowIntegrationMetadataKlaviyo,
                "listId"
              >
            >;
            mailchimp?: Maybe<
              { __typename?: "FlowIntegrationMetadataMailchimp" } & Pick<
                FlowIntegrationMetadataMailchimp,
                "listId"
              >
            >;
          }
        >;
      }
  >;
};

export type ContainersQueryVariables = Exact<{
  filter?: Maybe<FlowContainerFilter>;
}>;

export type ContainersQuery = { __typename?: "Query" } & {
  flowContainers: { __typename?: "FlowContainerConnection" } & {
    edges: Array<
      { __typename?: "FlowContainerEdge" } & {
        node: { __typename?: "FlowContainer" } & Pick<
          FlowContainer,
          | "createdAt"
          | "id"
          | "name"
          | "status"
          | "updatedAt"
          | "seoTitle"
          | "seoDescription"
        >;
      }
    >;
  };
};

export type CurrentStoreQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentStoreQuery = { __typename?: "Query" } & {
  currentStore?: Maybe<
    { __typename?: "Store" } & Pick<
      Store,
      "id" | "multiQuestionAccess" | "createdAt"
    > & {
        tier: { __typename?: "Tier" } & Pick<
          Tier,
          "id" | "maxEngagements" | "name" | "price" | "billingVersion"
        >;
      }
  >;
};

export type IntegrationsQueryVariables = Exact<{
  filter?: Maybe<IntegrationFilter>;
}>;

export type IntegrationsQuery = { __typename?: "Query" } & {
  integrations: { __typename?: "IntegrationConnection" } & {
    edges: Array<
      { __typename?: "IntegrationEdge" } & {
        node: { __typename?: "Integration" } & IntegrationFragmentFragment;
      }
    >;
  };
};

export type ShopifyCollectionsQueryVariables = Exact<{
  input: ShopifyCollectionInput;
}>;

export type ShopifyCollectionsQuery = { __typename?: "Query" } & {
  shopifyCollections: { __typename?: "ShopifyCollectionConnection" } & {
    edges: Array<
      { __typename?: "ShopifyCollectionEdge" } & Pick<
        ShopifyCollectionEdge,
        "cursor"
      > & {
          node: { __typename?: "ShopifyCollection" } & Pick<
            ShopifyCollection,
            "handle" | "title"
          >;
        }
    >;
    pageInfo: { __typename?: "ShopifyPageInfo" } & Pick<
      ShopifyPageInfo,
      "hasNextPage" | "hasPreviousPage"
    >;
  };
};

export type ShopifyProductTypesQueryVariables = Exact<{
  input: ShopifyProductTypesInput;
}>;

export type ShopifyProductTypesQuery = { __typename?: "Query" } & {
  shopifyProductTypes: { __typename?: "ShopifyStringConnection" } & {
    edges: Array<
      { __typename?: "ShopifyStringEdge" } & Pick<
        ShopifyStringEdge,
        "cursor" | "node"
      >
    >;
    pageInfo: { __typename?: "ShopifyPageInfo" } & Pick<
      ShopifyPageInfo,
      "hasNextPage" | "hasPreviousPage"
    >;
  };
};

export type ShopifyProductsQueryVariables = Exact<{
  input: ShopifyProductsInput;
}>;

export type ShopifyProductsQuery = { __typename?: "Query" } & {
  shopifyProducts: { __typename?: "ShopifyProductConnection" } & {
    edges: Array<
      { __typename?: "ShopifyProductEdge" } & Pick<
        ShopifyProductEdge,
        "cursor"
      > & {
          node: {
            __typename?: "ShopifyListingProduct";
          } & ShopifyProductListingFragment;
        }
    >;
    pageInfo: { __typename?: "ShopifyPageInfo" } & Pick<
      ShopifyPageInfo,
      "hasNextPage" | "hasPreviousPage"
    >;
  };
};

export type ShopifyProductsByCollectionQueryVariables = Exact<{
  input: ShopifyCollectionByHandleInput;
}>;

export type ShopifyProductsByCollectionQuery = { __typename?: "Query" } & {
  shopifyProductsByCollection: { __typename?: "ShopifyProductConnection" } & {
    edges: Array<
      { __typename?: "ShopifyProductEdge" } & Pick<
        ShopifyProductEdge,
        "cursor"
      > & {
          node: {
            __typename?: "ShopifyListingProduct";
          } & ShopifyProductListingFragment;
        }
    >;
    pageInfo: { __typename?: "ShopifyPageInfo" } & Pick<
      ShopifyPageInfo,
      "hasNextPage" | "hasPreviousPage"
    >;
  };
};

export type ShopifyProductsByIdsQueryVariables = Exact<{
  input: ShopifyProductsByIdsInput;
}>;

export type ShopifyProductsByIdsQuery = { __typename?: "Query" } & {
  shopifyProductsByIds: Array<
    { __typename?: "ShopifyProduct" } & ShopifyProductFragment
  >;
};

export type TranslationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TranslationQuery = { __typename?: "Query" } & {
  translation?: Maybe<
    { __typename?: "Translation" } & Pick<
      Translation,
      "id" | "locale" | "isRtl"
    > & {
        messages?: Maybe<
          Array<
            { __typename?: "KeyStringValuePair" } & Pick<
              KeyStringValuePair,
              "key" | "value"
            >
          >
        >;
      }
  >;
};

export type TranslationsQueryVariables = Exact<{ [key: string]: never }>;

export type TranslationsQuery = { __typename?: "Query" } & {
  translations: { __typename?: "TranslationConnection" } & {
    edges: Array<
      { __typename?: "TranslationEdge" } & {
        node: { __typename?: "Translation" } & Pick<
          Translation,
          "id" | "locale"
        >;
      }
    >;
  };
};

export const FileFragmentFragmentDoc = gql`
  fragment FileFragment on FileUnion {
    __typename
    ... on File {
      directory
      fileName
      storage
    }
    ... on URLFile {
      url
    }
  }
`;
export const FlowNodeOptionFragmentFragmentDoc = gql`
  fragment FlowNodeOptionFragment on FlowNodeOption {
    id
    emoji
    image {
      ...FileFragment
    }
    label
    description
    productIds
    excludedProductIds
    nextAction
    nextNode
  }
  ${FileFragmentFragmentDoc}
`;
export const FlowNodeFragmentFragmentDoc = gql`
  fragment FlowNodeFragment on FlowNode {
    id
    title
    description
    type
    nextQuestionOnSelection
    createdAt
    updatedAt
    image {
      ...FileFragment
    }
    video {
      ...FileFragment
    }
    options {
      ...FlowNodeOptionFragment
    }
    layout
    isRequired
  }
  ${FileFragmentFragmentDoc}
  ${FlowNodeOptionFragmentFragmentDoc}
`;
export const FlowFragmentFragmentDoc = gql`
  fragment FlowFragment on Flow {
    id
    status
    nodes {
      ...FlowNodeFragment
    }
    colorScheme
    fontFamily
    hasProgressBar
    hasStepIndicator
    enableAddToCart
    enableMoreResults
    enableRestart
    enableReviews
    resultsAmount
    resultsDescription
    resultsTitle
    primaryColor
    createdAt
    updatedAt
    notificationsEnabled
    notificationLogo {
      ...FileFragment
    }
    logoWidth
    accentColor
    questionEmail
    subject
    emailTemplate
    cssEditor
    logo {
      ...FileFragment
    }
    productIds
    locale
    translationId
  }
  ${FlowNodeFragmentFragmentDoc}
  ${FileFragmentFragmentDoc}
`;
export const IntegrationFragmentFragmentDoc = gql`
  fragment IntegrationFragment on Integration {
    id
    type
    status
    createdAt
    publicKey
    hasPrivateKey
  }
`;
export const ShopifyProductFragmentDoc = gql`
  fragment ShopifyProduct on ShopifyProduct {
    id
    title
    featuredImage {
      transformedSrc
    }
  }
`;
export const ShopifyProductListingFragmentDoc = gql`
  fragment ShopifyProductListing on ShopifyListingProduct {
    id
    title
    featuredImage {
      transformedSrc
    }
  }
`;
export const ActivateFlowDocument = gql`
  mutation activateFlow($input: ActivateFlowInput!) {
    activateFlow(input: $input) {
      ...FlowFragment
    }
  }
  ${FlowFragmentFragmentDoc}
`;
export const CreateOneContainerDocument = gql`
  mutation createOneContainer($input: CreateOneFlowContainerInput!) {
    createOneFlowContainer(input: $input) {
      id
    }
  }
`;
export const CreateOneFlowDocument = gql`
  mutation createOneFlow($input: CreateOneFlowInput!) {
    createOneFlow(input: $input) {
      id
    }
  }
`;
export const CreateOneFlowNodeDocument = gql`
  mutation createOneFlowNode($input: CreateOneFlowNodeInput!) {
    createOneFlowNode(input: $input) {
      ...FlowNodeFragment
    }
  }
  ${FlowNodeFragmentFragmentDoc}
`;
export const CreateOneIntegrationDocument = gql`
  mutation createOneIntegration($input: CreateOneIntegrationInput!) {
    createOneIntegration(input: $input) {
      ...IntegrationFragment
    }
  }
  ${IntegrationFragmentFragmentDoc}
`;
export const DeleteFlowNodeDocument = gql`
  mutation deleteFlowNode($input: DeleteFlowNodeInput!) {
    deleteFlowNode(input: $input) {
      id
    }
  }
`;
export const DeleteOneIntegrationDocument = gql`
  mutation deleteOneIntegration($input: DeleteOneInput!) {
    deleteOneIntegration(input: $input) {
      id
    }
  }
`;
export const UpdateFlowContainerOgImageDocument = gql`
  mutation updateFlowContainerOgImage(
    $input: UploadFlowContainerOGImageInput!
  ) {
    updateFlowContainerOgImage(input: $input) {
      id
    }
  }
`;
export const UpdateFlowLogicDocument = gql`
  mutation updateFlowLogic($input: UpdateFlowLogicInput!) {
    updateFlowLogic(input: $input) {
      ...FlowFragment
    }
  }
  ${FlowFragmentFragmentDoc}
`;
export const UpdateFlowNodesOrderDocument = gql`
  mutation updateFlowNodesOrder($input: FlowNodeOrderInput!) {
    updateFlowNodesOrder(input: $input) {
      id
    }
  }
`;
export const UpdateOneContainerDocument = gql`
  mutation updateOneContainer($input: UpdateOneFlowContainerInput!) {
    updateOneFlowContainer(input: $input) {
      id
    }
  }
`;
export const UpdateOneFlowDocument = gql`
  mutation updateOneFlow($input: UpdateOneFlowInput!) {
    updateOneFlow(input: $input) {
      ...FlowFragment
    }
  }
  ${FlowFragmentFragmentDoc}
`;
export const UpdateOneFlowNodeDocument = gql`
  mutation updateOneFlowNode($input: UpdateOneFlowNodeInput!) {
    updateOneFlowNode(input: $input) {
      ...FlowNodeFragment
    }
  }
  ${FlowNodeFragmentFragmentDoc}
`;
export const UpdateOneIntegrationDocument = gql`
  mutation updateOneIntegration($input: UpdateOneIntegrationInput!) {
    updateOneIntegration(input: $input) {
      ...IntegrationFragment
    }
  }
  ${IntegrationFragmentFragmentDoc}
`;
export const UpdateUrlFileForOptionDocument = gql`
  mutation updateUrlFileForOption($input: UpdateFlowNodeOptionImageInput!) {
    updateUrlFileForOption(input: $input) {
      ...FlowNodeOptionFragment
    }
  }
  ${FlowNodeOptionFragmentFragmentDoc}
`;
export const UploadFileForOptionDocument = gql`
  mutation uploadFileForOption($input: UploadFlowNodeOptionImageInput!) {
    uploadFileForOption(input: $input) {
      ...FlowNodeOptionFragment
    }
  }
  ${FlowNodeOptionFragmentFragmentDoc}
`;
export const UploadImageForFlowNodeDocument = gql`
  mutation uploadImageForFlowNode($input: FlowNodeUploadInput!) {
    uploadImageForFlowNode(input: $input) {
      id
    }
  }
`;
export const UploadLogoForFlowDocument = gql`
  mutation uploadLogoForFlow($input: FlowUploadInput!) {
    uploadLogoForFlow(input: $input) {
      id
    }
  }
`;
export const UploadNotificationLogoForFlowDocument = gql`
  mutation uploadNotificationLogoForFlow($input: FlowUploadInput!) {
    uploadNotificationLogoForFlow(input: $input) {
      id
    }
  }
`;
export const AccessTokenDocument = gql`
  query accessToken {
    accessToken
  }
`;
export const ContainerDocument = gql`
  query container($id: ID!, $flowFilter: FlowFilter) {
    flowContainer(id: $id) {
      id
      name
      status
      slug
      createdAt
      seoTitle
      seoDescription
      ogImage {
        ...FileFragment
      }
      flows(filter: $flowFilter) {
        ...FlowFragment
      }
      integrationMetadata {
        klaviyo {
          listId
        }
        mailchimp {
          listId
        }
      }
    }
  }
  ${FileFragmentFragmentDoc}
  ${FlowFragmentFragmentDoc}
`;
export const ContainersDocument = gql`
  query containers($filter: FlowContainerFilter) {
    flowContainers(filter: $filter) {
      edges {
        node {
          createdAt
          id
          name
          status
          updatedAt
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
export const CurrentStoreDocument = gql`
  query currentStore {
    currentStore {
      id
      multiQuestionAccess
      createdAt
      tier {
        id
        maxEngagements
        name
        price
        billingVersion
      }
    }
  }
`;
export const IntegrationsDocument = gql`
  query integrations($filter: IntegrationFilter) {
    integrations(filter: $filter) {
      edges {
        node {
          ...IntegrationFragment
        }
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
`;
export const ShopifyCollectionsDocument = gql`
  query shopifyCollections($input: ShopifyCollectionInput!) {
    shopifyCollections(input: $input) {
      edges {
        cursor
        node {
          handle
          title
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const ShopifyProductTypesDocument = gql`
  query shopifyProductTypes($input: ShopifyProductTypesInput!) {
    shopifyProductTypes(input: $input) {
      edges {
        cursor
        node
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const ShopifyProductsDocument = gql`
  query shopifyProducts($input: ShopifyProductsInput!) {
    shopifyProducts(input: $input) {
      edges {
        cursor
        node {
          ...ShopifyProductListing
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ShopifyProductListingFragmentDoc}
`;
export const ShopifyProductsByCollectionDocument = gql`
  query shopifyProductsByCollection($input: ShopifyCollectionByHandleInput!) {
    shopifyProductsByCollection(input: $input) {
      edges {
        cursor
        node {
          ...ShopifyProductListing
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ShopifyProductListingFragmentDoc}
`;
export const ShopifyProductsByIdsDocument = gql`
  query shopifyProductsByIds($input: ShopifyProductsByIdsInput!) {
    shopifyProductsByIds(input: $input) {
      ...ShopifyProduct
    }
  }
  ${ShopifyProductFragmentDoc}
`;
export const TranslationDocument = gql`
  query translation($id: ID!) {
    translation(id: $id) {
      id
      locale
      isRtl
      messages {
        key
        value
      }
    }
  }
`;
export const TranslationsDocument = gql`
  query translations {
    translations(paging: { first: 50 }) {
      edges {
        node {
          id
          locale
        }
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    activateFlow(
      variables: ActivateFlowMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ActivateFlowMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ActivateFlowMutation>(
            ActivateFlowDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "activateFlow"
      );
    },
    createOneContainer(
      variables: CreateOneContainerMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateOneContainerMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOneContainerMutation>(
            CreateOneContainerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createOneContainer"
      );
    },
    createOneFlow(
      variables: CreateOneFlowMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateOneFlowMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOneFlowMutation>(
            CreateOneFlowDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createOneFlow"
      );
    },
    createOneFlowNode(
      variables: CreateOneFlowNodeMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateOneFlowNodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOneFlowNodeMutation>(
            CreateOneFlowNodeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createOneFlowNode"
      );
    },
    createOneIntegration(
      variables: CreateOneIntegrationMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateOneIntegrationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOneIntegrationMutation>(
            CreateOneIntegrationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createOneIntegration"
      );
    },
    deleteFlowNode(
      variables: DeleteFlowNodeMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteFlowNodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteFlowNodeMutation>(
            DeleteFlowNodeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "deleteFlowNode"
      );
    },
    deleteOneIntegration(
      variables: DeleteOneIntegrationMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteOneIntegrationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteOneIntegrationMutation>(
            DeleteOneIntegrationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "deleteOneIntegration"
      );
    },
    updateFlowContainerOgImage(
      variables: UpdateFlowContainerOgImageMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateFlowContainerOgImageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateFlowContainerOgImageMutation>(
            UpdateFlowContainerOgImageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateFlowContainerOgImage"
      );
    },
    updateFlowLogic(
      variables: UpdateFlowLogicMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateFlowLogicMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateFlowLogicMutation>(
            UpdateFlowLogicDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateFlowLogic"
      );
    },
    updateFlowNodesOrder(
      variables: UpdateFlowNodesOrderMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateFlowNodesOrderMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateFlowNodesOrderMutation>(
            UpdateFlowNodesOrderDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateFlowNodesOrder"
      );
    },
    updateOneContainer(
      variables: UpdateOneContainerMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateOneContainerMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateOneContainerMutation>(
            UpdateOneContainerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateOneContainer"
      );
    },
    updateOneFlow(
      variables: UpdateOneFlowMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateOneFlowMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateOneFlowMutation>(
            UpdateOneFlowDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateOneFlow"
      );
    },
    updateOneFlowNode(
      variables: UpdateOneFlowNodeMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateOneFlowNodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateOneFlowNodeMutation>(
            UpdateOneFlowNodeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateOneFlowNode"
      );
    },
    updateOneIntegration(
      variables: UpdateOneIntegrationMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateOneIntegrationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateOneIntegrationMutation>(
            UpdateOneIntegrationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateOneIntegration"
      );
    },
    updateUrlFileForOption(
      variables: UpdateUrlFileForOptionMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateUrlFileForOptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUrlFileForOptionMutation>(
            UpdateUrlFileForOptionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateUrlFileForOption"
      );
    },
    uploadFileForOption(
      variables: UploadFileForOptionMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UploadFileForOptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UploadFileForOptionMutation>(
            UploadFileForOptionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "uploadFileForOption"
      );
    },
    uploadImageForFlowNode(
      variables: UploadImageForFlowNodeMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UploadImageForFlowNodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UploadImageForFlowNodeMutation>(
            UploadImageForFlowNodeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "uploadImageForFlowNode"
      );
    },
    uploadLogoForFlow(
      variables: UploadLogoForFlowMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UploadLogoForFlowMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UploadLogoForFlowMutation>(
            UploadLogoForFlowDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "uploadLogoForFlow"
      );
    },
    uploadNotificationLogoForFlow(
      variables: UploadNotificationLogoForFlowMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UploadNotificationLogoForFlowMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UploadNotificationLogoForFlowMutation>(
            UploadNotificationLogoForFlowDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "uploadNotificationLogoForFlow"
      );
    },
    accessToken(
      variables?: AccessTokenQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AccessTokenQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AccessTokenQuery>(AccessTokenDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "accessToken"
      );
    },
    container(
      variables: ContainerQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ContainerQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ContainerQuery>(ContainerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "container"
      );
    },
    containers(
      variables?: ContainersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ContainersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ContainersQuery>(ContainersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "containers"
      );
    },
    currentStore(
      variables?: CurrentStoreQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CurrentStoreQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CurrentStoreQuery>(CurrentStoreDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "currentStore"
      );
    },
    integrations(
      variables?: IntegrationsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<IntegrationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IntegrationsQuery>(IntegrationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "integrations"
      );
    },
    shopifyCollections(
      variables: ShopifyCollectionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ShopifyCollectionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ShopifyCollectionsQuery>(
            ShopifyCollectionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "shopifyCollections"
      );
    },
    shopifyProductTypes(
      variables: ShopifyProductTypesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ShopifyProductTypesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ShopifyProductTypesQuery>(
            ShopifyProductTypesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "shopifyProductTypes"
      );
    },
    shopifyProducts(
      variables: ShopifyProductsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ShopifyProductsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ShopifyProductsQuery>(
            ShopifyProductsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "shopifyProducts"
      );
    },
    shopifyProductsByCollection(
      variables: ShopifyProductsByCollectionQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ShopifyProductsByCollectionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ShopifyProductsByCollectionQuery>(
            ShopifyProductsByCollectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "shopifyProductsByCollection"
      );
    },
    shopifyProductsByIds(
      variables: ShopifyProductsByIdsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ShopifyProductsByIdsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ShopifyProductsByIdsQuery>(
            ShopifyProductsByIdsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "shopifyProductsByIds"
      );
    },
    translation(
      variables: TranslationQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TranslationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TranslationQuery>(TranslationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "translation"
      );
    },
    translations(
      variables?: TranslationsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TranslationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TranslationsQuery>(TranslationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "translations"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
