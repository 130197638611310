import { Box } from '@storyofams/react-ui';
import { motion } from 'framer-motion';

import config from '~/config';
import { useFlow } from '~/hooks';

import { ProductCard } from '../ProductCard';

const item = {
  hidden: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0, transition: config.transition },
};

const AnimatedBox = motion(Box);

interface ResultProps {
  idx: number;
}

export const Result = ({ idx }: ResultProps) => {
  const { data } = useFlow();

  return (
    <AnimatedBox
      variants={item}
      width={{
        _: '100%',
        sm: 'calc(50% - 16px)',
        lg: 'calc(33.33333% - 16px)',
      }}
      mx={1}
      mb={[1, 2]}
    >
      <ProductCard
        primaryColor={data?.flows?.[0]?.primaryColor}
        enableAddToCart={!!data?.flows?.[0]?.enableAddToCart}
        enableReviews={!!data?.flows?.[0]?.enableReviews}
        price="Price"
        title={`#${idx + 1} Product - Result`}
        type="white-bg"
      />
    </AnimatedBox>
  );
};
