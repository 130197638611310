import { ReactNode } from 'react';
import { Flex, SystemProps } from '@storyofams/react-ui';

interface SideMenuProps extends SystemProps {
  children?: ReactNode;
}

export const SideMenu = ({ children, ...props }: SideMenuProps) => (
  <Flex width="262px" minWidth="262px" position="relative" height="100%">
    <Flex
      width="100%"
      flexDirection="column"
      bg="white"
      position="absolute"
      top={0}
      right={0}
      left={0}
      bottom={0}
      overflowY="auto"
      overflowX="hidden"
      {...props}
    >
      {children}
    </Flex>
  </Flex>
);
