import { useMemo } from 'react';
import { Box, Flex } from '@storyofams/react-ui';
import { AnimatePresence, motion } from 'framer-motion';
import qs from 'query-string';
import { useIntl } from 'react-intl';
import { MediaInput } from '~/components';
import { BackButton, ProgressBar, TopNav } from '~/components/preview';
import config from '~/config';
import {
  File,
  FlowColorScheme,
  FlowFragmentFragment,
  FlowNodeLayout,
  FlowNodeType,
} from '~/graphql/sdk';
import { useFlow, useNavParams } from '~/hooks';

import { QuestionForm } from './forms/QuestionForm';
import { ResultsForm } from './forms/ResultsForm';
import { WelcomeForm } from './forms/WelcomeForm';
import { messages } from './messages';
interface PreviewPaneProps {
  flow: FlowFragmentFragment;
  isRtl?: boolean;
}

export const PreviewPane = ({ flow, isRtl }: PreviewPaneProps) => {
  const intl = useIntl();
  const [{ question: current }, setNavParams] = useNavParams();
  const { flowNode } = useFlow();

  const hasWelcome = flow?.nodes?.[0]?.type === FlowNodeType.Welcome;
  const isWelcome = flowNode?.type === FlowNodeType.Welcome;
  const isResults = !flowNode;
  const total = flow?.nodes?.length - (hasWelcome ? 1 : 0);
  const layout = flowNode?.layout as FlowNodeLayout;

  const bg = useMemo(() => {
    switch (flow?.colorScheme) {
      case FlowColorScheme.Cool:
        return '#F4F6FB';
      case FlowColorScheme.Warm:
        return '#FBF8F4';
      default:
        return '#F5F5F5';
    }
  }, [flow?.colorScheme]);

  return (
    <Flex
      flexDirection="column"
      flex="1"
      borderBottomLeftRadius="md"
      borderBottomRightRadius="md"
      boxShadow="0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)"
      mb={2}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      {!!flow?.fontFamily && (
        <>
          <link
            href={`https://fonts.googleapis.com/css2?${qs.stringify({
              family: `${flow.fontFamily}:wght@400;700`,
              display: 'swap',
            })}`}
            rel="stylesheet"
          />
          <style
            dangerouslySetInnerHTML={{
              __html: `
                #preview-plane *, .preview-pane * {
                  font-family: ${flow.fontFamily};
                }
              `,
            }}
          />
        </>
      )}

      <Flex
        id="preview-plane"
        dir={isRtl ? 'rtl' : undefined}
        flexDirection={
          layout === FlowNodeLayout.MediaCoverRight ? 'row-reverse' : 'row'
        }
        flex="1"
        minWidth="0"
        width="100%"
        borderBottomLeftRadius="md"
        borderBottomRightRadius="md"
        overflow="hidden"
        bg={bg}
        mr={2}
        style={{
          fontSize: 2,
        }}
      >
        {!!flowNode &&
          [
            FlowNodeLayout.MediaCoverLeft,
            FlowNodeLayout.MediaCoverRight,
          ].includes(layout) && (
            <Flex flex="1" height="100%">
              <MediaInput
                image={flowNode.image}
                video={flowNode.video}
                alt="Cover media"
                flowNodeId={flowNode.id}
                imageProps={{
                  minHeight: '100%',
                }}
                cover
                minEmptyHeight="100%"
                minHeight="100%"
                sizes="570px"
              />
            </Flex>
          )}

        <Flex flexDirection="column" flex="1" width="100%" px={4} pb={4}>
          <TopNav
            current={current + (hasWelcome ? 0 : 1)}
            total={current >= 0 ? total : undefined}
            closeText={
              isResults || isWelcome
                ? intl.formatMessage(messages.close)
                : undefined
            }
            hasStepIndicator={
              flow?.hasStepIndicator && !isWelcome && !isResults
            }
            logo={flow?.logo as File}
          />

          <AnimatePresence exitBeforeEnter>
            {flow?.hasProgressBar !== false && !isWelcome && !isResults ? (
              <ProgressBar
                percentage={(current - (hasWelcome ? 1 : 0)) * (100 / total)}
                color={flow?.primaryColor as string}
              />
            ) : (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={config.transition}
              />
            )}
          </AnimatePresence>

          {!isResults && (
            <AnimatePresence exitBeforeEnter>
              {current > 0 ? (
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={3}
                  mb={3}
                >
                  <BackButton
                    onClick={() => {
                      if (current > 0) {
                        setNavParams({ question: `${current - 1}` });
                      }
                    }}
                  />
                </Flex>
              ) : (
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={
                    [
                      FlowNodeLayout.MediaFloatTop,
                      FlowNodeLayout.MediaFloatRight,
                    ].includes(layout)
                      ? 0
                      : 3
                  }
                  mb={
                    [
                      FlowNodeLayout.MediaFloatTop,
                      FlowNodeLayout.MediaFloatRight,
                    ].includes(layout)
                      ? 0
                      : 3
                  }
                >
                  <Box
                    height={
                      layout === FlowNodeLayout.MediaFloatTop
                        ? '8px'
                        : layout === FlowNodeLayout.MediaFloatRight
                        ? '32px'
                        : '24px'
                    }
                  />
                </Flex>
              )}
            </AnimatePresence>
          )}

          <AnimatePresence exitBeforeEnter>
            {!!flowNode && (
              <>
                {isWelcome ? (
                  <WelcomeForm
                    key="welcome"
                    total={total}
                    primaryColor={flow?.primaryColor as string}
                    mt={
                      [
                        FlowNodeLayout.MediaCoverLeft,
                        FlowNodeLayout.MediaCoverRight,
                      ].includes(layout)
                        ? '-160px'
                        : 0
                    }
                  />
                ) : (
                  <QuestionForm
                    key={`question-${flowNode.id}`}
                    data={flowNode as any}
                    total={total}
                    primaryColor={flow?.primaryColor as string}
                    hasWelcome={hasWelcome}
                  />
                )}
              </>
            )}

            {isResults && <ResultsForm />}
          </AnimatePresence>
        </Flex>
      </Flex>
    </Flex>
  );
};
