import { ComponentProps, ReactNode } from 'react';
import { Icon } from '@shopify/polaris';
import { Box, Button, css, Flex } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';

interface NavButtonProps
  extends Omit<ComponentProps<typeof Button>, 'color' | 'children'> {
  color?: 'green' | 'blue';
  dragHandle?: ReactNode;
  isActive?: boolean;
  icon?: any;
  interactive?: boolean;
  text: string | ReactNode;
}

export const NavButton = ({
  color = 'green',
  dragHandle,
  isActive,
  icon,
  interactive,
  text,
  ...props
}: NavButtonProps) => {
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      mr={1}
      pl={0.75}
      position="relative"
      {...pick(props)}
    >
      {isActive && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="3px"
          bottom={0}
          borderTopRightRadius="4px"
          borderBottomRightRadius="4px"
          bg={color === 'green' ? '#347C84' : '#2C6ECB'}
        />
      )}

      <Button
        width="100%"
        py={1}
        px={1.5}
        borderRadius="4px"
        justifyContent="flex-start !important"
        bg={
          isActive ? (color === 'green' ? '#F1F8F5' : '#F3F7FE') : 'transparent'
        }
        color={
          (interactive || isActive) && color === 'green'
            ? '#347C84'
            : interactive && color === 'blue'
            ? '#2C6ECB'
            : '#202223'
        }
        fontSize="14px"
        lineHeight="20px"
        fontWeight={600}
        variant="plain"
        opacity="1 !important"
        userSelect="none"
        css={css({
          '.Polaris-Icon': {
            fill:
              isActive && color === 'green'
                ? '#347C84'
                : (interactive || isActive) && color === 'blue'
                ? '#2C6ECB'
                : '#8C9196',
          },
          ...(!isActive
            ? {
                '&:hover, &:focus': {
                  bg: '#f6f6f7',
                  color:
                    isActive && color === 'green'
                      ? '#347C84'
                      : (interactive || isActive) && color === 'blue'
                      ? '#2C6ECB'
                      : '#202223',
                },
                '&:active': {
                  bg: '#f1f2f3',
                },
              }
            : {}),
        })}
        {...omit(props)}
      >
        {!!icon && (
          <Box width="20px" mr={1}>
            <Icon source={icon} />
          </Box>
        )}
        {text}
        {dragHandle}
      </Button>
    </Flex>
  );
};
