import { Banner, List } from '@shopify/polaris';
import { Box } from '@storyofams/react-ui';

interface ErrorBannerProps {
  error: any;
  /** @default 'An error has occured' */
  title?: string;
}

export const ErrorBanner = ({
  error,
  title = 'An error has occured',
}: ErrorBannerProps) => {
  if (!error) {
    return null;
  }

  const messages =
    error?.response?.errors?.[0]?.extensions?.exception?.response?.message;

  return (
    <Box width="100%" mb={3}>
      <Banner title={title} status="critical">
        <p>
          {messages?.length > 1 ? (
            <List type="bullet">
              {messages.map((message) => (
                <List.Item key={message}>{message}</List.Item>
              ))}
            </List>
          ) : (
            messages?.[0] || error?.message
          )}
        </p>
      </Banner>
    </Box>
  );
};
