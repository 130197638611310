import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import equal from 'fast-deep-equal';
import { pick } from 'lodash';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router';

interface ProviderProps {
  children: ReactNode;
}

interface NavParams {
  main: number;
  page: number;
  question: number;
  tab: number;
}

type NavParamsInternal = Record<keyof NavParams, string>;

export const NavParamsContext = createContext<
  [NavParams, ((change: Partial<NavParamsInternal>) => void) | (() => {})]
>([
  {
    main: 0,
    page: 0,
    question: 0,
    tab: 0,
  },
  () => {},
]);

export const NavParamsProvider = ({ children }: ProviderProps) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const params = useMemo(() => qs.parse(search), [search]);

  const [activeNavParams, setActiveNavParams] = useState({
    main: (params.main as string) || '0',
    page: (params.page as string) || '0',
    question: (params.question as string) || '0',
    tab: (params.tab as string) || '0',
  } as NavParamsInternal);

  const setNavParams = (change: Partial<NavParamsInternal>) => {
    setActiveNavParams({ ...activeNavParams, ...change });
  };

  useEffect(() => {
    const relevantParams = pick(params, ['main', 'page', 'question', 'tab']);

    if (!equal(activeNavParams, relevantParams)) {
      history.replace({
        pathname,
        search: qs.stringify({ ...relevantParams, ...activeNavParams }),
      });
    }
  }, [activeNavParams]);

  useEffect(() => {
    if (
      (params?.main && activeNavParams.main !== params.main) ||
      (params?.page && activeNavParams.page !== params.page) ||
      (params?.question && activeNavParams.question !== params.question) ||
      (params?.tab && activeNavParams.tab !== params.tab)
    ) {
      setNavParams(pick(params, ['main', 'page', 'question', 'tab']) as any);
    }
  }, [params]);

  const navParams = useMemo(
    () =>
      Object.keys(activeNavParams).reduce((all, current) => {
        all[current] = parseInt(activeNavParams[current], 10);
        return all;
      }, {}) as NavParams,
    [activeNavParams],
  );

  return (
    <NavParamsContext.Provider value={[navParams, setNavParams]}>
      {children}
    </NavParamsContext.Provider>
  );
};
