import { forwardRef } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';
import { omit, pick } from '@styled-system/props';
import FontPicker from 'font-picker-react';
import styled from 'styled-components';

import config from '~/config';

const Wrapper = styled(Box)`
  #font-picker {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid #babfc3;
    border-radius: 4px;
    width: 100%;

    .dropdown-button {
      background: #fff;
      border-radius: 4px;
    }

    .font-list {
      background: #fff;
      z-index: 9999;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2),
        0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-top: 1px;

      li button {
        &:hover,
        &:focus {
          background: #f2f7fe;
        }
      }
    }
  }
`;

interface FontInputProps extends SystemProps {
  onChange(value: string): void;
  value: string;
}

export const FontInput = forwardRef(
  ({ onChange, value, ...props }: FontInputProps, ref) => (
    <Wrapper width="100%" {...pick(props)}>
      <FontPicker
        apiKey={config.googleFontsApiKey as string}
        activeFontFamily={value}
        onChange={(nextFont) => {
          onChange(nextFont.family);
        }}
        ref={ref}
        {...omit(props)}
      />
    </Wrapper>
  ),
);
