import { Stack, Icon, Link, TextStyle } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import { Flex } from '@storyofams/react-ui';

import { MainPane, LeftSideMenu, RightSideMenu } from '~/components';
import { useFlow, useIsRtl, useNavParams } from '~/hooks';

export const QuestionsPage = () => {
  const { data } = useFlow();
  const [{ main }] = useNavParams();
  const isRtl = useIsRtl();

  if (!data?.flows?.[0]) {
    return null;
  }

  return (
    <Flex flexDirection="row" flex="1" height="100%" width="100%">
      <LeftSideMenu />

      <Flex
        flexDirection="column"
        flex="1"
        mx={2}
        css={{
          '> div:first-child, .Polaris-Tabs__Panel:not(.Polaris-Tabs__Panel--hidden)': {
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
          },
        }}
      >
        <MainPane isRtl={isRtl} tab={main} flow={data.flows[0]} />

        <Flex pt={1} pb={3} alignItems="center" justifyContent="center">
          <Stack spacing="tight">
            <Icon source={CircleInformationMajor} color="highlight" />
            <TextStyle variation="subdued">
              Learn more about{' '}
              <Link
                external
                url="https://docs.productfinder.app/c/documentation"
              >
                building flows
              </Link>
            </TextStyle>
          </Stack>
        </Flex>
      </Flex>

      <RightSideMenu />
    </Flex>
  );
};
