import React from "react";
import { Card } from "@shopify/polaris";
import styled from "styled-components";

// CSS
const TestEmailButton = styled.button`
  background: #ffffff;
  width: 150px;
  height: 36px;
  margin-left: auto;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 22px;
  text-align: center;
  gap: 10px;
  cursor: pointer;
  float: right;
`;

export const Text = styled.p`
  font-family: "SF Pro Display";
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  font-weight: 600;
  pre {
    margin: 0;
    font-weight: 400;
    font-family: "SF Pro Display";
  }
`;

interface Props {
  render: any;
  save: () => void;
  sendTestEmail: () => void;
  subject: string;
}

const EmailPreview: React.FC<Props> = ({
  render,
  save,
  sendTestEmail,
  subject,
}: Props) => {
  return (
    <Card>
      <Card.Section>
        <Text>
          {"SUBJECT: "}
          <pre> {subject}</pre>
        </Text>
      </Card.Section>
      <Card.Section>
        <div
          style={{ minHeight: 500 }}
          className="container"
          dangerouslySetInnerHTML={{ __html: render }}
        />
      </Card.Section>
      <TestEmailButton onClick={sendTestEmail}>Send Test Email</TestEmailButton>
    </Card>
  );
};
export default EmailPreview;
