export const MediaLeft = () => (
  <svg viewBox="0 0 113 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="61" y="12" width="40" height="8" fill="currentColor" />
    <rect x="12" y="11" width="40" height="30" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 18.8C25.3373 18.8 24.8 19.3372 24.8 20V32C24.8 32.6627 25.3373 33.2 26 33.2H38C38.6627 33.2 39.2 32.6627 39.2 32V20C39.2 19.3372 38.6627 18.8 38 18.8H26ZM30 21.6C30.88 21.6 31.6 22.32 31.6 23.2C31.6 24.08 30.88 24.8 30 24.8C29.12 24.8 28.4 24.08 28.4 23.2C28.4 22.32 29.12 21.6 30 21.6ZM37.1992 31.6H26.7976C26.4696 31.6 26.2856 31.232 26.4776 30.968L29.32 27.7272C29.472 27.5592 29.736 27.5592 29.896 27.7192L31.2 29.2L33.648 25.3752C33.816 25.1352 34.168 25.1432 34.32 25.3912L37.5512 31C37.6952 31.272 37.5032 31.6 37.1992 31.6Z"
      fill="white"
    />
    <rect x="61" y="42" width="16" height="6" rx="2" fill="currentColor" />
    <rect x="61" y="24" width="36" height="4" fill="currentColor" />
    <rect
      x="0.5"
      y="0.5"
      width="112"
      height="59"
      rx="3.5"
      stroke="currentColor"
    />
  </svg>
);
