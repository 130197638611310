import { useState } from "react";
import { Page, Layout, Card, Icon, Badge } from "@shopify/polaris";
import { ChevronUpMinor, ChevronDownMinor } from "@shopify/polaris-icons";
import { useFlow, useNavParams } from "~/hooks";
import { FlowNodeCard } from "../Results/FlowNodeProducts";
import { OPTIONS } from "../sidePanes/OptionsPane/QuestionOptions";
import { FlowNodeType } from "~/graphql/sdk";
import { FlowNodeState } from "~/context";

interface Page {
  after?: string;
  before?: string;
}

export const ResultsPage = () => {
  const { data: flowContainer, flowNode: currentFlowNode } = useFlow();
  const [, setNavParams] = useNavParams();
  const flow = flowContainer?.flows?.[0];

  const [currentNode, setCurrentNode] = useState<
    FlowNodeState["id"] | undefined | null
  >(currentFlowNode?.id);

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <h1 style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
              Results
            </h1>
            <p style={{ color: "#6D7175" }}>
              Select products to match to your answers
            </p>
            {flow?.nodes.map((node, index) => {
              if (
                node.type !== FlowNodeType.Welcome &&
                node.type !== FlowNodeType.Email
              ) {
                return (
                  <Card key={node?.id}>
                    <Card.Section>
                      <div
                        onClick={() => {
                          const isOpen = currentNode === node.id;
                          if (isOpen) {
                            setCurrentNode(null);
                          } else {
                            setCurrentNode(node.id);
                            setNavParams({ question: index.toString() });
                          }
                        }}
                        style={{cursor:'pointer'}}
                      >
                        <div>
                          <h1
                              style={{
                                display: "flex",
                                fontSize: 14,
                                marginBottom: 10,
                              }}
                          >
                            {index + 1 + ". " + node.title}
                            {/*<Button*/}
                            {/*    plain*/}
                            {/*    icon={*/}
                            {/*      currentNode === node.id*/}
                            {/*          ? ChevronDownMinor*/}
                            {/*          : ChevronUpMinor*/}
                            {/*    }*/}
                            {/*></Button>*/}
                            <span>
                              <Icon source={currentNode === node.id ? ChevronDownMinor : ChevronUpMinor}/>
                            </span>
                          </h1>
                        </div>
                        <Badge>
                          {
                            OPTIONS?.find((opt) => opt?.value === node?.type)
                                ?.label
                          }
                        </Badge>
                      </div>
                    </Card.Section>
                    {currentNode === node.id ? <FlowNodeCard /> : null}
                  </Card>
                );
              } else {
                return null;
              }
            })}
          </Card>
        </Layout.Section>
        {/* <Layout.Section secondary>
          <Card title="Common Questions" sectioned>
            <hr></hr>
            <br></br>
            <Stack vertical spacing="tight">
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <br></br>
              <Button fullWidth>More Questions</Button>
            </Stack>
          </Card>
        </Layout.Section> */}
      </Layout>
    </Page>
  );
};
