import { ReactNode } from 'react';
import { Heading, Stack } from '@shopify/polaris';
import { Box, SystemProps } from '@storyofams/react-ui';
import styled from 'styled-components';

const Wrapper = styled(Box).attrs((attrs) => ({
  py: 2,
  px: 1.5,
  ...attrs,
}))`
  &:not(:last-of-type) {
    border-bottom: 1px solid #ebeaea;
  }

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface SectionProps extends SystemProps {
  children?: ReactNode;
  title?: string;
}

export const Section = ({ children, title, ...props }: SectionProps) => (
  <Wrapper {...props}>
    <Stack vertical spacing="loose">
      {!!title && <Heading>{title}</Heading>}

      {children}
    </Stack>
  </Wrapper>
);
