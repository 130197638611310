import { Box, SystemProps, Text } from '@storyofams/react-ui';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { useIsRtl } from '~/hooks';

const AnimatedBox = motion(Box);

const Progress = styled(AnimatedBox)<{
  barColor?: string;
  isRtl?: boolean;
  percentage: number;
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${(p) => p.percentage}%;
  border-radius: ${(p) => p.theme.radii.full};
  background-color: ${(p) => p.barColor || p.theme.colors.primary};

  ${(p) =>
    p.isRtl
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}

  > div {
    transform: translateX(
      ${(p) => (p.percentage > 6 ? (p.isRtl ? '100%' : '-100%') : '0')}
    );
    transition: ${(p) => (p.isRtl ? 'right' : 'left')} 0.3s ease,
      transform 0.3s ease;
  }
`;

interface ProgressBarProps extends SystemProps {
  color?: string;
  percentage: number;
}

export const ProgressBar = ({
  color,
  percentage: rawPercentage,
  ...props
}: ProgressBarProps) => {
  const isRtl = useIsRtl();

  const percentage = Math.round(Math.min(Math.max(0, rawPercentage), 100));

  return (
    <Box
      position="relative"
      borderRadius="full"
      height="8px"
      width="100%"
      bg="black4"
      mb="23px"
      {...props}
    >
      <Progress
        barColor={color}
        percentage={percentage}
        initial={{ width: 0 }}
        animate={{ width: `${percentage}%` }}
        transition={{ duration: 0.6 }}
        isRtl={isRtl}
      >
        <Text
          color="black90"
          fontSize={1.5}
          lineHeight="15px"
          position="absolute"
          bottom="-23px"
          css={
            isRtl
              ? {
                  right: percentage < 7 ? 0 : '100%',
                }
              : {
                  left: percentage < 7 ? 0 : '100%',
                }
          }
        >
          {percentage}%
        </Text>
      </Progress>
    </Box>
  );
};
