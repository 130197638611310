import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { useFlow } from '~/hooks';

interface FormSyncProps {
  control: any;
  flowNodeId: string;
}

export const useFormSync = ({ control, flowNodeId }: FormSyncProps) => {
  const { updateFlowNode } = useFlow();
  const values = useWatch({
    control,
  });

  useEffect(() => {
    if (flowNodeId && values) {
      updateFlowNode(flowNodeId, values);
    }
  }, [values]);
};
