export const CoverRight = () => (
  <svg viewBox="0 0 113 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="12" width="40" height="8" fill="currentColor" />
    <rect x="57" width="56" height="60" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79 22.8C78.3372 22.8 77.8 23.3373 77.8 24V36C77.8 36.6627 78.3372 37.2 79 37.2H91C91.6627 37.2 92.2 36.6627 92.2 36V24C92.2 23.3373 91.6627 22.8 91 22.8H79ZM83 25.6C83.88 25.6 84.6 26.32 84.6 27.2C84.6 28.08 83.88 28.8 83 28.8C82.12 28.8 81.4 28.08 81.4 27.2C81.4 26.32 82.12 25.6 83 25.6ZM90.1992 35.6H79.7976C79.4696 35.6 79.2856 35.232 79.4776 34.968L82.32 31.7272C82.472 31.5592 82.736 31.5592 82.896 31.7192L84.2 33.2L86.648 29.3752C86.816 29.1352 87.168 29.1432 87.32 29.3912L90.5512 35C90.6952 35.272 90.5032 35.6 90.1992 35.6Z"
      fill="white"
    />
    <rect x="10" y="42" width="16" height="6" rx="2" fill="currentColor" />
    <rect x="10" y="24" width="36" height="4" fill="currentColor" />
    <rect
      x="0.5"
      y="0.5"
      width="112"
      height="59"
      rx="3.5"
      stroke="currentColor"
    />
  </svg>
);
