import { AnimatePresence } from 'framer-motion';

import { EditorPane } from '~/components/sidePanes/EditorPane';
import { useNavParams } from '~/hooks';

import { SideMenu } from '../SideMenu';
// import {DesignPane} from '../DesignPane'

export const LeftSideMenu = () => {
  const [{ tab }] = useNavParams();

  return (
    <SideMenu borderRight="1px solid #EBEAEA">
      <AnimatePresence initial={false}>
        <EditorPane key={tab} />
        {/* {props.tab === '1' && <DesignPane />} */}
      </AnimatePresence>
    </SideMenu>
  );
};
