import { Flex } from '@storyofams/react-ui';
import styled from 'styled-components';

const TabWrapper = styled.div<{ selected: boolean }>`
  width: 100px;
  text-align: center;
  cursor: pointer;
  
  .status-with-line {
    
    height: 16px;
    width: 16px;
    background-color: ${(props) => (props.selected ? '#387C83' : '#E4E5E7')};
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    display: inline-block;

    &:before {
      content: '';
      display: block;
      width: 88px;
      top: 50%;
      left: 12px;
      position: relative;
      border-width: 1px 0px 1px 0px;
      border-style: solid;
      border-color: #E4E5E7;
    }

  }

  .status-without-line {
    height: 16px;
    width: 16px;
    background-color: ${(props) => (props.selected ? '#387C83' : '#E4E5E7')};
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    display: inline-block;
  }

  .text {
    color: ${(props) => (props.selected ? '#387C83' : '#1A1A1A')};
  }
}
`;

export const Tabs = ({ TABS, selectedTab, setNavParams }) => {
  return (
    <Flex minWidth={300} width={500}>
      {TABS.map((tab, idx) => (
        <TabWrapper
          selected={selectedTab === idx}
          onClick={() => {
            setNavParams({ page: `${idx}`, main: '0' });
          }}
        >
          {idx === TABS.length - 1 ? (
            <div className="status-without-line"></div>
          ) : (
            <div className="status-with-line"></div>
          )}
          <div className="text">{tab}</div>
        </TabWrapper>
      ))}
    </Flex>
  );
};
