import { ReactNode } from 'react';

import { NProgress } from './NProgress';

interface LazyLoadProps {
  children?: ReactNode;
}

export const LazyLoad = ({ children }: LazyLoadProps) => (
  <>
    <NProgress />
    {children}
  </>
);
