import styled from 'styled-components';

export const StyledFlow = styled.div`
  display: flex;
  flex: 1;

  .react-flow {
    &__node {
      padding: 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background: #fff;
      border: 1px solid #aeb4b9;
      box-sizing: border-box;
      border-radius: 8px;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        box-shadow: none;
      }

      &.selected {
        border-color: #222;
        box-shadow: none;
      }
    }

    &__handle {
      visibility: hidden;
    }

    &__edge-path,
    &__arrowhead polyline {
      stroke: #222;
    }
  }

  .edgebutton-foreignobject > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 100%;
    background-color: #f6f6f7;
  }
`;
