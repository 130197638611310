export const MediaTop = () => (
  <svg viewBox="0 0 113 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="28" y="12" width="58" height="24" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51 16.8C50.3373 16.8 49.8 17.3372 49.8 18V30C49.8 30.6627 50.3373 31.2 51 31.2H63C63.6627 31.2 64.2 30.6627 64.2 30V18C64.2 17.3372 63.6627 16.8 63 16.8H51ZM55 19.6C55.88 19.6 56.6 20.32 56.6 21.2C56.6 22.08 55.88 22.8 55 22.8C54.12 22.8 53.4 22.08 53.4 21.2C53.4 20.32 54.12 19.6 55 19.6ZM62.1992 29.6H51.7976C51.4696 29.6 51.2856 29.232 51.4776 28.968L54.32 25.7272C54.472 25.5592 54.736 25.5592 54.896 25.7192L56.2 27.2L58.648 23.3752C58.816 23.1352 59.168 23.1432 59.32 23.3912L62.5512 29C62.6952 29.272 62.5032 29.6 62.1992 29.6Z"
      fill="white"
    />
    <rect x="49" y="42" width="16" height="6" rx="2" fill="currentColor" />
    <rect
      x="0.5"
      y="0.5"
      width="112"
      height="59"
      rx="3.5"
      stroke="currentColor"
    />
  </svg>
);
