import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkProps {
  children?: ReactNode;
  external?: boolean;
  url: string;
}

export const Link = ({ children, external, url, ...props }: LinkProps) => {
  if (!url?.startsWith('/')) {
    return (
      <a
        href={url}
        {...props}
        {...(external ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={url} {...props}>
      {children}
    </RouterLink>
  );
};
