import { useState, useRef } from "react";
import { Link, Modal, TextContainer } from "@shopify/polaris";
import { useQueryClient } from "react-query";
import slugifyFn from "slugify";

import { FlowGeneralSettings } from "./FlowGeneralSettings";
import { useSdk, useFlow } from "~/hooks";
import { useToast } from "~/lib";
import { useParams } from "react-router-dom";

const slugify = (value: string) =>
  slugifyFn(value, { lower: true, strict: true });

export const Settings = () => {
  const { data } = useFlow();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const sdk = useSdk();
  const toast = useToast();

  const [isBusy, setBusy] = useState(false);
  const [error, setError] = useState<any>();
  const [isSlugWarningOpen, setSlugWarningOpen] = useState(false);
  const saveRef = useRef<any>();

  const saveFlow = async (input) => {
    try {
      await sdk.updateOneContainer({
        input: {
          id,
          update: {
            ...input,
            slug: slugify(input.slug),
          },
        },
      });

      await queryClient.invalidateQueries(["container", { id }]);

      toast({ content: "Flow saved successfully" });
      saveRef.current = null;
      setSlugWarningOpen(false);
    } catch (e: any) {
      setError({
        message: e?.message,
        messages: e?.response?.errors?.map((err) => err?.message),
      });
    }
  };

  const onSubmit = async (input) => {
    if (input.slug !== data?.slug) {
      saveRef.current = async () => saveFlow(input);
      setSlugWarningOpen(true);
    } else {
      await saveFlow(input);
    }
  };

  const saveChanges = async () => {
    if (isBusy) {
      return;
    }

    if (saveRef.current) {
      setError(null);
      setBusy(true);
      await saveRef.current();
      setBusy(false);
    }
  };

  return (
    <>
      <FlowGeneralSettings
        error={error}
        onSubmit={onSubmit}
        defaultValues={{
          name: data?.name,
          slug: data?.slug,
          seoTitle: data?.seoTitle || "",
          seoDescription: data?.seoDescription || "",
        }}
        allFields
        id={id}
        ogImage={data?.ogImage || undefined}
      />

      <Modal
        open={isSlugWarningOpen}
        onClose={() => {
          setSlugWarningOpen(false);
        }}
        title="Edit slug"
        primaryAction={{
          content: "Save",
          loading: isBusy,
          onAction: saveChanges,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            disabled: isBusy,
            onAction: () => {
              setSlugWarningOpen(false);
            },
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Updating the slug will change the URL of this flow, causing the
              old link to stop working. To make sure the old link remains
              functional you could add a{" "}
              <Link
                external
                url="https://help.shopify.com/en/manual/online-store/menus-and-links/url-redirect"
              >
                Redirect
              </Link>
              .
            </p>
            <p>Are you sure you want to update the slug?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};
