import { Page, Layout, Card, Button, Stack } from "@shopify/polaris";
import { Settings } from "../Settings";

export const SettingsPage = () => {
  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <h1 style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
              Settings
            </h1>
            <p style={{ color: "#6D7175" }}>
              Make sure your quiz is set up the way you want it to be
            </p>
            <Settings />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title="Common Questions" sectioned>
            <hr></hr>
            <br></br>
            <Stack vertical spacing="tight">
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <Button plain>How does the matching process work?</Button>
              <br></br>
              <Button fullWidth>More Questions</Button>
            </Stack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};
