import { FC } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import config from '~/config';
import { useShopOrigin } from '~/hooks';

export const CustomIntercomProvider: FC = ({ children }) => {
  const shop = useShopOrigin();

  if (!shop) {
    return <>{children}</>;
  }

  return (
    <IntercomProvider
      appId={config.intercomAppId}
      autoBoot
      autoBootProps={{ userId: shop }}
    >
      {children}
    </IntercomProvider>
  );
};
