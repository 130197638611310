import React, { useCallback, useMemo, useEffect, forwardRef } from 'react';
import { Box } from '@storyofams/react-ui';
import {
  Transforms,
  Descendant,
  createEditor,
  Node,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

export type ParagraphElement = { type: 'paragraph'; children: Descendant[] };

const withLayout = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length < 1) {
        const title: ParagraphElement = {
          type: 'paragraph',
          children: [{ text: '' }],
        };
        Transforms.insertNodes(editor, title, { at: path.concat(0) });
      }

      if (editor.children.length > 1) {
        Transforms.removeNodes(editor, editor.children[1]);
      }

      for (const [child, childPath] of Node.children(editor, path) as any) {
        const type = 'paragraph';

        if (SlateElement.isElement(child) && (child as any).type !== type) {
          const newProperties: Partial<SlateElement> = { type } as any;
          Transforms.setNodes(editor, newProperties, { at: childPath });
        }
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

interface EditorProps {
  editorRef?: any;
  dir?: 'rtl' | 'ltr' | 'auto';
  element: any;
  placeholder: string;
  value: Descendant[];
  onChange(value: Descendant[]): void;
}

export const Editor = forwardRef(
  (
    { editorRef, element, placeholder, value, onChange, dir }: EditorProps,
    ref,
  ) => {
    const renderElement = useCallback(
      (props) => <Element el={element} {...props} />,
      [],
    );

    const editor = useMemo(
      () => withLayout(withHistory(withReact(createEditor() as ReactEditor))),
      [],
    );

    useEffect(() => {
      const editorDOMNode = ReactEditor.toDOMNode(editor, editor);

      if (ref) {
        (ref as any).current = editorDOMNode;
      }

      if (editorRef) {
        editorRef.current = editorDOMNode;
      }
    }, []);

    return (
      <Box cursor="text" dir={dir}>
        <Slate editor={editor} value={value} onChange={onChange}>
          <Editable
            renderElement={renderElement}
            placeholder={React.createElement(element, {}, placeholder) as any}
            // @todo spellcheck blinks a lot on rerender, known slate issue:
            // https://github.com/ianstormtaylor/slate/pull/3888
            spellCheck={false}
          />
        </Slate>
      </Box>
    );
  },
);

const Element = ({ attributes, children, element, el }) => {
  switch (element.type) {
    case 'paragraph':
      return React.createElement(el, attributes, children);
    default:
      return <span {...attributes}>{children}</span>;
  }
};
