import React, { memo, useCallback, useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import {
  ColorPicker,
  TextField,
  Popover,
  rgbToHsb,
  rgbToHex,
  hsbToRgb,
  HSBColor,
} from "@shopify/polaris";
import validator from "validator";
import hexToRgb from "./hexToRgb";

import styles from "./style.module.scss";

interface ColorInputProps {
  onChange(value: string): void;
  value: string;
  error: any;
}

const ColorInput: React.FC<ColorInputProps> = ({ onChange, value, error }) => {
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [color, setColor] = useState<null | HSBColor>(null);

  //Convert value to HSB color.
  useEffect(() => {
    if (validator.isHexColor(value)) {
      setColor({
        ...rgbToHsb(hexToRgb(value)),
      });
    }
  }, [value]);

  const onInputChange = (value) => {
    onChange(value);
  };

  const onChangeColor = (value) => {
    delete value.alpha;
    onChange(rgbToHex(hsbToRgb(value)).toUpperCase());
  };

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((state) => !state);
  }, []);

  const activator = (
    <button
      className={styles.ColorInput__button}
      style={{ backgroundColor: `${!isEmpty(error) ? "gray" : value}` }}
      onClick={togglePopoverActive}
    />
  );
  return (
    <div className={styles.ColorInput}>
      <div className={styles.ColorInput__input}>
        <TextField
          value={value}
          onChange={onInputChange}
          label=""
          autoComplete="off"
          error={error}
        />
        <div className={styles.ColorInput__colorPreview}>
          <Popover
            active={popoverActive}
            activator={activator}
            onClose={togglePopoverActive}
            fluidContent
          >
            <div className={styles.ColorInput__colorPopover}>
              <ColorPicker color={color as HSBColor} onChange={onChangeColor} />
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default memo(ColorInput, isEqual);
