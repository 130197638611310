import {
  ComponentProps,
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { Toast } from '@shopify/polaris';

interface ContextProps {
  setToast(toast: ReactElement | null): void;
}

interface ProviderProps {
  children: ReactNode;
}

const ToastContext = createContext<ContextProps | undefined>(undefined);

export const ToastProvider = ({ children }: ProviderProps) => {
  const [toast, setToast] = useState<ReactElement | null>(null);

  return (
    <ToastContext.Provider
      value={{
        setToast,
      }}
    >
      <>
        {children}

        {toast}
      </>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);

  return (props: Pick<ComponentProps<typeof Toast>, 'content' | 'error'>) => {
    context?.setToast(
      <Toast
        {...props}
        onDismiss={() => {
          context?.setToast(null);
        }}
      />,
    );
  };
};
