import { Box } from '@storyofams/react-ui';
import { Controller } from 'react-hook-form';

import { LayoutInput } from '~/components/LayoutInput';
import { Section } from '~/components/Section';
import { FlowNodeLayout } from '~/graphql/sdk';
import { useOptionsForm } from '~/hooks';

const getDefaultValues = (values) => ({
  layout: values?.layout || FlowNodeLayout.Simple,
});

export const WelcomeOptions = () => {
  const { control } = useOptionsForm({
    getDefaultValues,
    type: 'flowNode',
  });

  return (
    <Section title="Welcome screen options">
      <Box style={{ marginBottom: 125 }}>
        <Controller
          control={control}
          name="layout"
          render={({ field: { ref, ...field } }) => <LayoutInput {...field} />}
        />
      </Box>
    </Section>
  );
};
