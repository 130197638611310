export const MediaRight = () => (
  <svg viewBox="0 0 113 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12" y="12" width="40" height="8" fill="currentColor" />
    <rect x="61" y="12" width="40" height="30" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75 19.8C74.3372 19.8 73.8 20.3372 73.8 21V33C73.8 33.6627 74.3372 34.2 75 34.2H87C87.6627 34.2 88.2 33.6627 88.2 33V21C88.2 20.3372 87.6627 19.8 87 19.8H75ZM79 22.6C79.88 22.6 80.6 23.32 80.6 24.2C80.6 25.08 79.88 25.8 79 25.8C78.12 25.8 77.4 25.08 77.4 24.2C77.4 23.32 78.12 22.6 79 22.6ZM86.1992 32.6H75.7976C75.4696 32.6 75.2856 32.232 75.4776 31.968L78.32 28.7272C78.472 28.5592 78.736 28.5592 78.896 28.7192L80.2 30.2L82.648 26.3752C82.816 26.1352 83.168 26.1432 83.32 26.3912L86.5512 32C86.6952 32.272 86.5032 32.6 86.1992 32.6Z"
      fill="white"
    />
    <rect x="12" y="42" width="16" height="6" rx="2" fill="currentColor" />
    <rect x="12" y="24" width="36" height="4" fill="currentColor" />
    <rect
      x="0.5"
      y="0.5"
      width="112"
      height="59"
      rx="3.5"
      stroke="currentColor"
    />
  </svg>
);
