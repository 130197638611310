import { useState } from 'react';
import { Button } from '@shopify/polaris';
import { Box, Flex } from '@storyofams/react-ui';
import { QuestionDeleteModal } from '~/components/QuestionDeleteModal';
import { FlowNodeType } from '~/graphql/sdk';
import { useFlow, useNavParams } from '~/hooks';

import { EmailOptions } from './EmailOptions';
import { QuestionOptions } from './QuestionOptions';
import { ResultsOptions } from './ResultsOptions';
import { WelcomeOptions } from './WelcomeOptions';

export const OptionsPane = () => {
  const { data, flowNode } = useFlow();
  const [{ question: current }] = useNavParams();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<number | boolean>(
    false,
  );

  const flow = data?.flows?.[0];
  const isWelcome = flow?.nodes?.[current]?.type === FlowNodeType.Welcome;
  const isEmail = flow?.nodes?.[current]?.type === FlowNodeType.Email;
  const isResults = !flow?.nodes?.[current];

  return (
    <>
      <Box flex="1">
        {isWelcome && <WelcomeOptions />}

        {!isWelcome && !isEmail && !isResults && (
          <QuestionOptions key={flowNode?.id} />
        )}

        {isEmail && <EmailOptions />}

        {isResults && <ResultsOptions />}

        <Flex
          justifySelf="flex-end"
          flexDirection="column"
          justifyContent="center"
          mt={3}
          pb={2}
        >
          <Button
            destructive
            plain
            onClick={() => {
              setDeleteModalOpen(current);
            }}
          >
            Remove{' '}
            {isWelcome ? 'welcome screen' : isEmail ? 'email step' : 'question'}
          </Button>
        </Flex>
      </Box>

      {!!flow && (
        <QuestionDeleteModal
          isOpen={isDeleteModalOpen}
          setOpen={setDeleteModalOpen}
          currentFlow={flow}
        />
      )}
    </>
  );
};
