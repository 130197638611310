import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { FlowBuilder, NotFound } from '~/containers';

export const Router = ({ location }: Pick<RouteComponentProps, 'location'>) => {
  return (
    <Switch location={location}>
      <Route exact path="/:id" component={FlowBuilder} />
      <Route component={NotFound} />
    </Switch>
  );
};
