import { useCallback, useState } from "react";
import { Stack, Icon, Link, TextStyle, ActionList } from "@shopify/polaris";
import {
  ChevronRightMinor,
  CircleInformationMajor,
} from "@shopify/polaris-icons";
import { Box, Flex } from "@storyofams/react-ui";

import { LogicPreview, LogicModal, Section, SideMenu } from "~/components";
import { useFlow } from "~/hooks";
import { useCheckBillingOnMount } from "~/lib";

export const LogicPage = () => {
  const { data } = useFlow();

  const [active, setActive] = useState<string | boolean>(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const flow = data?.flows?.[0];

  useCheckBillingOnMount({ freeForExistingV1Users: true });

  if (!flow) {
    return null;
  }

  return (
    <Flex flexDirection="row" flex="1" height="100%" width="100%">
      <Flex
        flexDirection="column"
        flex="1"
        mx={2}
        css={{
          "> div:first-child, .Polaris-Tabs__Panel:not(.Polaris-Tabs__Panel--hidden)": {
            display: "flex",
            flexDirection: "column",
            flex: "1",
          },
        }}
      >
        <LogicPreview flow={flow} setModalActive={setActive} />

        <Flex pt={1} pb={3} alignItems="center" justifyContent="center">
          <Stack spacing="tight">
            <Icon source={CircleInformationMajor} color="highlight" />
            <TextStyle variation="subdued">
              Learn more about{" "}
              <Link
                external
                url="https://docs.productfinder.app/c/documentation"
              >
                logic
              </Link>
            </TextStyle>
          </Stack>
        </Flex>
      </Flex>

      <SideMenu borderLeft="1px solid #EBEAEA">
        <Section title="Logic">
          <Box mx="-16px" mt="-16px">
            <ActionList
              items={[
                {
                  content: "Configure logic jumps",
                  helpText: "Edit conditional answers",
                  suffix: <Icon source={ChevronRightMinor} />,
                  onAction: toggleActive,
                },
              ]}
            />
          </Box>
        </Section>
      </SideMenu>

      <LogicModal active={active} toggleActive={toggleActive} flow={flow} />
    </Flex>
  );
};
