import { useCallback, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChoiceList, Modal } from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";
import { isBefore } from "date-fns";
import produce from "immer";
import qs from "query-string";
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Flow, FlowNodeLayout, FlowNodeType } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { useBillingWrapper, useToast } from "~/lib";
import { ErrorBanner } from "~/components";
import { OPTIONS } from "../sidePanes/OptionsPane/QuestionOptions";
import { MultiQuestionAccessModal } from "./multiBetaAccess";

interface QuestionTypeModalProps {
  active: boolean;
  currentFlow: Flow;
  klaviyoListId?: string;
  questionIndex?: number;
  setActive(active: (active: boolean) => boolean): void;
  type: "new" | "edit";
}

const schema = Yup.object().shape({
  klaviyoListId: Yup.string(),
});

export const QuestionTypeModal = ({
  active,
  currentFlow,
  questionIndex,
  setActive,
  type: modalType,
}: QuestionTypeModalProps) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const sdk = useSdk();
  const toast = useToast();
  const { pathname, search } = useLocation();
  const billingWrapper = useBillingWrapper({ freeMultiQuestionAccess: true });

  const history = useHistory();
  const params = useMemo(() => qs.parse(search), [search]);

  const [error, setError] = useState<any>(null);
  const [isBusy, setBusy] = useState(false);

  const { data: store } = useQuery(["currentStore"], () =>
    sdk.currentStore().then((res) => res.currentStore)
  );

  const [multiQuestionModal, setMultiQuestionModal] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      type: FlowNodeType.Simple,
    },
    resolver: yupResolver(schema),
  });

  const toggleActive = useCallback(
    (newValue?) => {
      setActive((active) =>
        typeof newValue === "boolean" ? newValue : !active
      );

      if (active) {
        setError(null);
      }
    },
    [active]
  );

  const createNewQuestion = async ({ values, type, isMulti }) => {
    setBusy(true);

    try {
      let result;

      if (modalType === "new") {
        if (values.klaviyoListId) {
          await sdk.updateOneContainer({
            input: {
              id,
              update: {
                integrationMetadata: {
                  klaviyo: { listId: values.klaviyoListId },
                },
              },
            },
          });
        }

        result = (
          await sdk.createOneFlowNode({
            input: {
              flowNode: {
                flowId: currentFlow.id,
                layout: FlowNodeLayout.Simple,
                isRequired: true,
                type,
                nextQuestionOnSelection: !isMulti,
                options: [],
              },
            },
          })
        ).createOneFlowNode;
      } else {
        result = (
          await sdk.updateOneFlowNode({
            input: {
              id: currentFlow?.nodes?.[questionIndex as number]?.id,
              update: {
                type,
              },
            },
          })
        ).updateOneFlowNode;
      }

      setError(null);
      toast({
        content:
          modalType === "new"
            ? type === FlowNodeType.Welcome
              ? "Welcome screen added"
              : type === FlowNodeType.Email
              ? "Email step added"
              : "Question added"
            : "Question updated",
      });

      queryClient.setQueryData(["container", { id }], (old: any) =>
        produce(old, (draft) => {
          if (type === FlowNodeType.Welcome) {
            // Add welcome at start
            draft.flows?.[0]?.nodes?.unshift(result);
          } else if (modalType === "new") {
            if (
              old.flows?.[0]?.nodes?.[old.flows?.[0]?.nodes?.length - 1]
                ?.type === FlowNodeType.Email
            ) {
              // Add before email at end
              draft.flows?.[0]?.nodes?.splice(
                old.flows?.[0]?.nodes?.length - 1,
                0,
                result
              );
            } else {
              draft.flows?.[0]?.nodes?.push(result);
            }
          } else {
            // Update existing
            const index = draft.flows?.[0]?.nodes?.findIndex(
              (node) => node?.id === result.id
            );

            if (index !== -1) {
              draft.flows[0].nodes[index] = result;
            }
          }
        })
      );

      history.replace({
        pathname,
        search: qs.stringify({
          ...params,
          main: "0",
          question:
            modalType === "new"
              ? type === FlowNodeType.Welcome
                ? 0
                : currentFlow?.nodes?.[currentFlow?.nodes?.length - 1]?.type ===
                  FlowNodeType.Email
                ? currentFlow?.nodes?.length - 1
                : currentFlow?.nodes?.length
              : (questionIndex as number),
        }),
      });
      toggleActive(false);
    } catch (e: any) {
      setError({
        message: e?.message,
        messages: e?.response?.errors?.map((err) => err?.message),
      });
    }

    setBusy(false);
  };

  const onSubmit = async ({ type, ...values }) => {
    if (
      isBusy ||
      !currentFlow ||
      (modalType === "edit" && typeof questionIndex === "undefined")
    ) {
      return;
    }

    const isMulti =
      type === FlowNodeType.EmojiMulti ||
      type === FlowNodeType.ImageMulti ||
      type === FlowNodeType.SimpleMulti;

    let isV2Launched = false;
    if (process.env.REACT_APP_LAUNCH_V2_DATE) {
      isV2Launched = isBefore(
        new Date(process.env.REACT_APP_LAUNCH_V2_DATE),
        new Date()
      );
    }

    if (isMulti) {
      //Check to see if they have access to the multi questions
      if (!store?.multiQuestionAccess && !isV2Launched) {
        toggleActive();
        setMultiQuestionModal(true);
        return;
      }

      toggleActive();
      await billingWrapper(createNewQuestion)({ values, type, isMulti });
      return;
    }

    await createNewQuestion({ values, type, isMulti });
  };

  useEffect(() => {
    if (modalType === "edit" && active && questionIndex) {
      reset({ type: currentFlow?.nodes?.[questionIndex]?.type });
    }
  }, [active]);

  if (modalType === "edit" && questionIndex === undefined) {
    return null;
  }

  return (
    <>
      <Modal
        open={active}
        onClose={toggleActive}
        title={
          modalType === "new"
            ? "Add question - choose type"
            : `Edit type of question ${(questionIndex as number) + 1}`
        }
        primaryAction={{
          content: modalType === "new" ? "Add answer" : "Save",
          onAction: handleSubmit(onSubmit),
          loading: isSubmitting,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleActive,
          },
        ]}
      >
        <Box p={2}>
          <ErrorBanner
            title={
              modalType === "new"
                ? "Error adding question:"
                : "Error changing question type:"
            }
            error={error}
          />

          <Controller
            name="type"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <ChoiceList
                title="Question type"
                titleHidden
                choices={OPTIONS}
                selected={[field.value]}
                {...field}
                onChange={(value) => field.onChange(value?.[0])}
                error={errors?.type?.message}
              />
            )}
          />
        </Box>
      </Modal>
      <MultiQuestionAccessModal
        active={multiQuestionModal}
        setActive={setMultiQuestionModal}
      />
    </>
  );
};
