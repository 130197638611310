import { Box, Flex, Icon } from '@storyofams/react-ui';
import { motion } from 'framer-motion';
import { range } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Appear } from '~/components';
import {
  Button,
  Subtext,
  Link as LinkComponent,
  Title,
  EditorController,
  Result,
} from '~/components/preview';
import { ReactComponent as refresh } from '~/components/preview/Icon/library/refresh.svg';
import config from '~/config';
import { useFlow, useOptionsForm } from '~/hooks';

import { messages } from './messages';

const MotionBox = motion(Box);

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
      ...config.transition,
    },
  },
};

const getDefaultValues = (values) => ({
  resultsTitle: values?.resultsTitle || '',
  resultsDescription: values?.resultsDescription || '',
});

const ResetLink = styled.div<{ disabled?: boolean }>`
  svg {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
  }

  &:hover svg {
    transform: rotate(30deg);
  }

  &:active svg,
  &:focus svg {
    transform: rotate(360deg);
  }

  ${(p) =>
    !!p.disabled &&
    css`
      svg {
        transform: rotate(360deg) !important;
      }
    `}
`;

export const ResultsForm = () => {
  const intl = useIntl();
  const { data } = useFlow();

  const { control } = useOptionsForm({
    getDefaultValues,
    type: 'flow',
  });

  return (
    <MotionBox
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={config.transition}
      textAlign="center"
    >
      <>
        {!!data?.flows?.[0]?.enableRestart && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0, ...config.transition }}
          >
            <Box mb={3}>
              <ResetLink as={LinkComponent} disabled>
                <Icon icon={refresh} css={{ marginInlineEnd: '8px' }} />{' '}
                <FormattedMessage {...messages.restart} />
              </ResetLink>
            </Box>
          </motion.div>
        )}

        <Appear>
          <Box maxWidth="790px" mx="auto">
            <EditorController
              name="resultsTitle"
              control={control}
              element={Title}
              dir="auto"
              placeholder={intl.formatMessage(messages.resultsTitle)}
            />

            <Box mt={2} mb={5}>
              <EditorController
                name="resultsDescription"
                control={control}
                element={Subtext}
                dir="auto"
                placeholder={intl.formatMessage(messages.resultsSubtext)}
              />
            </Box>
          </Box>
        </Appear>

        <motion.div variants={container} initial="hidden" animate="show">
          <Flex flexWrap="wrap" mx={-1} justifyContent="center">
            {range(data?.flows?.[0]?.resultsAmount || 3).map((idx) => (
              <Result key={idx} idx={idx} />
            ))}
          </Flex>
        </motion.div>

        {!!data?.flows?.[0]?.enableMoreResults && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0, ...config.transition }}
          >
            <Button size="small" variant="secondary" mt={5} disabled>
              <FormattedMessage {...messages.viewMore} />
            </Button>
          </motion.div>
        )}
      </>
    </MotionBox>
  );
};
