export const Simple = () => (
  <svg viewBox="0 0 113 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12" y="12" width="40" height="8" fill="currentColor" />
    <rect x="12" y="42" width="16" height="6" rx="2" fill="currentColor" />
    <rect x="12" y="24" width="68" height="4" fill="currentColor" />
    <rect
      x="0.5"
      y="0.5"
      width="112"
      height="59"
      rx="3.5"
      stroke="currentColor"
    />
  </svg>
);
