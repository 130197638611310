export const isDescendant = (
  el,
  parentId: string,
  type: 'class' | 'id' = 'id',
) => {
  let isChild = false;

  if (type === 'id') {
    if (el.id === parentId) {
      isChild = true;
    }
  } else if (el.className?.includes && el.className.includes(parentId)) {
    isChild = true;
  }

  while ((el = el.parentNode)) {
    if (type === 'id') {
      if (el.id === parentId) {
        isChild = true;
      }
    } else if (el.className?.includes && el.className.includes(parentId)) {
      isChild = true;
    }
  }

  return isChild;
};
