import { Icon } from '@shopify/polaris';
import { Box, Flex } from '@storyofams/react-ui';

interface NodeLabelProps {
  icon?: any;
  text: string;
}

export const NodeLabel = ({ icon, text }: NodeLabelProps) => (
  <Flex flexDirection="row">
    {!!icon && (
      <Box width="20px" mr={1}>
        <Icon source={icon} color="base" />
      </Box>
    )}
    <Box
      flex="1"
      whiteSpace="nowrap"
      overflow="hidden"
      css={{ textOverflow: 'ellipsis' }}
    >
      {text}
    </Box>
  </Flex>
);
