import { useState } from 'react';
import { Modal } from '@shopify/polaris';
import { Box } from '@storyofams/react-ui';
import { useIntercom } from 'react-use-intercom';

interface ModalProps {
  active: boolean;
  setActive: (active: boolean) => void;
}

export const MultiQuestionAccessModal = ({ active, setActive }: ModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showNewMessages } = useIntercom();

  const handleRequestAccess = () => {
    setIsLoading(true);
    showNewMessages(
      'Hi, I would like to request beta access to multiple choice questions!',
    );
    setActive(false);
    setIsLoading(false);
  };
  return (
    <>
      <Modal
        open={active}
        onClose={() => setActive(false)}
        title={'Request Early Access to Multi Choice [BETA]'}
        primaryAction={{
          content: 'Request Beta Access',
          onAction: handleRequestAccess,
          loading: isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setActive(false),
          },
        ]}
      >
        <div style={{ margin: 22 }}>
          <Box
            p={2}
            border="1px solid #E4E5E7"
            borderRadius={3}
            marginBottom={18}
          >
            <div style={{ display: 'flex', marginBottom: 11 }}>
              <img
                style={{
                  border: '1px solid #E4E5E7',
                  borderRadius: 3,
                  marginRight: 15,
                  height: 22,
                }}
                src={`${process.env.PUBLIC_URL}/images/multiQuestionAccessIcon.png`}
                alt="Multi Question Icon"
              ></img>
              <h2 style={{ fontSize: 16 }}>Multi Choice</h2>
            </div>
            <p style={{ fontSize: 14 }}>
              Allow shoppers to select multiple answers instead of just one!
            </p>
          </Box>
          <img
            style={{ border: '1px solid #E4E5E7', borderRadius: 3 }}
            src={`${process.env.PUBLIC_URL}/images/multiQuestionAccess.png`}
            alt="Multi Question Example"
          ></img>
        </div>
      </Modal>
    </>
  );
};
