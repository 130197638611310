import { useState } from "react";
import { Checkbox, Select } from "@shopify/polaris";
import { Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { MultiQuestionAccessModal } from "~/components/QuestionTypeModal/multiBetaAccess";
import { Section } from "~/components/Section";
import { FlowNodeType } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { useOptionsForm } from "~/hooks";
import { useBillingWrapper } from "~/lib";
import { isBefore } from "date-fns";

export const OPTIONS = [
  { label: "Single choice - text only", value: FlowNodeType.Simple },
  { label: "Single choice - with emoji", value: FlowNodeType.Emoji },
  { label: "Single choice - with image", value: FlowNodeType.Image },
  {
    label: "Multi choice - text only",
    value: FlowNodeType.SimpleMulti,
  },
  {
    label: "Multi choice - with emoji",
    value: FlowNodeType.EmojiMulti,
  },
  {
    label: "Multi choice - with image",
    value: FlowNodeType.ImageMulti,
  },
];

const getDefaultValues = (values) => ({
  type: values?.type || FlowNodeType.Simple,
  isRequired:
    typeof values?.isRequired !== "undefined" ? !!values.isRequired : true,
  nextQuestionOnSelection:
    typeof values?.nextQuestionOnSelection !== "undefined"
      ? !!values.nextQuestionOnSelection
      : true,
});

export const QuestionOptions = () => {
  const form = useOptionsForm({
    getDefaultValues,
    type: "flowNode",
  });
  const billingWrapper = useBillingWrapper({ freeMultiQuestionAccess: true });

  const control = form.control;

  const sdk = useSdk();
  const { data: store } = useQuery(["currentStore"], () =>
    sdk.currentStore().then((res) => res.currentStore)
  );

  const [multiQuestionModal, setMultiQuestionModal] = useState(false);

  //Wrapper function to handle case where user does not have access to multiple choice questions
  const handleQuestionTypeUpdate = (value, onChange) => {
    const type = value;
    const isMulti =
      type === FlowNodeType.EmojiMulti ||
      type === FlowNodeType.ImageMulti ||
      type === FlowNodeType.SimpleMulti;

    let isV2Launched = false;
    if (process.env.REACT_APP_LAUNCH_V2_DATE) {
      isV2Launched = isBefore(
        new Date(process.env.REACT_APP_LAUNCH_V2_DATE),
        new Date()
      );
    }

    if (isMulti) {
      if (!store?.multiQuestionAccess && !isV2Launched) {
        setMultiQuestionModal(true);
        return;
      }

      billingWrapper(onChange)(value);
      return;
    }

    onChange(value);
  };

  return (
    <>
      <MultiQuestionAccessModal
        active={multiQuestionModal}
        setActive={setMultiQuestionModal}
      />
      <Section title="Question options">
        <Controller
          control={control}
          name="type"
          render={({ field: { ref, ...field } }) => (
            <Select
              label="Type"
              options={OPTIONS}
              onChange={(value) =>
                handleQuestionTypeUpdate(value, field.onChange)
              }
              value={field.value}
              name={field.name}
            />
          )}
        />
      </Section>

      <Section>
        <Controller
          control={control}
          name="isRequired"
          render={({ field: { value, ...field } }) => (
            <Checkbox label="Required" checked={value} {...field} />
          )}
        />
      </Section>

      <Section>
        <Controller
          control={control}
          name="nextQuestionOnSelection"
          render={({ field: { value, ...field } }) => {
            const values = form.watch();
            const type = values.type;
            const isMulti =
              type === FlowNodeType.EmojiMulti ||
              type === FlowNodeType.ImageMulti ||
              type === FlowNodeType.SimpleMulti;

            //Set to false for multi choice questions
            if (isMulti && value) {
              field.onChange(false);
            }
            return (
              <Checkbox
                label="Go to next question when answer is selected"
                checked={value}
                disabled={isMulti}
                {...field}
              />
            );
          }}
        />
      </Section>
    </>
  );
};
