import { ComponentProps } from 'react';
import { TextField } from '@shopify/polaris';
import { Controller } from 'react-hook-form';

type TextFieldProps = Omit<ComponentProps<typeof TextField>, 'onChange'>;

interface InputProps extends TextFieldProps {
  control: any;
  name: string;
}

export const Input = ({ name, control, ...props }: InputProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, ...field } }) => (
      <TextField
        {...field}
        onChange={(value) => field.onChange(value)}
        {...props}
        autoComplete={props.autoComplete || 'off'}
      />
    )}
  />
);
