import { useState, useMemo, useEffect, FC } from "react";
import {
  Page,
  Layout,
  Card,
  Button,
  Stack,
  Banner,
  TextField,
  Spinner,
} from "@shopify/polaris";
import { ExternalSmallMinor } from "@shopify/polaris-icons";
import copy from "copy-to-clipboard";
import { Flex } from "@storyofams/react-ui";
import { FlowContainerStatus } from "~/graphql/sdk";
import { IPublishErrors, useFlow } from "~/hooks";
import { useShopOrigin } from "~/hooks";
import { ErrorBanner } from "~/components";
import { useBillingWrapper, hasPaidFeatureAccess } from "~/lib";

/* 
1. Check for errors, prevent publishing until the errors are resolved
2. After publishing, show options 
*/
interface IPublishPageProps {
  publishErrorInfo: IPublishErrors;
}

export const PublishPage: FC<IPublishPageProps> = ({ publishErrorInfo }) => {
  const { data, publishFlow } = useFlow();
  const shopOrigin = useShopOrigin();
  const billingWrapper = useBillingWrapper();

  const {
    publishError,
    setPublishError,
    errorsLength,
    isLoading,
    setIsLoading,
  } = publishErrorInfo;
  const [isPublished, setIsPublished] = useState(false);

  const flowLink = useMemo(
    () =>
      `https://${shopOrigin}/tools/${process.env.REACT_APP_SHOPIFY_APP_NAME}/${data?.slug}`,
    [data]
  );
  const quizSubUrl = useMemo(() => `tools/${process.env.REACT_APP_SHOPIFY_APP_NAME}/${data?.slug}`, [data]);
  const openThemeEditorUrl = useMemo(() => `https://${shopOrigin}/admin/themes/current/editor`, [data])

  const republishing = data?.status === FlowContainerStatus.Published;

  /*
    TODO: (returns if changes we're made)
    For this to work we need to edit the sdk to return createdAt and UpdatedAt
    However, this causes an infinite loop in the auto saving of the form in `UseFormSync`
  */
  // const unpublishedChanges = useMemo(() => {
  //   if (!draftFlow) {
  //     return false;
  //   }

  //   if (draftFlow.createdAt !== draftFlow.updatedAt) {
  //     return true;
  //   }

  //   for (let i = 0; i < draftFlow.nodes.length; i += 1) {
  //     const { createdAt, updatedAt } = draftFlow.nodes[i];

  //     if (createdAt !== updatedAt) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }, [data]);

  useEffect(() => {
    //Checks to see if there are any errors that need to be addressed
    publish({ validateOnly: true });
  }, [data]);

  const publish = async ({ validateOnly }) => {
    setIsLoading(true);
    try {
      const success = await publishFlow({ validateOnly: validateOnly });
      if (success && !validateOnly) {
        setIsPublished(true);
      }
      setPublishError(null);
    } catch (e) {
      setPublishError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const content = useMemo(() => {
    if (isLoading && !isPublished) {
      return (
        <Flex justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      );
    }
    if (!!errorsLength) {
      return (
        <>
          {" "}
          <ErrorBanner
            title={`To publish this flow, ${errorsLength} ${
              errorsLength > 1 ? "changes need" : "change needs"
            } to be made:`}
            error={publishError}
          />
          <p>Please fix the errors above to be able to publish the flow.</p>
        </>
      );
    }
    return isPublished ? (
      <div>
        <Banner
          title="Success! Your quiz has been published. Add to storefront using a method below."
          status="success"
          action={{
            content: "🎉",
          }}
        ></Banner>
        <br></br>
        <Card title="Add to a dedicated page">
          <Card.Section>
            <Stack vertical>
              <p>
                {"Here is the link to your quiz: "}

                <Button onClick={() => window.open(flowLink)} plain>
                  {flowLink}
                </Button>
              </p>
              <Button onClick={() => copy(flowLink)}>Copy quiz URL</Button>
            </Stack>
          </Card.Section>
        </Card>
        <br></br>
        <Card title="Add to section on the home page">
          <Card.Section>
            <Stack vertical spacing="tight">
              <Stack distribution="fill" alignment="trailing">
                <TextField
                  value={!hasPaidFeatureAccess() ? quizSubUrl : "Upgrade to access this feature"}
                  label="Copy the quiz key"
                  disabled
                  autoComplete="off"
                />
                <Button
                  onClick={() => {
                    billingWrapper(() => {copy(quizSubUrl)})();
                  }}
                >
                  Copy Key
                </Button>
              </Stack>
              <br></br>
              <Stack alignment="center">
                <Button onClick={() => window.open(openThemeEditorUrl, '_blank') }>Go to theme editor</Button>
                <Button icon={ExternalSmallMinor} plain>
                  Watch Tutorial
                </Button>
              </Stack>
            </Stack>
          </Card.Section>
        </Card>
      </div>
    ) : (
      <Banner
        title={`Your quiz is ready to ${
          republishing ? "republish" : "publish"
        }!`}
        status="success"
        action={{
          content: republishing ? "Republish" : "Publish",
          onAction: () => publish({ validateOnly: false }),
        }}
        onDismiss={() => {}}
      >
        <p>
          Click on the Publish button below to get more options on where to
          publish your quiz.
        </p>
      </Banner>
    );
  }, [
    isLoading,
    publishError,
    errorsLength,
    isPublished,
    flowLink,
    republishing,
    publish,
  ]);

  return (
    <div>
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <h1 style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
                Publish
              </h1>
              <p style={{ color: "#6D7175" }}>
                Put your quiz out in front of customers
              </p>
              <Card.Section>{content}</Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Common Questions" sectioned>
              <hr></hr>
              <br></br>
              <Stack vertical spacing="tight">
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <Button plain>How does the matching process work?</Button>
                <br></br>
                <Button fullWidth>More Questions</Button>
              </Stack>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );
};
