import { FormLayout } from "@shopify/polaris";
import * as Yup from "yup";

import { Input } from "~/components";
import { IntegrationModal, IntegrationModalProps } from "../IntegrationModal";

const schema = Yup.object().shape({
  publicKey: Yup.string().required(),
  privateKey: Yup.string().required(),
});

export const KlaviyoModal = (
  props: Omit<
    IntegrationModalProps,
    "getDefaultValues" | "schema" | "renderInputs"
  >
) => {
  return (
    <IntegrationModal
      {...props}
      getDefaultValues={(integration) => ({
        publicKey: integration?.publicKey || "",
        privateKey: "",
      })}
      schema={schema}
      renderInputs={(control, errors, integration) => (
        <FormLayout>
          <Input
            autoComplete="off"
            name="publicKey"
            control={control}
            label="Public API Key"
            error={errors?.publicKey?.message}
            labelAction={{
              content: "Where do I find this?",
              url: "https://docs.productfinder.app/c/integrations/klaviyo",
              external: true,
            }}
            placeholder="xxxxxx"
          />
          <Input
            name="privateKey"
            control={control}
            label="Private API Key"
            error={errors?.privateKey?.message}
            labelAction={{
              content: "Where do I find this?",
              url: "https://docs.productfinder.app/c/integrations/klaviyo",
              external: true,
            }}
            placeholder={
              integration?.hasPrivateKey
                ? "•••••••••••••••••••"
                : "pk_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            }
            type="password"
            autoComplete="new-password"
          />
        </FormLayout>
      )}
    />
  );
};
