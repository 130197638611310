import { createContext, ReactNode } from 'react';

interface ProviderProps {
  children: ReactNode;
  shop: string;
}

export const ShopContext = createContext<string | undefined>(undefined);

export const ShopProvider = ({ children, shop }: ProviderProps) => (
  <ShopContext.Provider value={shop}>{children}</ShopContext.Provider>
);
