import { forwardRef, ReactNode, useRef } from 'react';
import { Icon, Tooltip } from '@shopify/polaris';
import { DragHandleMinor } from '@shopify/polaris-icons';
import { Box, SystemProps, Text } from '@storyofams/react-ui';
import { Controller } from 'react-hook-form';
import { SortableHandle } from 'react-sortable-hoc';
import styled, { css } from 'styled-components';

import { File } from '~/graphql/sdk';
import { EditorController } from '../EditorController';
import { Option, OptionProps } from '../Option';

import { ImageUpload } from './ImageUpload';

interface OptionImageProps {
  control: any;
  field: any;
  index: number;
  src?: File;
  register: any;
  /** @default not-transparent */
  type?: 'not-transparent' | 'white-bg';
  optionId: string;
  flowNodeId: string;
}

const StyledOption = styled(Option)<Pick<OptionImageProps, 'type'>>`
  ${(p) =>
    p.isActive &&
    css`
      && {
        ${p.type === 'white-bg'
          ? css`
              background-color: white;
            `
          : css`
              border-color: ${p.theme.colors.black60};
            `}
      }
    `}
`;

const DragHandle = SortableHandle(() => (
  <Box position="absolute" top={0} left={0} zIndex={99}>
    <Tooltip content="Drag to change order" dismissOnMouseOut>
      <Box
        className="drag-handle"
        css={{
          '.Polaris-Icon': {
            width: '24px',
            height: '24px',
          },
        }}
        cursor="grab"
        p={1}
        bg="white"
        borderTopLeftRadius="8px"
        borderBottomRightRadius="8px"
        boxShadow="lg"
      >
        <Icon source={DragHandleMinor} color="subdued" />
      </Box>
    </Tooltip>
  </Box>
));

const Answer = forwardRef(
  (
    {
      children,
      type,
    }: {
      children?: ReactNode;
      type: OptionImageProps['type'];
    },
    ref,
  ) => (
    <Text
      ref={ref as any}
      fontSize={2}
      lineHeight="19px"
      color={type === 'white-bg' ? 'black90' : 'black80'}
    >
      {children}
    </Text>
  ),
);

export const OptionImage = ({
  isActive,
  src,
  type,
  control,
  index,
  field,
  register,
  flowNodeId,
  optionId,
  ...props
}: OptionImageProps & Omit<OptionProps, 'children'> & SystemProps) => {
  const editorRef = useRef<any>();

  return (
    <StyledOption
      flexDirection="column"
      type={type}
      isActive={isActive}
      {...props}
    >
      <>
        <Box
          position="relative"
          height={type === 'white-bg' ? '210px' : '180px'}
          width="100%"
        >
          <ImageUpload
            src={src}
            index={index}
            flowNodeId={flowNodeId}
            optionId={optionId}
          />
        </Box>

        <Box
          p={3}
          pt={type === 'white-bg' ? 2 : 3}
          textAlign="start"
          width="100%"
        >
          <Controller
            control={control}
            name={`options.${index}.id`}
            defaultValue={field.id}
            render={(input) => <input type="hidden" {...input.field} />}
          />

          <EditorController
            name={`options.${index}.label`}
            control={control}
            element={Answer as any}
            placeholder="Your answer here ..."
            editorRef={editorRef}
            defaultValue={field.label}
          />

          {/* {!!subtext && (
          <Text mt={0.25} fontSize={1.5} lineHeight="15px" color="black60">
            {subtext}
          </Text>
        )} */}
        </Box>

        <DragHandle />
      </>
    </StyledOption>
  );
};
