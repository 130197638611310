import React from "react";
import { Card, Heading, Icon, IconSource } from "@shopify/polaris";
import styled from "styled-components";

const Title = styled.div`
  display: flex;

  [class~="Polaris-Icon"] {
    display: inline-block;
    margin: 0;
    margin-right: 0.7rem;
  }
  [class~="Polaris-Heading"] {
    display: inline-block;
  }
`;

interface DescriptionCardProps {
  icon: IconSource;
  title: string;
  content?: string;
}

const DescriptionCard: React.FC<DescriptionCardProps> = ({
  icon,
  title,
  content,
}) => {
  return (
    <Card
      title={
        <Title>
          <Icon source={icon} />
          <Heading>{title}</Heading>
        </Title>
      }
    >
      {!!content && <Card.Section>{content}</Card.Section>}
    </Card>
  );
};

export default DescriptionCard;
