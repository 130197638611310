import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Layout,
  Card,
  DropZone,
  Form,
  FormLayout,
  Button,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import slugifyFn from "slugify";
import * as Yup from "yup";

import { ErrorBanner, Input } from "~/components";
import { FileUnion } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { getErrorLength, getFileUrl, useToast } from "~/lib";

const schema = Yup.object().shape({
  name: Yup.string().required(),
  slug: Yup.string().required(),
  seoTitle: Yup.string(),
  seoDescription: Yup.string(),
});

const slugify = (value: string) =>
  slugifyFn(value, { lower: true, strict: true });

interface FlowGeneralSettingsProps {
  allFields?: boolean;
  defaultValues?: any;
  error?: any;
  id?: string;
  ogImage?: FileUnion;
  onSubmit(values: any): void;
  submitText?: string;
}

export const FlowGeneralSettings = ({
  allFields,
  defaultValues,
  error,
  id,
  ogImage,
  onSubmit,
  submitText,
}: FlowGeneralSettingsProps) => {
  const sdk = useSdk();
  const toast = useToast();

  const [isBusy, setBusy] = useState(false);
  const [file, setFile] = useState<any>();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const uploadFile = async (file) => {
    if (isBusy) {
      return;
    }

    setBusy(true);

    try {
      await sdk.updateFlowContainerOgImage({
        input: {
          id,
          ogImage: file,
        },
      });

      setFile(window.URL.createObjectURL(file));
    } catch (e) {
      toast({ content: "Error uploading image", error: true });
    }

    setBusy(false);
  };

  const handleDrop = (_droppedFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.[0]) {
      toast({
        content: `"${rejectedFiles?.[0].name}" is not supported. File type must be .jpg or .png.`,
        error: true,
      });
    } else if (acceptedFiles?.[0]) {
      uploadFile(acceptedFiles[0]);
    }
  };

  useEffect(
    () => () => {
      if (file) {
        window.URL.revokeObjectURL(file);
      }
    },
    []
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!!error && (
        <ErrorBanner
          title={`To save this flow, ${getErrorLength(error)} ${
            getErrorLength(error) > 1 ? "changes need" : "change needs"
          } to be made:`}
          error={error}
        />
      )}

      <Card.Section>
        <h1
          style={{
            fontSize: 16,
            fontWeight: 600,
            marginTop: 22,
          }}
        >
          General Settings
        </h1>
        <br></br>
        <FormLayout>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                autoComplete="off"
                onChange={(value) => {
                  field.onChange(value);
                  setValue("slug", slugify(value), {
                    shouldValidate: true,
                  });
                }}
                label="Quiz Flow Name"
                error={errors?.name?.message}
              />
            )}
          />
          <Controller
            name="slug"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                autoComplete="off"
                onChange={(value) => {
                  field.onChange(slugify(value));
                }}
                label="Quiz Flow Slug"
                helpText="This will be part of the path (URL) where this finder can be accessed"
                error={errors?.slug?.message}
              />
            )}
          />
        </FormLayout>
        <br></br>
        <br></br>
        <h1 style={{ fontSize: 16, fontWeight: 600 }}>SEO Settings</h1>
        <br></br>
        <FormLayout>
          <Input
            autoComplete="off"
            name="seoTitle"
            control={control}
            label="Meta title"
            error={errors?.seoTitle?.message}
            placeholder="Meta title"
          />

          <Input
            autoComplete="off"
            name="seoDescription"
            control={control}
            label="Meta description"
            error={errors?.seoDescription?.message}
            placeholder="Meta description"
          />

          <Stack vertical spacing="tight">
            <DropZone
              label="Meta image"
              accept="image/*"
              type="image"
              onDrop={handleDrop}
              allowMultiple={false}
              disabled={isBusy}
            >
              {file || ogImage ? (
                <img
                  src={file || getFileUrl(ogImage)}
                  alt="Meta"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <DropZone.FileUpload
                  actionHint="or drop image to upload"
                  actionTitle="Add image"
                />
              )}
            </DropZone>

            <TextStyle variation="subdued">Dimensions 1200 x 630</TextStyle>
          </Stack>
        </FormLayout>
      </Card.Section>
      <Layout.Section>
        <Stack alignment="trailing" vertical>
          <Button submit primary disabled={!isDirty} loading={isSubmitting}>
            {submitText || "Save"}
          </Button>
        </Stack>
      </Layout.Section>
    </Form>
  );
};
