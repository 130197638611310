import { Box, Icon, SystemProps } from '@storyofams/react-ui';
import styled, { css } from 'styled-components';

import { ReactComponent as check } from '../Icon/library/check.svg';

interface CheckProps {
  isActive: boolean;
  type: 'check' | 'radio';
}

const Wrapper = styled(Box).withConfig({
  shouldForwardProp: (p, defaultValidatorFn) =>
    !['type'].includes(p) && defaultValidatorFn(p),
})<CheckProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: white;
  border: 2px solid;
  border-color: ${(p) => p.theme.colors.black20};
  border-radius: ${(p) => (p.type === 'check' ? p.theme.radii.sm : '50%')};
  transition: background-color 0.18s ease-in-out, border-color 0.18s ease-in-out;
  cursor: pointer;
  padding: ${(p) => p.theme.space[p.type === 'check' ? 0.25 : 0.5]}px;

  &:hover {
    border-color: ${(p) => p.theme.colors.black60};
  }

  ${(p) =>
    p.isActive &&
    css`
      && {
        ${p.type === 'check' &&
        `
          background-color: ${p.theme.colors.black90};
        `}
        border-color: ${p.theme.colors.black90};
      }
    `}
`;

const Circle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.black90};
`;

export const Check = ({ ...props }: CheckProps & SystemProps) => (
  <Wrapper className="guided-selling__check" {...props}>
    {props.isActive && (
      <>
        {props.type === 'check' ? (
          <Icon icon={check} color="white" />
        ) : (
          <Circle />
        )}
      </>
    )}
  </Wrapper>
);
