import React, { useMemo, useState } from "react";

type PublishError = null | {
  response?: {
    errors?: {
      extensions?: {
        exception?: {
          response?: {
            message?: string[];
          };
        };
      };
    }[];
  };
};

export interface IPublishErrors {
  publishError: PublishError;
  setPublishError: React.Dispatch<PublishError>;
  errorsLength: number;
  isLoading: boolean;
  setIsLoading: React.Dispatch<boolean>;
}

const usePublishErrors = (): IPublishErrors => {
  const [publishError, setPublishError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const errorsLength = useMemo(
    () =>
      publishError?.response?.errors?.[0]?.extensions?.exception?.response
        ?.message?.length || 0,
    [publishError]
  );

  return {
    publishError,
    setPublishError,
    errorsLength,
    isLoading,
    setIsLoading,
  };
};

export default usePublishErrors;
