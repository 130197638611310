import { ActionList, Badge, Icon, Stack } from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import { Box } from '@storyofams/react-ui';

import { Section } from '~/components/Section';
import { useFlow, useShopOrigin } from '~/hooks';

export const EmailOptions = () => {
  const { data } = useFlow();
  const shop = useShopOrigin();

  return (
    <Section title="Email capture options">
      <Box mx="-16px" mt="-16px">
        <ActionList
          items={[
            {
              // @ts-ignore
              content: (
                <Stack spacing="extraTight">
                  <Stack.Item>Configure Klaviyo</Stack.Item>
                  {!!data?.integrationMetadata?.klaviyo?.listId && (
                    <Stack.Item>
                      <Badge status="success" size="small">
                        Active
                      </Badge>
                    </Stack.Item>
                  )}
                </Stack>
              ),
              helpText: 'Save captured emails',
              suffix: <Icon source={ChevronRightMinor} />,
              url: `https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/flows/${data?.id}/connect`,
              external: true,
            },
            {
              // @ts-ignore
              content: (
                <Stack spacing="extraTight">
                  <Stack.Item>Configure Mailchimp</Stack.Item>
                  {!!data?.integrationMetadata?.mailchimp?.listId && (
                    <Stack.Item>
                      <Badge status="success" size="small">
                        Active
                      </Badge>
                    </Stack.Item>
                  )}
                </Stack>
              ),
              helpText: 'Save captured emails',
              suffix: <Icon source={ChevronRightMinor} />,
              url: `https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/flows/${data?.id}/connect`,
              external: true,
            },
          ]}
        />
      </Box>
    </Section>
  );
};
