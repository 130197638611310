import { createContext, ReactNode, useState } from 'react';
import { Detector } from 'react-detect-offline';

interface ProviderProps {
  children: ReactNode;
}

export const OfflineContext = createContext<boolean>(false);

export const OfflineProvider = ({ children }: ProviderProps) => {
  const [isOffline, setOffline] = useState(false);

  return (
    <OfflineContext.Provider value={isOffline}>
      {children}

      <Detector
        onChange={(online: boolean) => {
          if (!online !== isOffline) {
            setOffline(!online);
          }
        }}
        render={() => <></>}
      />
    </OfflineContext.Provider>
  );
};
