import React from "react";
import {
  Card,
  Button,
  TextField,
  DropZone,
  Stack,
  Icon,
  TextStyle,
} from "@shopify/polaris";
import styled from "styled-components";
import Editor from "@monaco-editor/react";
import ColorInput from "./ColorInput";
import validator from "validator";
import { useShopOrigin, useSdk } from "~/hooks";
import { UploadMajor } from "@shopify/polaris-icons";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";

enum ToastLabel {
  SavedSuccessfully = "Saved successfully!",
  SaveError = "Save error",
  FileUploadError = "Error Uploading File",
}

export const Text = styled.p`
  font-family: "SF Pro Display";
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  font-weight: 600;
  margin-bottom: 15px;
  pre {
    margin: 0;
    font-weight: 400;
    font-family: "SF Pro Display";
  }
`;

const AddImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 221px;
  .Polaris-Icon: {
    width: '40px',
    height: '40px',
  },
`;

interface Props {
  flowId: string;
  file: string;
  setFile: (value: string) => void;
  color: string;
  setColor: (value: string) => void;
  questionsEmail: string;
  setQuestionsEmail: (value: string) => void;
  subject: string;
  setSubject: (value: string) => void;
  logoWidth: string;
  setLogoWidth: (value: string) => void;
  emailTemplate: string;
  setEmailTemplate: (value: string) => void;
  save: () => void;
}

const ThemeSettings: React.FC<Props> = ({
  flowId,
  file,
  setFile,
  color,
  setColor,
  questionsEmail,
  setQuestionsEmail,
  subject,
  setSubject,
  logoWidth,
  setLogoWidth,
  emailTemplate,
  setEmailTemplate,
  save,
}) => {
  const shop = useShopOrigin();

  const queryClient = useQueryClient();
  const sdk = useSdk();
  const { id } = useParams<{ id: string }>();

  const uploadLogo = async (file: any, domain) => {
    try {
      await sdk.uploadNotificationLogoForFlow({
        input: { flowId: flowId, logo: file },
      });

      setFile(window.URL.createObjectURL(file));

      queryClient.invalidateQueries(["container", { id }]);
    } catch (e) {
      toast.error(ToastLabel.FileUploadError);
    }
  };

  const handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) => {
    if (acceptedFiles.length === 0) {
      toast.error("Error uploading file. Make sure it is a valid image type");
      return;
    }

    // Validate that the file is less than 500kb = 500,000 bits
    if (acceptedFiles[0].size > 500000) {
      toast.error("Error: File is larger than 500KB");
    } else {
      uploadLogo(acceptedFiles[0], shop);
    }
  };

  return (
    <Card title="Theme Settings">
      <div style={{ marginTop: 20 }}>
        <hr />
      </div>

      <Card.Section>
        <Text>
          {"Logo "}
          <pre> (Less than 500KB; Accepts .jpg, .png, .svg, .gif, .jpeg.)</pre>
        </Text>
        <div
          style={{ width: "100%", overflow: "hidden", position: "relative" }}
        >
          <DropZone
            accept="image/*"
            type="image"
            allowMultiple={false}
            onDrop={handleDropZoneDrop}
            variableHeight
          >
            <AddImage
              style={{
                backgroundImage: `url(${encodeURI(file)})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Stack vertical alignment="center" spacing="tight">
                <Icon source={UploadMajor} color="base" />
                <div style={{ opacity: "75%" }}>
                  <Button>{file ? "Change image" : "Add image"}</Button>
                </div>
                <TextStyle variation="subdued">
                  or drop image to upload
                </TextStyle>
              </Stack>
            </AddImage>
          </DropZone>
        </div>
      </Card.Section>
      <Card.Section>
        <Text>Logo Width</Text>
        <TextField
          label=""
          type="number"
          value={logoWidth}
          onChange={setLogoWidth}
          autoComplete="off"
        />
      </Card.Section>
      <Card.Section>
        <Text>Accent Color</Text>
        <ColorInput
          onChange={(value) => setColor(value)}
          value={color}
          error={
            validator.isHexColor(color)
              ? ""
              : "Invalid Hex Color Code (example: #21AF45)"
          }
        />
      </Card.Section>
      <Card.Section>
        <Text>Questions Email</Text>
        <TextField
          label=""
          type="email"
          value={questionsEmail}
          autoComplete="email"
          onChange={(value) => setQuestionsEmail(value)}
          error={validator.isEmail(questionsEmail) ? "" : "Invalid Email"}
        />
      </Card.Section>
      <Card.Section>
        <Text>Email Subject</Text>
        <TextField
          label=""
          value={subject}
          autoComplete="off"
          onChange={(value) => setSubject(value)}
          error={subject.length !== 0 ? "" : "Subject can not be empty"}
        />
      </Card.Section>
      <Card.Section>
        <Text>Edit Email</Text>
        <Editor
          height="512px"
          defaultLanguage="handlebars"
          value={emailTemplate}
          onChange={(value) => setEmailTemplate(value || "")}
        />
      </Card.Section>
    </Card>
  );
};
export default ThemeSettings;
