import { createContext, ReactNode, useEffect } from 'react';

interface ProviderProps {
  children: ReactNode;
  token: string;
}

export const TokenContext = createContext<string | undefined>(undefined);

export const TokenProvider = ({ children, token }: ProviderProps) => {
  useEffect(() => {
    if (token) {
      localStorage.setItem(`ppf-token`, token);
    }
  }, [token]);

  return (
    <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
  );
};
