import styled from 'styled-components';

export const Link = styled.a`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  transition: 0.2s color ease-out;

  > span {
    margin-left: 8px;
  }

  &:hover,
  &:focus {
    &,
    > span {
      color: var(--p-text);
    }
  }
`;
