export const htmlEmail = `<!DOCTYPE html>
<html lang="en">
<!-- Documentation: https://handlebarsjs.com/guide/  -->    
<head>
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<!-- The styling is divided into 3 parts -->
<!-- A global class called "email" -->
<!-- A class called "product" for the products -->
<!-- A class called "response" for the questions & answers -->
<style>
.email {
    text-align:center;
}
.email p {
    text-align:start;
    color: #646466;
    font-size: 16px;
}
.email h1,
.email h2 {
    color: {{accentColor}};
}
.email h1 {
  font-size: 20px;
  font-weight: bolder;
}
.email h2 {
  font-size: 20px;
  text-align: start;
  font-weight: bold;
}
.productsrow {
    display: flex;
}
.product {
    font-weight: bold;
    margin-right: auto;
}
.product p {
    font-weight: bold;
    text-align: start;
}
.product__description {
    margin-right:auto;
}
.product__description h3 {
    color: #332233;
    max-width: 111px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 15px;
}
.product__description p{
    color:#727272;
    font-size:16px;
    margin-bottom: 5px;
}
.product_buy {
    background-color: {{accentColor}};
    width: 111px;
    padding: 5px 10px;
    border-radius: 3px;
}

.responses {
    text-align: left
}
.response h3 {
    color: #332233;
    font-weight: bold;
}
.response p {
    color:#727272;
    font-size:16px;
    margin-bottom: 5px;
}
</style>
</head>

<body>
<div class="email" id="email" style="width:100%;max-width:600px">
    
<!-- Logo -->
<table role="presentation" border="0" cellspacing="0" width="100%">
<tr> <td align="center" style="padding:0;">
<img src="{{logo}}" alt="logo" width="{{logoWidth}}"/>
</td> </tr>

<!-- Under Logo Text  -->
<tr> <td align="center" style="padding:0;">
<br/>
<h1 style="color:{{accentColor}};fontSize:21px;font-weight:bolder" > Thanks for taking our quiz! Here are your results. </h1>
<br/>
</td> </tr>

<!-- Middle Text -->
<tr> <td style="padding:0;">
<br/>
<p >Hello,</p>
<br/>
<p > Thanks for taking our quiz. We’ve compiled your results below. </p>
<br/>
</td> </tr>

<!-- Product Summary -->
</table>
<table role="presentation" border="0" cellspacing="0" width="100%">
<tr> <td style="padding:0;">
<br/>
<h2>Recommended Products</h2>
<br/>
<div class="productsrow">
    {{#each products}}
    <div class="product">
        <img src="{{this.src}}" width="111px" height="43px"/>
        <div class="product__description">
        <h3>{{this.name}}</h3>
        <p>{{this.price}}</p>
        </div>
        <div class="product_buy"> <a href="{{this.link}}">Buy now</a></div>
    </div>
    <br/>
    {{/each}}
</div>
<br/>

<!-- Response Summary -->
<h2> Your Responses </h2>
<br/>
<div class="responses">
    {{#each responses}}
        <div class="response">
        <h3>{{this.question}}</h3>
        <p>{{this.answer}}</p>
        </div>
    <br/>
    {{/each}}
</div>
<p>If you have any questions, reply to this email or contact us at <a href="mailto:{{email}}">{{email}}</a></p>
</td> </tr>

</table>
</body>
</html>`;
