import { useEffect, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";

import { ErrorPage, LazyLoad } from "~/components";
import { StoreProvider } from "~/context";
import config from "~/config";

import { Router } from "./Router";
import { useSdk } from "~/hooks";
import { useQuery } from "react-query";

export const App = ({ location }: RouteComponentProps) => {
  const sdk = useSdk();

  const { data: store } = useQuery(["currentStore"], () =>
    sdk.currentStore().then((res) => res.currentStore)
  );

  useEffect(() => {
    const root = document.querySelector("#perfect-product-finder");

    if (root) {
      (root as any).style.opacity = 1;
    }
    const pageLoader = document.querySelector("#page-loader");

    if (pageLoader) {
      (pageLoader as any).style.opacity = 0;

      setTimeout(() => {
        (pageLoader as any).style.display = "none";
      }, 120);
    }
  }, []);

  return (
    <StoreProvider currentStore={store}>
      <Helmet {...config.seo} />

      <ErrorBoundary
        onReset={() => {
          window.location.reload();
        }}
        fallbackRender={({ resetErrorBoundary }) => (
          <ErrorPage
            onReset={() => {
              resetErrorBoundary();
            }}
          />
        )}
      >
        <Suspense fallback={<LazyLoad />}>
          <Router location={location} />
        </Suspense>
      </ErrorBoundary>
    </StoreProvider>
  );
};
