import { ReactNode } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ResponsiveValue, variant } from 'styled-system';

const variants = {
  light: {
    color: 'black40',

    '&:hover': { color: 'black60' },
    '&:active': { color: 'black90' },
  },
  dark: {
    color: 'black90',

    '&:hover': { color: 'black90' },
    '&:active': { color: 'black90' },
  },
};

interface LinkProps extends SystemProps {
  as?: any;
  children?: ReactNode;
  disabled?: boolean;
  to?: string;
  variant?: ResponsiveValue<keyof typeof variants>;
}

const StyledLink = styled(Box)<LinkProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  transition: color 0.18s ease-in-out;
  cursor: pointer;
  user-select: none;
  font-size: ${(p) => p.theme.space[2]}px;
  line-height: 19px;
  font-weight: bold;

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
    `}

  ${variant({ variants })}
`;

export const Link = (props: LinkProps) => (
  <StyledLink
    as={props.to ? RouterLink : undefined}
    variant="light"
    {...props}
  />
);
