import { createContext, ReactNode } from 'react';

interface ProviderProps {
  children: ReactNode;
  isRtl: boolean;
}

export const RtlContext = createContext<boolean | undefined>(undefined);

export const RtlProvider = ({ children, isRtl }: ProviderProps) => (
  <RtlContext.Provider value={isRtl}>{children}</RtlContext.Provider>
);
