import { useEffect, useRef } from "react";
import equal from "fast-deep-equal";
import { produce } from "immer";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { useFlow, useSdk } from "~/hooks";
import { useToast } from "~/lib";

interface OptionsFormProps {
  getDefaultValues(flowNode?: any): any;
  formatValues?(values: any): any;
  type: "flow" | "flowNode";
}

export const useOptionsForm = ({
  getDefaultValues,
  formatValues,
  type,
}: OptionsFormProps) => {
  const { data, flowNode, setStatus } = useFlow();

  const queryClient = useQueryClient();
  const sdk = useSdk();
  const toast = useToast();

  const currentFlow = data?.flows?.[0];

  const previousValues = useRef<any>(
    getDefaultValues(type === "flow" ? data : flowNode)
  );

  const formProps = useForm({
    defaultValues: getDefaultValues(type === "flow" ? currentFlow : flowNode),
  });
  const { reset, watch } = formProps;

  formProps.setValue;
  const values = watch();

  const flowMutation = useMutation(
    ({ id, values }: { id: string; values: any }) =>
      sdk.updateOneFlow({
        input: {
          id,
          update: formatValues ? formatValues(values) : values,
        },
      }),
    {
      onMutate: async ({ values }) => {
        const key = ["container", { id: data?.id }];

        await queryClient.cancelQueries(key);

        const previous = queryClient.getQueryData(key);

        queryClient.setQueryData(key, (old: any) =>
          produce(old, (draft) => {
            draft.flows[0] = { ...draft.flows[0], ...values };
          })
        );

        return { previous };
      },
      onSuccess: () => {
        setStatus("saved");
      },
      onError: (e: any, _, context: any) => {
        reset(getDefaultValues(context?.previous?.flows?.[0]));
        queryClient.setQueryData(
          ["container", { id: data?.id }],
          context?.previous
        );
        setStatus("error");
        toast({
          error: true,
          content: e?.messages?.[0] || e?.message || "Error saving settings.",
        });
      },
    }
  );

  const flowNodeMutation = useMutation(
    ({ id, values }: { id: string; values: any }) => {
      return sdk.updateOneFlowNode({
        input: {
          id,
          update: formatValues ? formatValues(values) : values,
        },
      });
    },
    {
      onMutate: async ({ id, values }) => {
        const key = ["container", { id: data?.id }];

        await queryClient.cancelQueries(key);

        const previous = queryClient.getQueryData(key);

        queryClient.setQueryData(key, (old: any) =>
          produce(old, (draft) => {
            const index = draft.flows?.[0]?.nodes?.findIndex(
              (node) => node?.id === id
            );

            if (index !== -1) {
              draft.flows[0].nodes[index] = {
                ...draft.flows[0].nodes[index],
                ...values,
              };
            }
          })
        );

        return { previous };
      },
      onSuccess: () => {
        setStatus("saved");
      },
      onError: (e: any, { id }, context: any) => {
        reset(
          getDefaultValues(
            context?.previous?.flows?.[0]?.findIndex((node) => node?.id === id)
          )
        );
        queryClient.setQueryData(
          ["container", { id: data?.id }],
          context?.previous
        );
        setStatus("error");
        toast({
          error: true,
          content: e?.messages?.[0] || e?.message || "Error saving settings.",
        });
      },
    }
  );

  useEffect(() => {
    if (!previousValues?.current || !equal(values, previousValues.current)) {
      setStatus("saving");

      if (type === "flow") {
        if (currentFlow?.id) {
          flowMutation.mutate({ id: currentFlow.id, values });
        }
      } else if (flowNode?.id) {
        flowNodeMutation.mutate({ id: flowNode.id, values });
      }
    }

    previousValues.current = values;
  }, [values]);

  return formProps;
};
