import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { rgba } from 'polished';
import styled from 'styled-components';

interface EmojiPickerProps {
  onSelect(emoji: any): void;
}

const Wrapper = styled.div`
  .emoji-mart-anchor-icon svg {
    margin: 0 auto;
  }

  .emoji-mart-search {
    margin-bottom: 4px;

    input {
      &:focus {
        box-shadow: 0px 0px 0px 2px ${(p) => rgba(p.theme.colors.primary, 0.4)};
      }
    }
  }

  .emoji-mart-category {
    user-select: none;
  }

  .emoji-mart-emoji span {
    cursor: pointer;
  }
`;

export const EmojiPicker = (props: EmojiPickerProps) => (
  <Wrapper>
    <Picker
      native
      autoFocus
      title="Select an emoji"
      emoji=""
      style={{ borderRadius: '8px', border: 'none' }}
      {...props}
    />
  </Wrapper>
);
