//import { useFlow } from '~/hooks';
import Integrations from "../Integrations";
import {
  Button,
  Heading,
  Layout,
  Link,
  Page,
  Stack,
  Card,
  Modal,
  TextField,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import Handlebars from "handlebars";
import validator from "validator";
import EmailPreview from "../Notifications/EmailPreview";
import ThemeSettings from "../Notifications/ThemeSettings";
import { htmlEmail } from "../Notifications/html";
import { useFlow, useOptionsForm } from "~/hooks";
import { getFileUrl, useBillingWrapper } from "~/lib";

const getDefaultValues = (values) => ({
  notificationsEnabled: values.notificationsEnabled,
  logoWidth: values.logoWidth,
  accentColor: values.accentColor,
  questionEmail: values.questionEmail,
  subject: values.subject,
  emailTemplate: values.emailTemplate,
});

export const NotificationsPage = () => {
  const { data } = useFlow();
  const billingWrapper = useBillingWrapper({ freeForExistingV1Users: true });

  const { control, setValue } = useOptionsForm({
    getDefaultValues,
    type: "flow",
  });

  const values = control.defaultValuesRef.current;
  const logo = data?.flows[0].notificationLogo;
  const flowId = data?.flows[0].id || "";

  // Notification Setting States
  const [ppfEmailsActive, setPpfEmailsActive] = useState(
    values.notificationsEnabled || false
  );
  const [file, setFile] = useState(
    (logo && getFileUrl(logo)) || "/logos/ppflogo.svg"
  );

  const [color, setColor] = useState(values.accentColor || "#21AF45");
  const [questionsEmail, setQuestionsEmail] = useState(
    values.questionsEmail || "example@domain.com"
  );
  const [subject, setSubject] = useState(
    values.subject || "Here are your quiz results"
  );
  const [logoWidth, setLogoWidth] = useState(
    control.defaultValuesRef.current.logoWidth?.toString() || "180"
  );
  const [emailTemplate, setEmailTemplate] = useState(
    values.emailTemplate || htmlEmail
  );

  // Email Modal
  const [isOpen, setIsOpen] = useState(false);
  const [testEmail, setTestEmail] = useState("");

  const save = async () => {
    setValue("logoWidth", parseInt(logoWidth));
    setValue("accentColor", color);
    setValue("questionEmail", questionsEmail);
    setValue("notificationsEnabled", ppfEmailsActive);
    setValue("subject", subject);
    setValue("emailTemplate", emailTemplate);
  };

  //Automatically save changes
  useEffect(() => {
    save();
  }, [
    logoWidth,
    color,
    file,
    questionsEmail,
    ppfEmailsActive,
    emailTemplate,
    subject,
  ]);
  const sendTestEmail = async () => {};

  // Render handlebars code
  let template;
  let render;
  try {
    template = Handlebars.compile(emailTemplate);
    render = template({
      accentColor: color,
      questionsEmail,
      email: questionsEmail,
      logo: file,
      logoWidth,
      products: [
        {
          id: 1,
          name: "Product Name",
          link: "https://google.ca",
          src:
            "https://cdn.shopify.com/s/files/1/0587/6622/9561/products/image7_1.png?v=1654032063",
          price: "$20.31",
        },
        {
          id: "2",
          name: "Product Name",
          link: "https://google.ca",
          src:
            "https://cdn.shopify.com/s/files/1/0587/6622/9561/products/image8_1.png?v=1654032093",
          price: "$20.31",
        },
        {
          id: "3",
          name: "Product Name",
          link: "https://google.ca",
          src:
            "https://cdn.shopify.com/s/files/1/0587/6622/9561/products/image8_1.png?v=1654032093",
          price: "$20.31",
        },
      ],
      responses: [
        {
          question: "Question 1",
          answer: " Answer 1",
        },
        {
          question: "Question 2",
          answer: " Answer 2",
        },
        {
          question: "Question 3",
          answer: " Answer 3",
        },
        {
          question: "Question 4",
          answer: " Answer 4",
        },
      ],
    });
  } catch (error) {
    render = error;
  }

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Integrations />
        </Layout.Section>
        <Layout.Section>
          <Card sectioned>
            <Stack alignment="center">
              <img src="/logos/ppflogo.svg" alt="PPF logo" />
              <Stack.Item fill>
                <Stack vertical spacing="tight">
                  <Stack alignment="center" spacing="tight">
                    <Heading>{"PPF Emails"}</Heading>
                  </Stack>
                  <p>
                    {
                      "Build personalized emails using our editor and connect with your quiz results. "
                    }
                    <Link url={"https://google.com"} external>
                      Learn more here
                    </Link>
                  </p>
                </Stack>
              </Stack.Item>
              {!ppfEmailsActive ? (
                <Button
                  loading={false}
                  primary={true}
                  onClick={() => billingWrapper(setPpfEmailsActive)(true)}
                >
                  {"Connect"}
                </Button>
              ) : (
                <Button onClick={() => setPpfEmailsActive(false)}>
                  {"Deactivate"}
                </Button>
              )}
            </Stack>
          </Card>
        </Layout.Section>
        {!!ppfEmailsActive && (
          <>
            <Layout.Section>
              <Modal
                instant
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title="Send a test email"
                primaryAction={{
                  content: "Send Email",
                  onAction: () => sendTestEmail(),
                }}
                secondaryActions={[
                  {
                    content: "Cancel",
                    onAction: () => setIsOpen(false),
                  },
                ]}
              >
                <Modal.Section>
                  <TextField
                    label=""
                    type="email"
                    value={testEmail}
                    autoComplete="email"
                    onChange={(value) => setTestEmail(value)}
                    error={validator.isEmail(testEmail) ? "" : "Invalid Email"}
                  />
                </Modal.Section>
              </Modal>
            </Layout.Section>
            <Layout.Section oneHalf>
              <ThemeSettings
                flowId={flowId}
                file={file}
                setFile={setFile}
                color={color}
                setColor={setColor}
                questionsEmail={questionsEmail}
                setQuestionsEmail={setQuestionsEmail}
                subject={subject}
                setSubject={setSubject}
                logoWidth={logoWidth}
                setLogoWidth={setLogoWidth}
                emailTemplate={emailTemplate}
                setEmailTemplate={setEmailTemplate}
                save={save}
              />
            </Layout.Section>
            <Layout.Section oneHalf>
              <EmailPreview
                render={render}
                save={save}
                subject={subject}
                sendTestEmail={() => {
                  setIsOpen(true);
                  save();
                  if (testEmail.length === 0) {
                    // Save changes
                    setTestEmail(questionsEmail);
                  }
                }}
              />
            </Layout.Section>
          </>
        )}
      </Layout>
    </Page>
  );
};
