import { LogicPage } from '~/components/pages';
import { FlowFragmentFragment } from '~/graphql/sdk';

import { PreviewPane } from '../PreviewPane';

interface TabPaneProps {
  flow: FlowFragmentFragment;
  isRtl?: boolean;
  tab: number;
}

export const MainPane = ({ tab, ...props }: TabPaneProps) => {
  switch (tab) {
    case 1:
      return <LogicPage />;
    default:
      return <PreviewPane {...props} />;
  }
};
