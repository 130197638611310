import { createContext } from 'react';

import { getSdk } from '~/lib';

import { useShopOrigin } from './useShopOrigin';

export const SdkContext = createContext<any | undefined>(undefined);

const updateOptions = (options, shop: string) => ({
  ...options,
  headers: {
    ...(options.headers || {}),
    'X-PPF-Admin-Shopify-Id': shop,
  },
});

export const cookieFetch = (shop: string) => (url, options) =>
  fetch(url, updateOptions(options, shop));

export const useSdk = () => {
  const shop = useShopOrigin();

  if (!shop) {
    throw Error('Store not set');
  }

  return getSdk(cookieFetch(shop));
};
