import { lazy, Suspense } from 'react';
import { Frame, Spinner, SkeletonBodyText } from '@shopify/polaris';
import { Box, Flex } from '@storyofams/react-ui';

import { LazyLoad, SideMenu } from '~/components';

const Component = lazy(() => import('~/containers/FlowBuilder'));

export default () => (
  <Suspense
    fallback={
      <LazyLoad>
        <Frame
          topBar={
            <Flex
              boxShadow="0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)"
              bg="white"
              height="56px"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              px={2}
            >
              <Box width="200px">
                <SkeletonBodyText lines={1} />
              </Box>
            </Flex>
          }
        >
          <Flex flexDirection="row" flex="1" height="100%" width="100%">
            <SideMenu borderRight="1px solid #EBEAEA" p="2">
              <SkeletonBodyText lines={3} />
            </SideMenu>

            <Flex
              alignItems="center"
              justifyContent="center"
              flex="1"
              width="100%"
              borderRadius="md"
              bg="white"
              boxShadow="0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)"
              my={2}
              mx={2}
              px={4}
              pb={4}
            >
              <Spinner accessibilityLabel="Loading preview" size="large" />
            </Flex>

            <SideMenu borderLeft="1px solid #EBEAEA" p="2">
              <SkeletonBodyText lines={3} />
            </SideMenu>
          </Flex>
        </Frame>
      </LazyLoad>
    }
  >
    <Component />
  </Suspense>
);
