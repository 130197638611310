import { useEffect, useMemo, useState } from "react";
import { Button, Icon, Stack, TextStyle, Tooltip } from "@shopify/polaris";
import {
  ArrowLeftMinor,
  TickMinor,
  AlertMinor,
  CircleAlertMajor,
  CircleTickMajor,
  ViewMajor,
} from "@shopify/polaris-icons";
import { Box, Flex, Text } from "@storyofams/react-ui";
import { AnimatePresence, motion } from "framer-motion";
import qs from "query-string";

import {
  useShopOrigin,
  useFlow,
  useOffline,
  useSdk,
  IPublishErrors,
} from "~/hooks";
import { useNavParams } from "~/hooks";
import { useToast } from "~/lib";

import { NavButton } from "../sidePanes/EditorPane/NavButton";
import { Link } from "./Link";
import { Nav } from "./Nav";
import { Tabs } from "./Tabs";
import { ContainerQuery } from "~/graphql/sdk";

const MotionFlex = motion(Flex);

let offlineTimer: any = null;

//TODO: move publish model to the end screen
const TABS = [
  "Quiz Builder",
  "Results",
  "Settings",
  "Notifications",
  "Publish",
];

export const TopBar = ({
  container,
  publishErrorInfo,
}: {
  container: ContainerQuery["flowContainer"] | null;
  publishErrorInfo: IPublishErrors;
}) => {
  const shop = useShopOrigin();
  const { status } = useFlow();
  const isOffline = useOffline();
  const sdk = useSdk();
  const toast = useToast();
  const [isOpeningPreview, setOpeningPreview] = useState(false);
  const [showOffline, setShowOffline] = useState(false);

  const [{ page: selectedTab }, setNavParams] = useNavParams();
  const nextTab = useMemo(() => selectedTab + 1, [selectedTab]);
  const nextButtonDisable = useMemo(() => {
    if (nextTab >= TABS.length) {
      return (
        ["error", "saving"].includes(status) ||
        !!publishErrorInfo.publishError ||
        publishErrorInfo.isLoading
      );
    }
    return false;
  }, [nextTab, publishErrorInfo]);

  useEffect(() => {
    if (isOffline && offlineTimer) {
      clearTimeout(offlineTimer);
      setShowOffline(true);
    } else if (!isOffline) {
      offlineTimer = setTimeout(() => {
        setShowOffline(false);
      }, 2);
    }
  }, [isOffline]);

  const statusMessage = useMemo(() => {
    switch (status) {
      case "error":
        return (
          <Stack spacing="extraTight">
            <>Error saving changes</>
            <Icon source={AlertMinor} color="critical" />
          </Stack>
        );
      case "saving":
        return "Saving...";
      case "saved":
        return (
          <Stack spacing="extraTight">
            <>Saved</>
            <Icon source={TickMinor} color="success" />
          </Stack>
        );
      default:
        return "";
    }
  }, [status]);

  const openPreview = async () => {
    if (isOpeningPreview) {
      return;
    }

    setOpeningPreview(true);

    try {
      const token = await sdk.accessToken().then((res) => res.accessToken);

      Object.assign(document.createElement("a"), {
        target: "_blank",
        href: `https://${shop}/tools/${
          process.env.REACT_APP_SHOPIFY_APP_NAME
        }/${container?.slug}?${qs.stringify({
          flowId: container?.flows?.[0]?.id,
          token,
        })}`,
      }).click();
    } catch (e) {
      toast({ content: "Failed to open preview", error: true });
    }

    setOpeningPreview(false);
  };

  return (
    <Box position="relative">
      <Nav>
        <Box flex="1">
          <Link
            href={`https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/`}
          >
            <Button icon={ArrowLeftMinor} />
            <TextStyle variation="subdued">Back to home page</TextStyle>
          </Link>
        </Box>

        <Tabs
          TABS={TABS}
          selectedTab={selectedTab}
          setNavParams={setNavParams}
        />

        <Flex
          flexDirection="column"
          flexWrap="nowrap"
          alignItems="flex-end"
          flex="1"
        >
          <Stack alignment="center" spacing="tight">
            <Text fontSize={1.5} color="subdued">
              {statusMessage}
            </Text>
            <NavButton
              icon={ViewMajor}
              onClick={openPreview}
              loading={isOpeningPreview}
              text="Preview"
            ></NavButton>
            <Tooltip
              content={
                nextTab >= TABS.length &&
                !nextButtonDisable &&
                "Back to quiz list page"
              }
            >
              <Button
                onClick={() =>
                  nextTab < TABS.length &&
                  setNavParams({ page: `${nextTab}`, main: "0" })
                }
                url={
                  nextTab >= TABS.length
                    ? `https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/flows`
                    : void 1
                }
                primary
                disabled={nextButtonDisable}
              >
                {nextTab >= TABS.length
                  ? "Finish 🎉"
                  : "Next: " + TABS[nextTab]}
              </Button>
            </Tooltip>
          </Stack>
        </Flex>
      </Nav>

      <AnimatePresence>
        {showOffline && (
          <MotionFlex
            position="fixed"
            top={0}
            left={0}
            right={0}
            height="40px"
            justifyContent="center"
            alignItems="center"
            bg={isOffline ? "rgba(254, 211, 209, 1)" : "rgba(174, 233, 209, 1)"}
            initial={{ y: "-100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "-100%" }}
            transition={{ ease: "easeOut", delay: isOffline ? 0 : 2 }}
          >
            <Stack alignment="center" spacing="tight">
              <Text>
                You are {isOffline ? "currently offline" : "back online"}.
              </Text>
              <Icon
                source={isOffline ? CircleAlertMajor : CircleTickMajor}
                color={isOffline ? "critical" : "success"}
              />
            </Stack>
          </MotionFlex>
        )}
      </AnimatePresence>
    </Box>
  );
};
