import { Box, Flex } from '@storyofams/react-ui';

import { FlowNodeLayout } from '~/graphql/sdk';

import { CoverLeft } from './CoverLeft';
import { CoverRight } from './CoverRight';
import { MediaLeft } from './MediaLeft';
import { MediaRight } from './MediaRight';
import { MediaTop } from './MediaTop';
import { Simple } from './Simple';

const LAYOUTS = [
  {
    Component: Simple,
    label: 'Simple',
    value: FlowNodeLayout.Simple,
  },
  {
    Component: MediaTop,
    label: 'Media top',
    value: FlowNodeLayout.MediaFloatTop,
  },
  {
    Component: MediaRight,
    label: 'Media right',
    value: FlowNodeLayout.MediaFloatRight,
  },
  {
    Component: MediaLeft,
    label: 'Media left',
    value: FlowNodeLayout.MediaFloatLeft,
  },
  {
    Component: CoverRight,
    label: 'Cover right',
    value: FlowNodeLayout.MediaCoverRight,
  },
  {
    Component: CoverLeft,
    label: 'Cover left',
    value: FlowNodeLayout.MediaCoverLeft,
  },
];

interface LayoutInputProps {
  onChange(value: FlowNodeLayout): void;
  value: FlowNodeLayout;
}

export const LayoutInput = ({
  value: currentValue,
  onChange,
}: LayoutInputProps) => {
  return (
    <Box>
      <label>Layout</label>

      <Flex flexWrap="wrap" flexDirection="row" mt={1.5} mx="-6px">
        {LAYOUTS.map(({ Component, label, value }) => (
          <Box
            key={value}
            onClick={() => {
              onChange(value);
            }}
            aria-label={label}
            width="calc(50% - 12px)"
            mx={0.75}
            mb={1.5}
            borderRadius="4px"
            overflow="hidden"
            cursor="pointer"
            transition="color 0.2s ease-out"
            color={currentValue === value ? '#111' : '#E1E3E5'}
            css={{
              '&:hover, &:focus': {
                color: currentValue === value ? '#333' : '#cdcfd1',
              },
            }}
          >
            <Component />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
