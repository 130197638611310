import { useEffect, useRef, useState } from 'react';
import { DropZone, Stack, Icon, Button, Spinner } from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';
import { Flex, Box, css } from '@storyofams/react-ui';
import produce from 'immer';
import Imgix from 'react-imgix';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';

import { FileUnion, UrlFile } from '~/graphql/sdk';
import { useFlow, useSdk } from '~/hooks';
import { getFileUrl, useToast } from '~/lib';

import { MediaModal } from '../../MediaInput/MediaModal';

const ImgWrapper = styled.div<{ type?: 'not-transparent' | 'white-bg' }>`
  width: 100%;
  height: ${(p) => (p.type === 'white-bg' ? '210px' : '180px')};
  margin: ${(p) =>
    p.type === 'white-bg'
      ? `${p.theme.space[3]}px ${p.theme.space[3]}px 0`
      : 0};

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

interface ImageUploadProps {
  index: number;
  src?: FileUnion;
  optionId: string;
  flowNodeId: string;
}

export const ImageUpload = ({
  src,
  index,
  flowNodeId,
  optionId,
}: ImageUploadProps) => {
  const { data } = useFlow();
  const queryClient = useQueryClient();
  const sdk = useSdk();
  const toast = useToast();

  const [isBusy, setBusy] = useState(false);
  const [isSelectModalOpen, setSelectModalOpen] = useState(false);
  const [file, setFile] = useState<any>();
  const uploadRef = useRef<any>();

  const executeUpload = async (file, currentOptionId?: string) => {
    try {
      await sdk.uploadFileForOption({
        input: {
          image: file,
          nodeId: flowNodeId,
          optionId: currentOptionId || optionId,
        },
      });

      setFile(window.URL.createObjectURL(file));

      if (isSelectModalOpen) {
        setSelectModalOpen(false);
      }
    } catch (e) {
      toast({ content: 'Error uploading image', error: true });
    }

    setBusy(false);
  };

  const uploadFile = async (file, isUrl?: boolean) => {
    if (isBusy) {
      return;
    }

    setBusy(true);

    if (optionId !== 'new') {
      if (isUrl) {
        flowNodeOptionMutation.mutate({
          currentOptionId: optionId,
          image: file,
        });
      } else {
        await executeUpload(file);
      }
    } else if (isUrl) {
      uploadRef.current = (currentOptionId) => {
        flowNodeOptionMutation.mutate({
          currentOptionId,
          image: file,
        });
      };
    } else {
      uploadRef.current = (currentOptionId) => {
        executeUpload(file, currentOptionId);
      };
    }
  };

  const handleDrop = (_droppedFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.[0]) {
      toast({
        content: `"${rejectedFiles?.[0].name}" is not supported. File type must be .jpg or .png.`,
        error: true,
      });
    } else if (acceptedFiles?.[0]) {
      uploadFile(acceptedFiles[0]);
    }
  };

  useEffect(() => {
    if (uploadRef.current && optionId !== 'new') {
      uploadRef.current(optionId);
      uploadRef.current = null;
    }
  }, [optionId]);

  useEffect(
    () => () => {
      if (file) {
        window.URL.revokeObjectURL(file);
      }
    },
    [],
  );

  const flowNodeOptionMutation = useMutation(
    ({ currentOptionId, image }: { currentOptionId: string; image: UrlFile }) =>
      sdk.updateUrlFileForOption({
        input: {
          image,
          nodeId: flowNodeId,
          optionId: currentOptionId,
        },
      }),
    {
      onMutate: async ({ currentOptionId, image }) => {
        const key = ['container', { id: data?.id }];

        await queryClient.cancelQueries(key);

        const previous = queryClient.getQueryData(key);

        queryClient.setQueryData(key, (old: any) =>
          produce(old, (draft) => {
            const nodeIdx = draft.flows?.[0]?.nodes?.findIndex(
              (node) => node?.id === flowNodeId,
            );

            if (nodeIdx !== -1) {
              const optionIdx = draft.flows[0].nodes[
                nodeIdx
              ].options?.findIndex((option) => option?.id === currentOptionId);

              if (optionIdx !== -1) {
                draft.flows[0].nodes[nodeIdx].options[optionIdx].image = {
                  ...image,
                  __typename: 'URLFile',
                };
              }
            }
          }),
        );

        setBusy(false);

        return { previous };
      },
      onError: (e: any, _, context: any) => {
        queryClient.setQueryData(
          ['container', { id: data?.id }],
          context?.previous,
        );
        toast({
          error: true,
          content: e?.messages?.[0] || e?.message || 'Error saving image',
        });
      },
    },
  );

  const fileUpload = !file && !src && (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      css={{
        '.Polaris-Icon': {
          width: '32px',
          height: '32px',
        },
      }}
    >
      <Stack vertical spacing="tight">
        <Icon source={ImageMajor} color="subdued" />
        <Button plain>Add image</Button>
      </Stack>
    </Flex>
  );

  const imgSrc = file || getFileUrl(src);

  const uploadedFiles = (
    <Box
      position="relative"
      css={{
        '&:hover, &:focus': {
          '.change-button': {
            opacity: 1,
          },
        },
      }}
    >
      <ImgWrapper>
        {!!imgSrc && (
          <>
            {file ||
            (process.env.NODE_ENV === 'development' &&
              src?.__typename !== 'URLFile') ? (
              <img src={imgSrc} alt={`Answer ${index + 1}`} />
            ) : (
              <Imgix
                className="lazyload"
                src={imgSrc}
                sizes="735px"
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
                htmlAttributes={{
                  alt: `Answer ${index + 1}`,
                }}
              />
            )}
          </>
        )}
      </ImgWrapper>

      <Flex
        position="absolute"
        alignItems="center"
        justifyContent="center"
        top={0}
        right={0}
        left={0}
        bottom={0}
        opacity={0}
        className="change-button"
        transition="opacity 0.2s ease-out"
        bg="black40"
      >
        <Button>Change image</Button>
      </Flex>
    </Box>
  );

  return (
    <Box
      width="100%"
      height="100%"
      borderTopLeftRadius="8px"
      borderTopRightRadius="8px"
      overflow="hidden"
      css={css({
        img: { ml: 'auto', mr: 'auto' },
        '> div': {
          height: '100%',
        },
        '.Polaris-DropZone': {
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
          bg: 'black4',
          minHeight: '100%',
          height: '100%',
        },
        '.Polaris-DropZone--hasOutline': {
          padding: 0,
          '&::after': {
            display: 'none',
          },
        },
      })}
    >
      <DropZone
        accept="image/*"
        type="image"
        onDrop={handleDrop}
        onClick={() => {
          setSelectModalOpen(true);
        }}
        allowMultiple={false}
        variableHeight
      >
        {(!!src || !!file) && uploadedFiles}
        {fileUpload}
      </DropZone>

      {isBusy && (
        <Flex
          position="absolute"
          alignItems="center"
          justifyContent="center"
          top={0}
          right={0}
          left={0}
          bottom={0}
          bg="white60"
        >
          <Spinner />
        </Flex>
      )}

      <MediaModal
        isOpen={isSelectModalOpen}
        setOpen={setSelectModalOpen}
        onDrop={handleDrop}
        onSelectImage={(image) => {
          uploadFile(image, true);
          setSelectModalOpen(false);
        }}
        types={['image']}
        isBusy={isBusy}
      />
    </Box>
  );
};
