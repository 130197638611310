export const loadMessages = async (locale: string) => {
  let messagePromise;

  switch (locale) {
    case 'nl':
      messagePromise = import('~/translations/nl.json');
      break;
    default:
      messagePromise = import('~/translations/en.json');
      break;
  }

  const messages = await messagePromise;

  return messages.default;
};
