import React from "react";
import { Modal } from "@shopify/polaris";
import {
  EmailNewsletterMajor,
  ExchangeMajor,
  PaintBrushMajor,
  CirclePlusMajor,
  CircleTickMajor,
  SmileyJoyMajor,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import DescriptionCard from "~/components/BillingAlertModal/DescriptionCard";
import { useShopOrigin } from "~/hooks";

const ModalWrapper = styled.div`
  [class~="Polaris-Modal-Section"] {
    padding-bottom: 0;
  }
`;

const CardLine = styled.div`
  margin-bottom: 1.8rem;
  display: flex;

  [class~="Polaris-Card"] + [class~="Polaris-Card"] {
    margin-top: 0;
    margin-left: 1.8rem;
  }

  & > * {
    flex-basis: 50%;
  }
`;

const PurpleCardLine = styled(CardLine)`
  & * {
    fill: #6138fe;
  }
`;

const GreenCardLine = styled(CardLine)`
  & * {
    fill: #15b158;
  }
`;

interface BillingModalProps {
  open: boolean;
  setClose: () => void;
  isInitial: boolean;
  setIsInitial: React.Dispatch<boolean>;
}

const BillingModal: React.FC<BillingModalProps> = ({
  open,
  setClose,
  isInitial,
  setIsInitial,
}) => {
  const shop = useShopOrigin();
  const history = useHistory();
  const location = useLocation();

  const onClose = () => {
    if (isInitial) {
      const homeUrl =
        location.pathname + location.search.replace("main=1", "main=0");

      setIsInitial(false);
      history.push(homeUrl);
    }
    setClose();
  };

  const closeOnAction = () => {
    isInitial && setIsInitial(false);
    setClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"Unlock Email, CSS, Logic Jumps, and More! [Starting at $19]"}
      primaryAction={{
        content: "Start your 14 days free trial",
        url: `https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/billing`,
        onAction: closeOnAction,
      }}
      secondaryActions={[
        {
          content: "Learn more",
          url: "https://productfinder.app/pricing",
          onAction: closeOnAction,
        },
      ]}
    >
      <ModalWrapper>
        <Modal.Section>
          <PurpleCardLine>
            <DescriptionCard
              icon={EmailNewsletterMajor}
              title={"Email Results"}
              content={"Segment customers and send results to them via email."}
            />
            <DescriptionCard
              icon={PaintBrushMajor}
              title={"CSS Editing"}
              content={
                "Out team can help you customize your quiz flow to better fit your site."
              }
            />
          </PurpleCardLine>
          <PurpleCardLine>
            <DescriptionCard
              icon={ExchangeMajor}
              title={"Logic Jumps"}
              content={
                "Use conditional logic to create more complex quiz flows."
              }
            />
            <DescriptionCard
              icon={CirclePlusMajor}
              title={"And Much More..!"}
              content={
                "Im-page embed types, integrate reviews, multi-choice questions, and more."
              }
            />
          </PurpleCardLine>
          <GreenCardLine>
            <DescriptionCard
              icon={CircleTickMajor}
              title={"500% ROI Positive Guarantee"}
            />
            <DescriptionCard
              icon={SmileyJoyMajor}
              title={"1000+ Happy Customers"}
            />
          </GreenCardLine>
          <img
            src={"/images/ppf-app-features.png"}
            alt={"PPF app"}
            style={{
              display: "block",
              width: "100%",
            }}
          />
        </Modal.Section>
      </ModalWrapper>
    </Modal>
  );
};

export default BillingModal;
