import styled from 'styled-components';

export const Credit = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0) 100%
  );
`;
