import { useMemo } from 'react';
import { List, Stack, TextStyle } from '@shopify/polaris';
import {
  FormsMajor,
  HomeMajor,
  ImportStoreMajor,
} from '@shopify/polaris-icons';
import { Flex } from '@storyofams/react-ui';
import dagre from 'dagre';
import { uniqWith } from 'lodash';
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  isNode,
  Elements,
  FlowElement,
} from 'react-flow-renderer';

import {
  FlowFragmentFragment,
  FlowNodeOptionNextAction,
  FlowNodeType,
} from '~/graphql/sdk';

import { Edge } from './Edge';
import { NodeLabel } from './NodeLabel';
import { StyledFlow } from './StyledFlow';

const nodeWidth = 150;
const nodeHeight = 54;

const getPositionedElements = (dagreGraph: any, elements: Elements) => {
  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target, { minlen: 2 });
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      el.position = { x: 0, y: 0 };

      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = 'left' as any;
      el.sourcePosition = 'right' as any;

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};

interface LogicPreviewProps {
  flow: FlowFragmentFragment;
  setModalActive(active: string | boolean): void;
}

export const LogicPreview = ({ flow, setModalActive }: LogicPreviewProps) => {
  const elements = useMemo(() => {
    const hasWelcome = flow?.nodes?.[0]?.type === FlowNodeType.Welcome;
    const hasEmail = !!flow?.nodes?.find(
      ({ type }) => type === FlowNodeType.Email,
    );
    const unpositionedElements: any[] = [];

    flow.nodes.forEach((node, idx) => {
      unpositionedElements.push({
        id: node.type === FlowNodeType.Email ? 'email' : node.id,
        data: {
          label:
            node.type === FlowNodeType.Welcome ? (
              <NodeLabel icon={HomeMajor} text="Welcome screen" />
            ) : node.type === FlowNodeType.Email ? (
              <NodeLabel icon={FormsMajor} text="Email capture" />
            ) : (
              `${idx + (hasWelcome ? 0 : 1)}. ${node.title || 'Untitled'}`
            ),
        },
        type: idx === 0 ? 'input' : 'default',
      });

      if (node.type === FlowNodeType.Welcome && flow.nodes?.[idx + 1]) {
        unpositionedElements.push({
          id: `edge-welcome`,
          type: 'edge',
          arrowHeadType: 'arrow',
          source: node.id,
          target:
            flow.nodes[idx + 1].type === FlowNodeType.Email
              ? 'email'
              : flow.nodes[idx + 1].id,
          data: {
            isDefault: true,
          },
        });
      }

      if (node.type === FlowNodeType.Email) {
        unpositionedElements.push({
          id: `edge-email-results`,
          type: 'edge',
          arrowHeadType: 'arrow',
          source: 'email',
          target: 'results',
          data: {
            isDefault: true,
          },
        });
      }

      const uniqueEdges = uniqWith(
        node.options,
        (a, b) => a.nextAction === b.nextAction && a.nextNode === b.nextNode,
      );

      if (
        !node.isRequired &&
        !uniqueEdges.find(
          (edge) => edge.nextAction === FlowNodeOptionNextAction.Auto,
        )
      ) {
        uniqueEdges.push({ nextAction: FlowNodeOptionNextAction.Auto } as any);
      }

      uniqueEdges.forEach(({ nextAction, nextNode }) => {
        const optionsForEdge =
          node.options?.filter(
            (option) =>
              option.nextAction === nextAction && option.nextNode === nextNode,
          ) || [];

        unpositionedElements.push({
          id: `edge-${node.id}-${nextAction}-${nextNode}`,
          type: 'edge',
          arrowHeadType: 'arrow',
          source: node.id,
          target:
            nextAction === FlowNodeOptionNextAction.Auto ||
            (nextAction === FlowNodeOptionNextAction.SpecificNode && !nextNode)
              ? idx >= flow.nodes.length - 1
                ? hasEmail
                  ? 'email'
                  : 'results'
                : flow.nodes?.[idx + 1]?.type === FlowNodeType.Email
                ? 'email'
                : flow.nodes[idx + 1].id
              : nextAction === FlowNodeOptionNextAction.EndSession
              ? hasEmail
                ? 'email'
                : 'results'
              : nextNode,
          data: {
            isDefault: nextAction === FlowNodeOptionNextAction.Auto,
            popoverText:
              nextAction !== FlowNodeOptionNextAction.Auto ? (
                <Stack vertical spacing="tight">
                  <Stack.Item>If answer equals</Stack.Item>
                  <List type="bullet">
                    {optionsForEdge.map((option) => (
                      <List.Item key={option.id}>
                        <TextStyle variation="strong">
                          {node.options?.findIndex(
                            ({ id }) => id === option.id,
                          ) || 0 + 1}
                          . {option?.label || 'Untitled'}
                        </TextStyle>
                      </List.Item>
                    ))}
                  </List>
                  <Stack.Item>Jump to</Stack.Item>
                  <List type="bullet">
                    <List.Item>
                      <TextStyle variation="strong">
                        {nextAction === FlowNodeOptionNextAction.EndSession
                          ? 'Results'
                          : `${
                              flow.nodes.findIndex(
                                ({ id }) => id === nextNode,
                              ) + (hasWelcome ? 0 : 1)
                            }. ${
                              flow.nodes.find(({ id }) => id === nextNode)
                                ?.title
                            }`}
                      </TextStyle>
                    </List.Item>
                  </List>
                </Stack>
              ) : null,
            toggleActive: () => {
              setModalActive(node.id);
            },
          },
        });
      });
    });

    unpositionedElements.push({
      id: 'results',
      data: {
        label: <NodeLabel icon={ImportStoreMajor} text="Results" />,
      },
      type: 'output',
    });

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({
      rankdir: 'LR',
      nodesep: 100,
      edgesep: 50,
    });

    return getPositionedElements(dagreGraph, unpositionedElements);
  }, [flow]);

  const onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView();
  };

  const onElementClick = (_, element: FlowElement) => {
    if (element.type !== 'edge' && !['email', 'results'].includes(element.id)) {
      setModalActive(element.id);
    }
  };

  const edgeTypes = {
    edge: Edge,
  };

  return (
    <Flex
      flexDirection="column"
      flex="1"
      borderBottomLeftRadius="md"
      borderBottomRightRadius="md"
      boxShadow="0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)"
      mb={2}
    >
      <StyledFlow>
        <ReactFlowProvider>
          <ReactFlow
            elements={elements}
            nodesConnectable={false}
            elementsSelectable={false}
            onElementClick={onElementClick}
            onLoad={onLoad}
            edgeTypes={edgeTypes}
            maxZoom={1.5}
          >
            <Controls showInteractive={false} />
          </ReactFlow>
        </ReactFlowProvider>
      </StyledFlow>
    </Flex>
  );
};
