import { useEffect } from 'react';
import NProgressLib from 'nprogress';

import 'nprogress/nprogress.css';

import './styles.scss';

NProgressLib.configure({ showSpinner: false });

export const NProgress = () => {
  useEffect(() => {
    NProgressLib.start();

    return () => {
      NProgressLib.done();
    };
  });

  return null;
};
