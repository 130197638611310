import { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  Stack,
  Button,
  ButtonGroup,
  Heading,
  Select,
  Icon,
  Scrollable,
} from '@shopify/polaris';
import { AffiliateMajor } from '@shopify/polaris-icons';
import { Box, Flex } from '@storyofams/react-ui';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import { useParams } from 'react-router-dom';
import {
  FlowFragmentFragment,
  FlowNodeOptionNextAction,
  FlowNodeType,
} from '~/graphql/sdk';
import { useSdk } from '~/hooks';
import { useToast } from '~/lib';

interface LogicModalProps {
  active: string | boolean;
  toggleActive(): void;
  flow: FlowFragmentFragment;
}

const getDefaultValues = (questionNodes: FlowFragmentFragment['nodes']) => ({
  options:
    questionNodes
      ?.map(({ options }) =>
        options?.map((option) => ({
          id: option.id,
          value:
            ([
              FlowNodeOptionNextAction.Auto,
              FlowNodeOptionNextAction.EndSession,
            ].includes(option.nextAction)
              ? option.nextAction
              : option.nextNode) || FlowNodeOptionNextAction.Auto,
        })),
      )
      ?.flat() || [],
});

export const LogicModal = ({ active, flow, toggleActive }: LogicModalProps) => {
  const { id: containerId } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const toast = useToast();
  const sdk = useSdk();

  const [isBusy, setBusy] = useState(false);

  const questionNodes = useMemo(
    () =>
      flow.nodes?.filter(
        ({ type }) =>
          ![FlowNodeType.Email, FlowNodeType.Welcome].includes(type),
      ),
    [flow],
  );

  const { control, handleSubmit, register, reset } = useForm({
    defaultValues: getDefaultValues(questionNodes),
  });
  const { fields } = useFieldArray({
    control,
    name: 'options',
    keyName: 'fieldKey',
  });

  useEffect(() => {
    reset(getDefaultValues(questionNodes));
  }, [questionNodes]);

  const onSave = async (values) => {
    if (isBusy) {
      return;
    }

    setBusy(true);

    try {
      await sdk.updateFlowLogic({
        input: {
          flowId: flow.id,
          logic: values.options?.map(({ id, value }) => ({
            id,
            ...([
              FlowNodeOptionNextAction.Auto,
              FlowNodeOptionNextAction.EndSession,
            ].includes(value)
              ? { nextAction: value }
              : {
                  nextAction: FlowNodeOptionNextAction.SpecificNode,
                  nextNode: value,
                }),
          })),
        },
      });

      queryClient.invalidateQueries(['container', { id: containerId }]);

      toast({
        content: 'Logic jumps saved',
      });
      toggleActive();
    } catch (e) {
      toast({
        error: true,
        content: e?.messages?.[0] || e?.message || `Error saving logic jumps`,
      });
    }

    setBusy(false);
  };

  const removeAllLogic = async () => {
    if (isBusy) {
      return;
    }

    setBusy(true);

    try {
      await sdk.updateFlowLogic({
        input: {
          flowId: flow.id,
          logic: fields.map(({ id }) => ({
            id,
            nextAction: FlowNodeOptionNextAction.Auto,
          })),
        },
      });

      queryClient.invalidateQueries(['container', { id: containerId }]);

      toast({
        content: 'Logic jumps removed',
      });
    } catch (e) {
      toast({
        error: true,
        content: e?.messages?.[0] || e?.message || `Error removing logic jumps`,
      });
    }

    setBusy(false);
  };

  return (
    <Modal
      large
      open={!!active}
      onClose={toggleActive}
      title="Logic jumps"
      footer={
        <Stack distribution="equalSpacing">
          <Button onClick={removeAllLogic} destructive loading={isBusy}>
            Remove all logic jumps
          </Button>

          <ButtonGroup>
            <Button onClick={toggleActive}>Cancel</Button>
            <Button onClick={handleSubmit(onSave)} primary loading={isBusy}>
              Save
            </Button>
          </ButtonGroup>
        </Stack>
      }
      limitHeight
    >
      <Modal.Section>
        <Stack vertical>
          {questionNodes?.map((node, idx) => (
            <Stack.Item key={node.id}>
              {node.id === active && <Scrollable.ScrollTo />}

              <Box border="1px solid #ccc" borderRadius="4px" p={2}>
                <Stack vertical>
                  <Heading>
                    {idx + 1}. {node.title || 'Untitled'}
                  </Heading>

                  {!!node.options?.length ? (
                    <Stack vertical spacing="tight">
                      {node.options.map((option, optionIdx) => {
                        const fieldIdx = fields.findIndex(
                          (field) => field.id === option.id,
                        );
                        const field = fields?.[fieldIdx];

                        if (!field) {
                          return null;
                        }

                        const isLastQuestion =
                          idx === questionNodes.length - 1 ||
                          (idx === questionNodes.length - 2 &&
                            questionNodes?.[idx + 1]?.type ===
                              FlowNodeType.Email);

                        return (
                          <Flex key={field.fieldKey} flexDirection="row">
                            <Box
                              width="calc(50% - 4px)"
                              mr={0.5}
                              border="1px solid #E1E3E5"
                              borderRadius="4px"
                              p={1.5}
                              whiteSpace="nowrap"
                              overflow="hidden"
                              css={{
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {option.label || 'Untitled'}
                            </Box>
                            <Box
                              width="calc(50% - 4px)"
                              ml={0.5}
                              height="100%"
                              css={{
                                '.Polaris-Labelled--hidden, .Polaris-Select, .Polaris-Select__Content': {
                                  height: '46px',
                                },
                              }}
                            >
                              <input
                                {...register(`options.${fieldIdx}.id` as const)}
                                defaultValue={field.id}
                                type="hidden"
                              />

                              <Controller
                                control={control}
                                name={`options.${fieldIdx}.value` as const}
                                defaultValue={field.value as any}
                                render={({ field: { ref, ...fieldProps } }) => (
                                  <Select
                                    label={`Action when selecting answer ${
                                      optionIdx + 1
                                    }`}
                                    labelHidden
                                    options={[
                                      {
                                        label: `Go to ${
                                          isLastQuestion
                                            ? 'results'
                                            : 'next question'
                                        }`,
                                        value: FlowNodeOptionNextAction.Auto,
                                      },
                                      ...questionNodes
                                        ?.filter(({ id }) => id !== node.id)
                                        ?.map((otherNode) => ({
                                          prefix: (
                                            <Icon
                                              source={AffiliateMajor}
                                              color="base"
                                            />
                                          ),
                                          label: `Jump to ${
                                            questionNodes?.findIndex(
                                              ({ id }) => id === otherNode.id,
                                            ) + 1
                                          }. ${otherNode.title || 'Untitled'}`,
                                          value: otherNode.id,
                                        })),
                                      ...(!isLastQuestion
                                        ? [
                                            {
                                              prefix: (
                                                <Icon
                                                  source={AffiliateMajor}
                                                  color="base"
                                                />
                                              ),
                                              label: `Jump to results`,
                                              value:
                                                FlowNodeOptionNextAction.EndSession,
                                            },
                                          ]
                                        : []),
                                    ]}
                                    {...fieldProps}
                                  />
                                )}
                              />
                            </Box>
                          </Flex>
                        );
                      })}
                    </Stack>
                  ) : (
                    <p>
                      Add answers to this question before configuring logic
                      jumps
                    </p>
                  )}
                </Stack>
              </Box>
            </Stack.Item>
          ))}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
