import { Icon } from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';
import {
  Box,
  Flex,
  Icon as IconUI,
  SystemProps,
  Text,
} from '@storyofams/react-ui';
import { range } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useIsRtl } from '~/hooks';
import { Button } from '../Button';
import { ReactComponent as star } from '../Icon/library/star.svg';
import { messages } from './messages';

interface ProductCardProps {
  enableAddToCart?: boolean;
  enableReviews?: boolean;
  image?: string;
  link?: string;
  price?: string;
  primaryColor?: string | null;
  title: string;
  /** @default white-bg */
  type?: 'not-transparent' | 'white-bg';
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  border: 1px solid;
  border-color: ${(p) => p.theme.colors.black10};
  border-radius: ${(p) => p.theme.radii.md};
  transition: background-color 0.18s ease-in-out, border 0.18s ease-in-out,
    box-shadow 0.18s ease-in-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: ${(p) => p.theme.colors.black60};
  }
`;

const Img = styled(Flex)<Pick<ProductCardProps, 'type'>>`
  background-color: ${(p) => p.theme.colors.border};
  width: calc(100% - 32px);
  height: ${(p) => (p.type !== 'not-transparent' ? '184px' : '200px')};
  margin: ${(p) =>
    p.type !== 'not-transparent'
      ? `${p.theme.space[2]}px ${p.theme.space[2]}px 0 ${p.theme.space[2]}px`
      : 0};

  > .Polaris-Icon {
    width: 32px;
    height: 32px;
  }
`;

export const ProductCard = ({
  enableAddToCart,
  enableReviews,
  price,
  primaryColor,
  title,
  ...props
}: ProductCardProps & SystemProps) => {
  const isRtl = useIsRtl();

  return (
    <Wrapper {...props}>
      <Box position="relative" width="100%">
        <Img justifyContent="center" alignItems="center">
          <Icon source={ImageMajor} color="subdued" />
        </Img>
      </Box>

      <Box p={3} pt={enableReviews ? 2.5 : 3} textAlign="start" width="100%">
        {enableReviews && (
          <Flex mb={1.5} alignItems="center">
            {range(5).map((idx) => (
              <IconUI key={idx} icon={star} mr={0.5} />
            ))}

            <Text fontSize={1.5} lineHeight="15px" color="black60" ml={0.5}>
              23 reviews
            </Text>
          </Flex>
        )}

        <Text
          fontSize={3}
          fontWeight="bold"
          lineHeight="29px"
          color="black80"
          letterSpacing={isRtl ? undefined : '-0.01em'}
        >
          {title}
        </Text>
        {!!price && (
          <Text mt={0.75} fontSize={2} lineHeight="140%" color="black60">
            {price}
          </Text>
        )}

        <Button
          mt={4}
          width="100%"
          borderRadius="xs"
          disabled
          primaryColor={primaryColor || undefined}
        >
          <FormattedMessage
            {...messages[enableAddToCart ? 'addToCart' : 'view']}
          />
        </Button>
      </Box>
    </Wrapper>
  );
};
