import { OptionsPane, DesignPane } from '~/components/sidePanes';
import { useNavParams } from '~/hooks';

import { SideMenu } from '../SideMenu';

export const RightSideMenu = () => {
  const [{ tab }] = useNavParams();

  return (
    <SideMenu borderLeft="1px solid #EBEAEA">
      {tab === 0 && <OptionsPane />}

      {tab === 1 && <DesignPane />}
    </SideMenu>
  );
};
