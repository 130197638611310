import { FileUnion } from '~/graphql/sdk';

export const getFileUrl = (file?: FileUnion) => {
  switch (file?.__typename) {
    case 'File':
      return `${process.env.REACT_APP_UPLOADS_FOLDER}/${file.directory}/${file.fileName}`;
    case 'URLFile':
      return file.url;
    default:
      return '';
  }
};
