import { Box } from '@storyofams/react-ui';
import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const OptionWrapper = styled(Box).attrs((attrs) => ({
  width: {
    _: '100%',
    sm: 'calc(50% - 16px)',
    lg: 'calc(33.33333% - 16px)',
  },
  mx: 1,
  mb: [1, 2],
  ...attrs,
}))`
  &.is-dragging {
    .drag-handle {
      opacity: 1;
    }

    .match-warning {
      animation: 0.2s ${fadeOut} ease-out;
      animation-fill-mode: both;
    }
  }
`;
