import { Layout, Card } from "@shopify/polaris";
import { ProductSearch } from "./ProductSearch";
import { useFlow } from "~/hooks";

export const FlowNodeCard = () => {
  const { flowNode, updateFlowNode } = useFlow();

  const save = async ({ idx, productIds, isExcluded }) => {
    if (!flowNode) {
      return;
    }
    updateFlowNode(flowNode?.id, {
      options: flowNode?.options?.map((prevOpt, index) => {
        const opt = { ...prevOpt };
        if (index === idx) {
          if (isExcluded) {
            opt.excludedProductIds = productIds;
          } else {
            opt.productIds = productIds;
          }
        }
        return opt;
      }),
    });
  };

  //      <p style={{ color: "#6D7175" }}>{values.title} </p>

  return (
    <Card.Section>
      {flowNode?.options?.map((option, index) => {
        return (
          <div style={{ marginBottom: 18 }}>
            <h1 style={{ fontSize: 16, marginBottom: 10 }}>{option?.label}</h1>
            <Layout>
              <Layout.Section oneHalf>
                <ProductSearch
                  index={index}
                  productIds={option.productIds}
                  isExcluded={false}
                  onSave={(productIds) =>
                    save({ idx: index, productIds, isExcluded: false })
                  }
                />
              </Layout.Section>
              <Layout.Section oneHalf>
                <ProductSearch
                  index={index}
                  productIds={option.excludedProductIds}
                  isExcluded={true}
                  onSave={(productIds) =>
                    save({ idx: index, productIds, isExcluded: true })
                  }
                />
              </Layout.Section>
            </Layout>
          </div>
        );
      })}
    </Card.Section>
  );
};
