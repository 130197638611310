// @ts-nocheck
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  start: {
    id: 'Start.messages.start',
    defaultMessage: 'Start',
  },
  submit: {
    id: 'Question.messages.submit',
    defaultMessage: 'Submit & get results',
  },
  skip: {
    id: 'Question.messages.skip',
    defaultMessage: 'No thanks',
  },
  emailTitle: {
    id: 'Question.messages.emailTitle',
    defaultMessage: 'Almost there!',
  },
  emailSubtext: {
    id: 'Question.messages.emailSubtext',
    defaultMessage:
      'Fill in your email to get the results. We’ll only use your email for relevant product information.',
  },
  emailLabel: {
    id: 'Question.messages.emailLabel',
    defaultMessage: 'Your email',
  },
  emailPlaceholder: {
    id: 'Question.messages.emailPlaceholder',
    defaultMessage: 'Email address',
  },
  finish: {
    id: 'Question.messages.finish',
    defaultMessage: 'Finish',
  },
  next: {
    id: 'Question.messages.next',
    defaultMessage: 'Next question',
  },
  restart: {
    id: 'Results.messages.restart',
    defaultMessage: 'Start over',
  },
  resultsSubtext: {
    id: 'Results.messages.resultsSubtext',
    defaultMessage:
      'Based on your preferences, we recommend the following products:',
  },
  resultsTitle: {
    id: 'Results.messages.resultsTitle',
    defaultMessage: 'Our top picks for you:',
  },
  viewMore: {
    id: 'Results.messages.viewMore',
    defaultMessage: 'View more picks',
  },
});
