export const CoverLeft = () => (
  <svg viewBox="0 0 113 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="64" y="12" width="40" height="8" fill="currentColor" />
    <rect width="56" height="60" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 22.8C21.3373 22.8 20.8 23.3373 20.8 24V36C20.8 36.6627 21.3373 37.2 22 37.2H34C34.6627 37.2 35.2 36.6627 35.2 36V24C35.2 23.3373 34.6627 22.8 34 22.8H22ZM26 25.6C26.88 25.6 27.6 26.32 27.6 27.2C27.6 28.08 26.88 28.8 26 28.8C25.12 28.8 24.4 28.08 24.4 27.2C24.4 26.32 25.12 25.6 26 25.6ZM33.1992 35.6H22.7976C22.4696 35.6 22.2856 35.232 22.4776 34.968L25.32 31.7272C25.472 31.5592 25.736 31.5592 25.896 31.7192L27.2 33.2L29.648 29.3752C29.816 29.1352 30.168 29.1432 30.32 29.3912L33.5512 35C33.6952 35.272 33.5032 35.6 33.1992 35.6Z"
      fill="white"
    />
    <rect x="64" y="42" width="16" height="6" rx="2" fill="currentColor" />
    <rect x="64" y="24" width="36" height="4" fill="currentColor" />
    <rect
      x="0.5"
      y="0.5"
      width="112"
      height="59"
      rx="3.5"
      stroke="currentColor"
    />
  </svg>
);
