import { Card, EmptyState, Page, Stack } from '@shopify/polaris';

interface ErrorPageProps {
  onReset?(): void;
  text?: string;
}

export const ErrorPage = ({ onReset, text }: ErrorPageProps) => (
  <Page>
    <Card sectioned>
      <EmptyState
        heading="An error has occurred"
        image="/illustrations/error.svg"
        action={
          onReset
            ? {
                content: 'Refresh the page',
                onAction: onReset,
              }
            : undefined
        }
      >
        <Stack vertical>
          <p>
            {text ||
              'Our team has been notified and is working to resolve the issue. Please try again soon.'}
          </p>
        </Stack>
      </EmptyState>
    </Card>
  </Page>
);
