import { useEffect, useRef } from "react";
import { Box, Flex, Icon as IconUI, css } from "@storyofams/react-ui";
import equal from "fast-deep-equal";
import { motion } from "framer-motion";
import { find } from "lodash";
import { useForm, useFieldArray } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { Appear } from "~/components";
import {
  Button,
  Input,
  Link,
  OptionChoice,
  OptionImage,
  Subtext,
  Title,
  EditorController,
} from "~/components/preview";
import { ReactComponent as email } from "~/components/preview/Icon/library/email.svg";
import config from "~/config";
import { File, FlowFragmentFragment, FlowNodeType } from "~/graphql/sdk";
import { useNavParams } from "~/hooks";

import { messages } from "./messages";
import { OptionWrapper } from "./OptionWrapper";
import { useFormSync } from "./useFormSync";

interface QuestionFormProps {
  data: FlowFragmentFragment["nodes"][0];
  hasWelcome?: boolean;
  primaryColor?: string;
  total: number;
}

const MotionBox = motion(Box);

const SortableItem = SortableElement(({ value }) => (
  <OptionWrapper>
    {value.type === "radio" ? (
      <OptionChoice {...value} />
    ) : (
      <OptionImage style={{ marginTop: 200 }} {...value} />
    )}
  </OptionWrapper>
));

const SortableList = SortableContainer(({ children }) => {
  return (
    <Flex
      flexWrap="wrap"
      mx={-1}
      width="100%"
      css={{
        "&:hover .drag-handle": {
          opacity: 1,
        },
      }}
    >
      {children}
    </Flex>
  );
});

const withoutImages = (options: any) =>
  options?.map(({ image, nextNode, nextAction, ...option }) => option);

const getDefaultValues = (flowNode) => ({
  title: flowNode?.title || "",
  description: flowNode?.description || "",
  options: withoutImages(flowNode?.options) || [],
});

export const QuestionForm = ({
  data: flowNode,
  hasWelcome,
  primaryColor,
  total,
}: QuestionFormProps) => {
  const intl = useIntl();
  const [{ question: current }, setNavParams] = useNavParams();
  const flowNodeId = useRef(flowNode?.id || "");

  const totalQuestions = total - (hasWelcome ? 0 : 1);

  const {
    control,
    register,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: getDefaultValues(flowNode),
  });
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "options",
    keyName: "fieldKey",
  });

  useFormSync({ control, flowNodeId: flowNodeId.current });

  useEffect(() => {
    if (isDirty && !(flowNode as any)?.isModified) {
      const currentOptions = getValues("options");
      if (!equal(withoutImages(flowNode?.options), currentOptions)) {
        const newOptions = getDefaultValues(flowNode).options;

        setValue(
          "options",
          newOptions.map((newOption, idx) => ({
            ...newOption,
            label:
              currentOptions?.find(({ id }) => id === newOption.id)?.label ||
              (currentOptions?.length === newOptions?.length &&
              currentOptions?.[idx]?.id === "new" &&
              !newOption.label
                ? currentOptions?.[idx]?.label
                : "") ||
              newOption.label,
            description:
              currentOptions?.find(({ id }) => id === newOption.id)
                ?.description ||
              (currentOptions?.length === newOptions?.length &&
              currentOptions?.[idx]?.id === "new" &&
              !newOption.description
                ? currentOptions?.[idx]?.description
                : "") ||
              newOption.description,
            ...(fields?.[idx]?.fieldKey
              ? { fieldKey: fields[idx].fieldKey }
              : {}),
          }))
        );
      }
    }
  }, [flowNode]);

  const isEmail = flowNode?.type === FlowNodeType.Email;

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    move(oldIndex, newIndex);
  };

  if (!flowNode?.id) {
    return null;
  }

  const titleAndDescription = (
    <>
      <EditorController
        name="title"
        control={control}
        element={Title}
        dir={isEmail ? "auto" : undefined}
        placeholder={
          isEmail
            ? intl.formatMessage(messages.emailTitle)
            : "Your question here ..."
        }
      />

      <Box mt={2} mb={5}>
        <EditorController
          name="description"
          control={control}
          element={Subtext}
          dir={isEmail ? "auto" : undefined}
          placeholder={
            isEmail
              ? intl.formatMessage(messages.emailSubtext)
              : "Description (optional)"
          }
        />
      </Box>
    </>
  );

  return (
    <MotionBox
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={config.transition}
      textAlign={isEmail ? "center" : undefined}
    >
      {isEmail ? (
        <>
          <Box width={{ _: "100%", sm: "345px", lg: "480px" }} mx="auto">
            <Appear>
              <Flex
                justifyContent="center"
                alignItems="center"
                borderRadius="full"
                bg="white"
                width="80px"
                height="80px"
                mb={[4, 5]}
                mx="auto"
              >
                <IconUI icon={email} />
              </Flex>
              {titleAndDescription}
            </Appear>
          </Box>

          <Box width={{ _: "100%", sm: "345px", lg: "370px" }} mx="auto">
            <Appear transition={{ delay: 0.2, ...config.transition }}>
              <Input
                disabled
                name="email"
                label={intl.formatMessage(messages.emailLabel)}
                placeholder={intl.formatMessage(messages.emailPlaceholder)}
                type="email"
                primaryColor={primaryColor}
              />
            </Appear>

            <Appear transition={{ delay: 0.3, ...config.transition }}>
              <Flex mt={4} flexDirection="column" alignItems="center">
                <Button arrow width="100%" disabled primaryColor={primaryColor}>
                  <FormattedMessage {...messages.submit} />
                </Button>

                <Link fontSize="18px" lineHeight="22px" mt={4} disabled>
                  <FormattedMessage {...messages.skip} />
                </Link>
              </Flex>
            </Appear>
          </Box>
        </>
      ) : (
        <>
          <Appear>
            {titleAndDescription}

            {!isEmail && (
              <SortableList
                axis="xy"
                onSortEnd={onSortEnd}
                useDragHandle
                helperClass="is-dragging preview-pane"
              >
                {fields.map((field, index) => {
                  const props = {
                    isActive: false,
                    field,
                    control,
                    register,
                    index,
                    onDelete: () => {
                      remove(index);
                    },
                  };

                  return (
                    <SortableItem
                      key={field.fieldKey}
                      index={index}
                      value={{
                        ...props,
                        ...(flowNode.type === FlowNodeType.Image ||
                        flowNode.type === FlowNodeType.ImageMulti
                          ? {
                              src: find(flowNode?.options, {
                                id: (field as any).id,
                              })?.image as File,
                              flowNodeId: flowNodeId.current,
                              optionId: (field as any).id,
                            }
                          : {
                              type: "radio",
                              emoji:
                                flowNode.type === FlowNodeType.Emoji ||
                                flowNode.type === FlowNodeType.EmojiMulti,
                            }),
                      }}
                    />
                  );
                })}

                <Button
                  width={{
                    _: "100%",
                    sm: "calc(50% - 16px)",
                    lg: "calc(33.33333% - 16px)",
                  }}
                  minHeight="72px"
                  mx={1}
                  mb={[1, 2]}
                  variant="plain"
                  fontWeight="regular"
                  fontSize={2.25}
                  css={css({
                    "&&": {
                      color: "interactive",
                    },
                    "> div:first-of-type": {
                      bg: "black4",
                    },
                    "&:hover > div:first-of-type": {
                      bg: "black10",
                    },
                  })}
                  onClick={() => {
                    append(
                      { id: "new", label: "", description: "" },
                      { shouldFocus: true }
                    );
                  }}
                >
                  Add answer
                </Button>
              </SortableList>
            )}
          </Appear>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, ...config.transition }}
          >
            <Button
              arrow
              mt={7}
              width={{ _: "100%", sm: "345px", lg: "370px" }}
              onClick={() => {
                if (current < totalQuestions) {
                  setNavParams({ question: `${current + 1}` });
                }
              }}
              primaryColor={primaryColor}
              disabled={current >= totalQuestions}
            >
              {current === totalQuestions
                ? intl.formatMessage(messages.finish)
                : intl.formatMessage(messages.next)}
            </Button>
          </motion.div>
        </>
      )}
    </MotionBox>
  );
};
