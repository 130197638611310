import { forwardRef, ReactNode, useCallback, useRef, useState } from 'react';
import { Icon, Popover, Tooltip } from '@shopify/polaris';
import { DragHandleMinor, SmileyHappyMajor } from '@shopify/polaris-icons';
import {
  Box,
  Button,
  Flex,
  SystemProps,
  Text,
  css,
} from '@storyofams/react-ui';
import { Controller } from 'react-hook-form';
import { SortableHandle } from 'react-sortable-hoc';

import { EmojiPicker } from '~/components/EmojiPicker';
import { Check } from '../Check';
import { EditorController } from '../EditorController';
import { Option, OptionProps } from '../Option';

interface OptionChoiceProps {
  control: any;
  field: any;
  emoji?: boolean;
  register: any;
  index: number;
  right?: ReactNode;
  type: 'check' | 'radio';
}

const Answer = forwardRef(({ children }: { children?: ReactNode }, ref) => (
  <Text
    fontSize={2.25}
    letterSpacing="-0.01em"
    lineHeight="22px"
    color="black90"
    ref={ref as any}
  >
    {children}
  </Text>
));

const Subtext = forwardRef(({ children }: { children?: ReactNode }, ref) => (
  <Text
    mt={0.25}
    fontSize={1.5}
    letterSpacing="-0.01em"
    lineHeight="15px"
    color="black60"
    ref={ref as any}
  >
    {children}
  </Text>
));

const DragHandle = SortableHandle(() => (
  <Tooltip content="Drag to change order" dismissOnMouseOut>
    <Box
      className="drag-handle"
      css={{
        '.Polaris-Icon': {
          width: '24px',
          height: '24px',
        },
      }}
      cursor="grab"
    >
      <Icon source={DragHandleMinor} color="subdued" />
    </Box>
  </Tooltip>
));

export const OptionChoice = ({
  isActive,
  right,
  emoji,
  type,
  control,
  index,
  field,
  register,
  ...props
}: OptionChoiceProps & Omit<OptionProps, 'children'> & SystemProps) => {
  const editorRef = useRef<any>();
  const [isEmojiPickerActive, setEmojiPickerActive] = useState(false);

  const toggleEmojiPicker = useCallback(
    () => setEmojiPickerActive((active) => !active),
    [],
  );

  return (
    <Option
      pr={3}
      pl={0}
      py="19px"
      isActive={isActive}
      editorRef={editorRef}
      {...props}
    >
      <>
        <Controller
          control={control}
          name={`options.${index}.id`}
          defaultValue={field.id}
          render={(input) => <input type="hidden" {...input.field} />}
        />
        <Controller
          control={control}
          name={`options.${index}.productIds`}
          defaultValue={field.productIds}
          render={(input) => <input type="hidden" {...input.field} />}
        />

        <Flex flex="1" flexDirection="row" alignItems="center" maxWidth="100%">
          <DragHandle />

          <Check type={type} isActive={isActive} />

          <Box
            width="100%"
            minWidth="0"
            maxWidth="100%"
            css={{
              marginInlineStart: '12px',
              textOverflow: 'ellipsis',
            }}
          >
            <EditorController
              name={`options.${index}.label`}
              control={control}
              element={Answer}
              placeholder="Answer Here"
              editorRef={editorRef}
              defaultValue={field.label}
            />

            <div className="description-input">
              <EditorController
                name={`options.${index}.description`}
                control={control}
                element={Subtext}
                placeholder="Description (optional)"
                defaultValue={field.description}
              />
            </div>
          </Box>
        </Flex>

        {!!emoji && (
          <Controller
            control={control}
            name={`options.${index}.emoji`}
            defaultValue={field.emoji}
            render={({ field: { onChange, value } }) => (
              <Popover
                active={isEmojiPickerActive}
                onClose={toggleEmojiPicker}
                fullHeight
                ariaHaspopup="dialog"
                autofocusTarget="none"
                activator={
                  <Button
                    width="48px"
                    height="48px"
                    lineHeight="48px"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={toggleEmojiPicker}
                    css={css({
                      bg: isEmojiPickerActive
                        ? 'black10'
                        : !value
                        ? 'black4'
                        : undefined,
                      '&:hover, &:focus, &:active': {
                        bg: 'black10',
                        opacity: 1,
                      },
                    })}
                  >
                    {value ? (
                      <Text fontSize="38px">
                        {/* eslint-disable-next-line */}
                      <span role="presentation">{value}</span>
                      </Text>
                    ) : (
                      <Box
                        position="relative"
                        css={css({
                          '.Polaris-Icon': {
                            width: '24px',
                            height: '24px',
                            fill: 'black20',
                          },
                        })}
                      >
                        <Icon source={SmileyHappyMajor} />
                      </Box>
                    )}
                  </Button>
                }
              >
                <EmojiPicker
                  onSelect={({ native }) => {
                    onChange(native);
                    toggleEmojiPicker();
                  }}
                />
              </Popover>
            )}
          />
        )}
      </>
    </Option>
  );
};
